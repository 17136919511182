.maincontainer {
  height: fit-content;
  min-height: 100vh;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: start;
  gap: 16px;
  min-height: 100vh;
  overflow: hidden !important;
}
.cardcontainer {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: start;
  background-color: transparent !important;
  flex-direction: column;
  background-color: transparent !important;
  margin: 0 auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.cardcontainer::-webkit-scrollbar {
  display: none;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #393a44;
}

.card {
  width: 100%;
  margin: 5px 0;
  /* height: 208px; */
  max-height: fit-content;
  border-radius: 16px;
  box-shadow: 4px 2px 12px 0px #00000040;
  background-color: #ffff;
  width: 98%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
}

.drimgcontainer {
  width: 176px;
  height: 176px;
  border-radius: 21.33px;
  overflow: hidden;

}
.drimg {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}
.textdata {
  width: 200px;
  overflow: hidden;
  margin: 0px 16px;
}
.drname,
.pmdc {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #131313;
}
.pmdc {
  color: #13a89e;
  font-size: 12px;
}
.featureconatiner {
  width: 71px;
  height: 14px;
  padding: 4px;
  border-radius: 24px;
  opacity: 0px;
  background-color: #eaf4ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature {
  color: #0e54a3;
  font-size: 8px;
  text-align: center;
  font-weight: 600;
}
.qualification {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-decoration-skip-ink: none;
  color: #7d7d7d;
  white-space: wrap;
}
.bottom {
  display: flex;
  align-items: center; /* Align content vertically */
  margin-top: 40%;
  gap: 10px;
}

.bottomtitle,
.bottomlabel,
.basic {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}

.bottomlabel {
  font-weight: 400;
  font-size: 10px;
  color: #7d7d7d !important;
}

.bottomseprator {
  align-self: stretch;
  border: 1px solid #7d7d7d;
}
.innercardcontainer {
  display: flex;
}

.innercard {
  box-sizing: border-box;
  padding: 10px;
  width: 220px;
  height: 176px;
  border-radius: 24px;
  border: 1px solid #0e54a3;
}
.innerbtn {
  margin: 0 auto;
  width: 202px;
  height: 34px;
  padding: 12px 84px 12px 84px;
  border-radius: 24px;

  background-color: #0e54a3;
  box-shadow: 4px 4px 12px 0px #00000040;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  color: #ffff;
  font-weight: 600;
  font-size: 14px;
}

.basic {
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
}

@media (max-width: 1024px) {
  .innercard {
    width: 155px;
  }
  .innerbtn {
    width: 135px;
    padding: 12px 60px;
  }
  .drimgcontainer {
    width: 155px;
  }
  .textdata {
    width: 175px;
  }
}
@media (max-width: 480px) {
  .innercardcontainer {
    width: 98%;
    margin: 0 auto;
  }
  .innercard {
    width: 100%;
    margin: 0 auto;
  }
  .innerbtn {
    width: 90;
    padding: 12px 60px;
  }
}
