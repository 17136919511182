.p20 {
  padding: 20px;
}

.pt20 {
  padding-top: 20px;
}
.pt38 {
  padding-top: 24px;
}
.py32 {
  padding: 32px 0 24px 0;
}
.pt11 {
  padding-top: 20px;
}
.pt4 {
  margin-top: 5px;
}
select {
  border: none;
}
.graph {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  background-color: white;
  border-radius: 15px;
  padding: 30px 0;
  margin-right: 24px;
  margin-top: 24px;
  height: 370px;
  box-sizing: border-box;
}
.graph2 {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  height: 300px;
  margin-top: 24px;
  box-sizing: border-box;
}
.down {
  width: 10px;
  height: 6px;
  padding: 10px 0 0 0;
  margin-right: 10px;
}
.w20 {
  width: 18.5% !important;
}
.mt24 {
  margin-top: 24px;
}
.Maincards {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  background-color: white;
  border-radius: 16px;
  width: 100%;
  color: #00276d;
  box-sizing: border-box;
  height: 115px;
}

.mb40 {
  margin-bottom: 40px;
}

.pt15 {
  padding-top: 16px;
}

.arrow {
  height: 27px;
}
.m20 {
  margin: 20px;
}
.pt15 {
  padding-top: 15px;
}
.col1 {
  width: 8.3333%;
}
.col2 {
  width: 16.6667%;
}
.col3 {
  width: 25%;
}
.col4 {
  width: 33.3333%;
}
.col5 {
  width: 41.6667%;
}
.col6 {
  width: 50%;
}
.col7 {
  width: 58.3333%;
}
.col8 {
  width: 66.6667%;
}
.col9 {
  width: 75%;
}
.col10 {
  width: 83.3333%;
}
.col11 {
  width: 91.6667%;
}
.col12 {
  width: 100%;
}

.pr10 {
  padding: 20px 0 20px 0;
}

.m20 {
  margin: 0 20px;
}
.flxp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mesg {
  height: 36px;
  width: 35px;
  margin-top: 10px;
}
.pt10 {
  padding: 12px;
}
.pt16 {
  padding: 16px 0 0 0;
}
.ml30 {
  margin-left: 30px;
}
.end {
  display: flex;
  justify-content: end;
}
.p11 {
  padding: 5px 0 5px 10px;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.box1 {
  width: 120px;
  border-radius: 7px;
  border: 1px solid black;
}
.container {
  margin: 0 120px;
}
.mt32 {
  margin-top: 32px;
}
.m0 {
  margin: 0%;
}
.ppic {
  padding: 10px 0 0 5px;
}
.pic3 {
  margin: 4px;
}
.mp {
  margin: 10px 50px 0 0;
}
.m15 {
  margin: 27px;
}
.mt16 {
  margin-top: 16px;
}
.slider {
  width: 100%;

  background-color: #020e35;
  border: 1px solid #020e35;
  border-radius: 0 22px 22px 22px;
}
.mt20 {
  margin-top: 20px;
}
.pt30 {
  padding-top: 30px;
}

.py20 {
  padding: 20px 33px;
}
.py10 {
  padding: 0 10px 0 0;
}
.my50 {
  margin: 30px 0;
}
.mr20 {
  margin-right: 40px;
}
.ml20 {
  margin-left: 4 20px;
}
.between {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.center {
  display: flex;
  justify-content: center;
}
.pt6 {
  padding-top: 6px;
}
.seletions {
  font-size: 20px;
  font-weight: 600;
  color: #020e35;
  outline: none !important;
}
.seletions::after {
  outline: none !important;
  border: none;
}
.mt32 {
  margin-top: 32px;
}
.arrowUp {
  height: 12px;
  width: 12px;
  color: #007201;
}
.arrowdown {
  height: 12px;
  width: 12px;
  color: red;
}
.colorOrange {
  color: red;
}
.colorGreen {
  color: #007201;
}
.iconsOuterGreen {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: #007201;
  background-color: #c4fcbb;
  margin: 0 10px;
}
.iconsOuterOrange {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: rgb(232, 144, 144);
  background-color: #ffedd6;
  margin: 0 10px;
}
.mb4 {
  margin-bottom: 4px;
}
.gray {
  color: gray;
}
.flx_coloumn {
  display: flex;
  flex-direction: column;
}
.flx_row {
  display: flex;
  flex-direction: row;
}

.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #099bed;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #c9d8e2;
}
