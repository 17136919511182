.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  /* min-height: 78vh; */
  max-height: auto;
}
.card {
  width: "100%";
  border-radius: 8px;
  /* background-color: #13a89e; */
  background-color: #f5f5f5;
  margin-bottom: 8px;
  padding: 10px;
  color: #00276d;
  box-sizing: border-box;
  cursor: pointer;
}
.mt7 {
  margin-top: 9px;
}
.emptyOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 450px; */
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #33cccc;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #c9d8e2;
}
.icon {
  height: 16px;
  width: 16px;
  color: #33cccc;
  margin-right: 10px;
}
/* -------Details ------------ */
.DetailCard {
  width: 100%;
  box-sizing: border-box;

  border-radius: 8px;
  padding: 16px;
}
.mt24 {
  margin-top: 24px;
}
.colorGray {
  color: #7d7d7d;
}
.bitBtn {
  margin-top: 24px;
  margin-left: auto;
  width: 160px;
  display: flex;
  justify-content: end;
}
.mb16 {
  margin-bottom: 16px;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.w25 {
  width: 22%;
  margin-right: 32px;
}
.w22 {
  width: 19%;
  margin-right: 10px;
}
.accordion {
  margin-top: 40px;
}
.deleteIcon {
  height: 22px;
  margin-right: 5px;
}
.amenties {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #00276d;
  width: 250px;
}
.checkbox {
  color: Orange !important;
}
.submit {
  margin-top: 56px;
  width: 160px;
  margin-bottom: 16px;
}
.customSelect {
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid #6f6f72;
  margin-top: 24px;
  font-size: 14px;
  font-style: italic;
  color: #00276d;
}
.customSelect:focus {
  outline: none;
}
.addicon {
  font-size: 16px;
  color: #00276d;
  margin-right: 16px;
  cursor: pointer;
}
.done {
  height: 84px;
  width: 84px;
  color: green;
  display: flex;
  margin: 0px auto 24px auto;
}
.close {
  outline: none;
  font-size: 22px;
  background-color: transparent;
  color: gray;
  border: none;
  cursor: pointer;
}
.end {
  margin-left: auto;
}
.justify {
  justify-content: space-between;
  background-color: #f5f5f5;
  margin: 4px 4px;
  padding: 16px;
}
.mr20 {
  margin-right: 40%;
}
.title {
  font-size: 17px;
}
.fs14 {
  font-size: 17px;
  margin-left: 4px;
}
.advancedButton {
  background: linear-gradient(135deg, #007bff, #0056b3);
  /* Gradient background */
  color: #fff;
  /* White text color */
  padding: 10px 20px;
  /* Padding for better spacing */
  font-size: 16px;
  /* Larger font size for readability */
  border: none;
  /* Remove border */
  border-radius: 4px;
  /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  cursor: pointer;
  /* Pointer cursor */
  transition: all 0.3s ease-in-out;
  /* Smooth transition effect */
}

.advancedButton:hover {
  background: linear-gradient(135deg, #0056b3, #003f7f);
  /* Darker gradient on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  /* Slightly deeper shadow */
  transform: translateY(-2px);
  /* Slight lift on hover */
}

.advancedButton:active {
  background: linear-gradient(135deg, #003f7f, #002d59);
  /* Even darker gradient on active */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Reduce shadow on click */
  transform: translateY(0);
  /* Remove lift on click */
}
/* Overall container styling */
.container {
  padding: 15px;
  border-radius: 10px;
}

/* User details card styling */
.userDetailsCard {
  border-radius: 10px;
  padding: 15px;
  /* Reduced padding */
  margin-bottom: 20px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
  /* Softer shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.userDetailsCard:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

/* User name styling */
.userName {
  font-size: 20px;
  /* Slightly smaller font size */
  font-weight: 600;
  color: #00276d;
  margin-bottom: 8px;
}

/* Flexbox for user flight info */
.userFlightInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  /* Reduced gap */
}

/* Label and value styling */
.label {
  font-size: 13px;
  /* Slightly smaller font size */
  color: #718096;
}

.value {
  font-size: 15px;
  /* Slightly smaller font size */
  color: #2d3748;
  font-weight: 500;
}

/* Flight details card styling */
.flightDetails {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  /* Reduced padding */
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
  /* Softer shadow */
}
.flightDetailsHeading {
  font-size: 18px;
  font-weight: 600;
  color: #005b9f;
}

/* Section title styling */
.sectionTitle {
  font-size: 18px;
  /* Slightly smaller font size */
  font-weight: 700;
  color: #00276d;
  margin-bottom: 15px;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 8px;
}

.sectionTitle::after {
  content: "";
  display: block;
  margin-top: 8px;
  width: 50px;
  height: 3px;
  background-color: #00276d;
}

/* Grid layout for form fields */
.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* Reduced gap */
}

/* Form group styling */
.formGroup {
  display: flex;
  flex-direction: column;
}

/* Form label styling */
.formLabel {
  font-size: 13px;
  /* Slightly smaller font size */
  color: #4a5568;
  margin-bottom: 6px;
}

/* Input fields styling */
.textInput,
.dateInput,
.timeInput,
.selectInput {
  padding: 10px 12px;
  /* Reduced padding */
  border-radius: 6px;
  /* Slightly sharper corners */
  border: 1px solid #cbd5e0;
  font-size: 14px;
  /* Slightly smaller font size */
  color: #2d3748;
  background-color: #f7fafc;
  transition: all 0.3s ease;
}

.textInput:focus,
.dateInput:focus,
.timeInput:focus,
.selectInput:focus {
  border-color: #3182ce;
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(49, 130, 206, 0.3);
  /* Reduced box shadow */
  outline: none;
}

/* File input styling */
.fileInput {
  padding: 10px;
  /* Reduced padding */
  border: 2px dashed #cbd5e0;
  border-radius: 6px;
  /* Slightly sharper corners */
  background-color: #edf2f7;
  color: #718096;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.card1 {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}
.subTitle {
  font-size: 18px;
  font-weight: 600;
  color: #005b9f;
  margin-top: 20px;
  margin-bottom: 10px;
}

.policyContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.policyItem {
  display: flex;
  justify-content: space-between;
  width: 48%;
}

.labelText {
  text-align: left;
}

.valueText {
  text-align: right;
}
.labelText {
  font-size: 17px;
  font-weight: 500;
  color: #7a7a7a;
}

.valueText {
  font-size: 20px;
  font-weight: 600;
  color: #0e54a3;
}

.drop {
  width: 20px;
  height: 20px;
}
.fileInput:hover {
  background-color: #e2e8f0;
  border-color: #3182ce;
}

/* Grid layout for amenities */
.amenitiesGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Reduced gap */
  margin-top: 15px;
}

/* Checkbox label and input styling */
.checkboxLabel {
  display: flex;
  align-items: center;
  font-size: 13px;
  /* Slightly smaller font size */
  color: #2d3748;
}

.checkboxInput {
  margin-right: 10px;
  /* Reduced margin */
}

/* Button container styling */
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  /* Reduced margin */
  gap: 10px;
  /* Adding gap for better button alignment */
}

/* Save and bid button styling */
.saveButton,
.bidButton {
  padding: 10px 25px;
  /* Reduced padding */
  background-color: #2c7a7b;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  /* Slightly sharper corners */
  font-size: 14px;
  /* Slightly smaller font size */
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.saveButton:hover,
.bidButton:hover {
  background-color: #285e61;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  /* Subtler shadow */
}

/* Styling for small "Add More" and "Remove" buttons */
.addMoreButton {
  padding: 4px 8px;
  /* Reduced padding */
  background-color: #f4f7fc;
  color: #00276d;
  border: 1px solid #00276d;
  border-radius: 6px;
  /* Slightly sharper corners */
  font-size: 12px;
  /* Smaller font size */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  align-self: flex-start;
  /* Align to the start */
  margin-top: 8px;
  /* Reduced margin */
}

.addMoreButton:hover {
  background-color: #00276d;
  color: #ffffff;
}

.removeButton {
  padding: 4px 8px;
  /* Reduced padding */
  background-color: #e53e3e;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  /* Slightly sharper corners */
  font-size: 12px;
  /* Smaller font size */
  cursor: pointer;
  align-self: flex-end;
  /* Align to the end */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 8px;
  /* Reduced margin */
}

.removeButton:hover {
  background-color: #c53030;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  /* Subtler shadow */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .formGrid {
    grid-template-columns: 1fr;
  }

  .userFlightInfo {
    flex-direction: column;
  }

  .buttonContainer {
    justify-content: center;
  }
}

/* Flight title container styling */
.flightTitleContainer {
  margin-bottom: 15px;
}

/*--------------------------------*/
/* General Styling */
.formContainer {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  max-width: 700px;
  margin: auto;
  font-family: "Roboto", sans-serif;
}

.heading {
  color: #00276d;
  font-weight: 600;
  font-size: 1.25em;
  margin-bottom: 15px;
  border-bottom: 1px solid #004aad;
  padding-bottom: 8px;
}

.subHeading {
  color: #004aad;
  font-weight: 500;
  margin: 20px 0 10px;
  font-size: 1.1em;
  border-bottom: 1px solid #004aad;
  padding-bottom: 6px;
}

/* User Info Section */
.userInfo {
  margin-bottom: 25px;
}

.userDetails {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f4f8;
  border-radius: 8px;
  border-left: 3px solid #004aad;
}

.userDetails p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #333;
  font-weight: 500;
}

.flightSummary {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.flightInfo,
.flightOtherInfo {
  display: flex;
  flex-direction: column;
}

.flightInfo p,
.flightOtherInfo p {
  margin: 4px 0;
  font-size: 0.85em;
  color: #555;
}

/* Flight Details Section */
.flightDetails {
  margin-bottom: 30px;
}

.flightRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.fieldGroup {
  width: 48%;
  margin-bottom: 15px;
  position: relative;
}

.fieldGroup label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #00276d;
  font-size: 0.85em;
}

.fieldGroup input,
.fieldGroup select {
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fdfdfd;
  font-size: 0.85em;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.fieldGroup input:focus,
.fieldGroup select:focus {
  border-color: #004aad;
  box-shadow: 0 0 4px rgba(0, 74, 173, 0.2);
}

.fieldGroup input[type="file"] {
  padding: 5px;
  border: none;
}

/* Flight Amenities Section */
.flightAmenities,
.flightPolicies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.checkboxGroup {
  width: 48%;
  display: flex;
  align-items: center;
  background-color: #eef2f6;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.checkboxGroup input[type="checkbox"] {
  margin-right: 8px;
  transform: scale(1.1);
}

.checkboxGroup label {
  font-weight: 500;
  color: #00276d;
  font-size: 0.85em;
}

.checkboxGroup:hover {
  background-color: #e0e8f0;
  border-color: #004aad;
}

/* Flight Policies Section */
.flightPolicies .fieldGroup {
  width: 48%;
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.dropIcon {
  font-size: 45px;
  color: #0e54a3;
  cursor: pointer;
}

.totalPrice {
  font-size: 18px;
  font-weight: 600;
  color: #0e54a3;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ticketPrice {
  font-size: 29px;
  font-weight: bold;
  color: #d32f2f;
  animation: blink 3s infinite;
}

/* Bid Button */
.bidButton {
  width: 100%;
  padding: 12px;
  background-color: #285e61;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.bidButton:hover {
  background-color: #204c4e;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}
