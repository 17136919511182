.LabImg {
  height: 553px;
  width: 452px;
  margin-top: 50px;
}
.MianOuter {
  padding: 100px 112px 0px 112px;
  box-sizing: border-box;
}
.lock {
  width: 337px;
  height: 552px;
  margin-right: 100px;
}
.BackIcon {
  height: 14px;
  width: 14px;
  color: #00276d;
  margin-right: 8px;
  cursor: pointer;
}
.colorGray {
  color: #313131;
}

.mt24 {
  margin-top: 24px;
}
.mt16 {
  margin-top: 16px;
}
.mt50 {
  margin-top: 50px;
}
.mt40 {
  margin-top: 40px;
}
.mt100 {
  margin-top: 100px;
}
