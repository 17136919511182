.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  display: flex;
  width: 342px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
}
.dltbtn {
  padding: 10px;
  color: #f5f5f5;
  background-color: red;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
}
.cancelbtn {
  padding: 10px;
  color: #f5f5f5;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #808080;
  cursor: pointer;
}
.closeicon {
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: rgb(163, 160, 160);
}

@media (max-width: 370px) {
  .modalContainer {
    display: flex;
    width: 100%;
    max-width: 239px;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 8px 48px rgba(0, 0, 0, 0.1);
  }
}
