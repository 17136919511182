.navContainer {
  display: flex;
  margin-top: 56px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap;
}

.navButton {
  background-color: #f9f9f9;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  margin: 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navButton:hover {
  background-color: #0d47a1;
  color: #dcdcdc;
}

.navButton.active {
  background-color: red; /* Change the background of the active button to red */
  color: #fff; /* Change text color to white */
}

