.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}
.modal-content {
  text-align: center;
  padding: 20px;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff4d4f;
}

.modal-message {
  font-size: 1rem;
  color: #555;
}
.text2 {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
  text-align: center;
}
.parent-container {
  display: flex;
  gap: 16px;
  justify-content: center;
}
.update-modal {
  text-align: center;
  /* padding: 20px; */
}
.textU_pdate {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
  text-align: center;
}
.update-button {
  background-color: #007bff;
  color: white;
  padding: 8px 25px;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  margin-top: 10px;
}
.cancel-button {
  background-color: red;
  color: white;
  padding: 8px 25px;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  margin-top: 10px;
}
.rowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 32px;
}
.update-button:hover {
  background-color: #0056b3;
}
.allow-button {
  background-color: #4caf50;
  color: white;
  height: 30px;
  width: 120px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.closebtn {
  display: flex;
  width: 120px;
  height: 30px;
  background-color: #0056b3;
  color: white;
  font-size: 1rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  align-self: flex-end;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ......................Widget Style ..................... */
/* Reduce the overall width of the Google Translate dropdown */
.goog-te-gadget-simple {
  max-width: 180px !important;
  padding: 5px 8px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Adjust the dropdown size */
.goog-te-combo {
  width: 100px !important; /* Increase width slightly */
  height: 25px !important;
  font-size: 12px !important;
}

/* Keep the Google Translate dropdown visible */
#google_translate_element {
  display: inline-block !important;
  width: auto !important;
}

/* Hide Google logo */
.goog-te-gadget-simple img {
  display: none !important;
}

/* Adjust the modal popup (if it appears) */
.goog-te-banner-frame {
  width: auto !important;
  height: 30px !important;
  min-height: 30px !important;
}

/* Parent container for centering */
.parent-container {
  max-width: 150px !important; /* Make it match widget size */
  width: 100%;
  height: 30px !important;
  margin: auto;
  text-align: center;
  background-color: transparent; /* Avoid hiding the widget */
}
