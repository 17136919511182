/* Container styling */
.activation_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items at the top */
  background-color: #ff4d4d; /* Red background */
  color: white;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  flex-wrap: wrap;
}
.activated {
  background-color: green; /* Red background */
  padding: 5px;
  border-radius: 12px;
}

/* Content styling */
.activation_content {
  max-width: 70%;
}

.activation_content h2 {
  font-size: 1.5rem;
  margin: 0;
}

.activation_content p {
  margin: 10px 0;
  font-size: 0.9rem;
}

.activation_content_small {
  font-size: 0.5rem;
}

/* Button styling */
.pay_button {
  width: 210px; /* Set fixed width */
  padding: 10px 0; /* Adjust padding to center text within the button */
  background-color: white;
  color: #333;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  align-self: flex-start; /* Align button with top of content */
  margin-top: 5px;
}

.pay_button:hover {
  background-color: #f2f2f2;
}

.gap {
  gap: 8px;
}
.text {
  /* font-size: 16px; */
  padding: 6px;
  background-color: #f2f2f2;
  border-radius: 8px;
  color: black;
  font-weight: bold;
}

/* Responsive styling */
@media (max-width: 768px) {
  .activation_container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .activation_content {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .pay_button {
    align-self: center;
  }
}

@media (max-width: 480px) {
  .activation_container {
    padding: 10px; /* Slightly smaller padding on very small screens */
  }

  .activation_content h2 {
    font-size: 1.2rem; /* Reduce heading size on very small screens */
  }

  .activation_content p,
  .activation-content small {
    font-size: 0.4rem; /* Adjust text size for readability */
  }

  .pay_button {
    padding: 12px 0; /* Adjust button padding for smaller screens */
    align-self: center;
  }
}
