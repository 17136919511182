.navbar {
  background-color: #fff;
  color: #fff;
  padding: 10px 0;
  position: sticky;
  z-index: 1;
  top: 0;
}
.bar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}
.selected {
  position: relative;
  color: #ff7631 !important;
}

/* .selected::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background-color: #ff7631;
  border-radius: 50%;
} */

.container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: sticky !important;
  top: 0%;
}

.logo {
  font-size: 24px;
  text-decoration: none;
  color: #fff;
}
.downIcon {
  color: #00276d;
  margin-left: 8px;
}
.nav-links {
  list-style: none;
  display: flex;
}

.nav-links > li {
  margin-right: 40px;
}

.nav-links a {
  text-decoration: none;
  color: #00276d !important;
}
.navvlink {
  color: #00276d !important;
  cursor: pointer;
}
.image-container {
  width: 100%;
  max-width: 77px; /* Adjust as needed */
  /* margin: 0 112px;  */
  margin: 0 112px;
}

.responsive-image {
  width: 100%;
  height: auto;
}

.border2 {
  border-bottom: 2px;
}
.mobileMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Adjust the top position as needed */
  right: 0;
  background-color: white;
  padding: 20px;
  border: 3px solid white;
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 8px;
  z-index: 1000;
  gap: 6px;
  height: 350px;
  overflow: auto;
  color: #00276d;
}
.mobileMenu::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.mobileMenu::-webkit-scrollbar-track {
  background: #a4a2a2;
  border-radius: 5px;
}

/* Handle */
.mobileMenu::-webkit-scrollbar-thumb {
  background: #00276d;
  border-radius: 5px;
}

/* Handle on hover */
.mobileMenu::-webkit-scrollbar-thumb:hover {
  background: #00276d;
}
.mobileMenu a {
  margin-bottom: 10px;
  text-decoration: none;
  color: #00276d;
  font-weight: 700;
  font-size: 16px;
  /* Add other styling as needed */
}
.dropdownMenu {
  background-color: gray;
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  top: 62%;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  width: 138px;
}
.dropdownMenu > li {
  list-style: none;
  text-align: center;
  padding: 8px;
}
.dropdownMenu > li:hover {
  background-color: rgb(192, 129, 129);
  color: #00276d;
}
@media (min-width: 320px) and (max-width: 480px) {
  .nav-links {
    /* list-style: none;
    display: block;
    background-color: gray;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: visible; */
    display: none;
  }
  .nav-links li {
    margin: 10px;
  }
  .image-container {
    width: 100%;
    max-width: 77px; /* Adjust as needed */
    /* margin: 0 112px;  */
    margin: 0 270px 0 20px;
    display: flex;
    justify-content: end;
  }
  .barbtn {
    height: 30px;
    width: 30px;
    margin-left: auto;
    margin-right: 30px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .nav-links {
    /* list-style: none;
    display: block;
    background-color: gray;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: visible; */
    display: none;
  }
  .nav-links li {
    margin: 10px;
  }

  .image-container {
    width: 100%;
    max-width: 77px; /* Adjust as needed */
    /* margin: 0 112px;  */
    margin: 0 520px 0 50px;
    display: flex;
    justify-content: end;
  }
  .barbtn {
    height: 30px;
    width: 30px;
    margin-left: auto;
    margin-right: 30px;
  }
}

@media (min-width: 769px) and (max-width: 2800px) {
  .nav-links {
    list-style: none;
    display: flex;
  }
  .barbtn {
    display: none;
  }
}

.home {
  background-color: white !important;
}
.donation2 {
  background-color: #f4efff !important;
}
.donation {
  background-color: #f4efff !important;
}
.laboratory2 {
  background-color: transparent !important;
}
.laboratory {
  background-color: transparent !important;
}

.pharmcy {
  background-color: #ebebe9 !important;
}
.pharmcy2 {
  background-color: #ebebe9 !important;
}
.doctor {
  background-color: #d4d4d4 !important;
}
.doctor2 {
  background-color: #d4d4d4 !important;
}
.Hospital2 {
  background-color: #a5ecfc !important;
}
.Hospital {
  background-color: #a5ecfc !important;
}
.ambulanceservices {
  background-color: #7ac8e2 !important;
}
.ambulanceservices2 {
  background-color: #7ac8e2 !important;
}
.Physiothrapist {
  background-color: #f7f7f4 !important;
}
.Physiothrapist2 {
  background-color: #f7f7f4 !important;
}
.nutritionist2 {
  background-color: #ffffff !important;
}
.nutritionist {
  background-color: #ffffff !important;
}
.paramedicstaff2 {
  background-color: #f7f7f4 !important;
}
.paramedicstaff {
  background-color: #f7f7f4 !important;
}
.psychologist2 {
  background-image: linear-gradient(to left, #d2d5d2, #d7dad5 50%) !important;
}
.psychologist {
  background-image: linear-gradient(to left, #d2d5d2, #d7dad5 50%) !important;
}
.hotel {
  background-color: #f0eee1 !important;
}
.hotel2 {
  background-color: #f0eee1 !important;
}
.insurance {
  background-color: #d4d1cf !important;
}
.insurance2 {
  background-image: linear-gradient(to left, #e9e8e6, #d0c7c5 100%) !important;
}
.rentAcar {
  background-color: #e6e6e6 !important;
}
.rentAcar2 {
  background-color: #e6e6e6 !important;
}
.travelAgency {
  background-image: linear-gradient(to left, #ffd93c, #ffe85d 50%) !important;
}
.travelAgency2 {
  background-image: linear-gradient(to left, #ffd93c, #ffe85d 50%) !important;
}
.laboratoryAgreement {
  background-color: white !important;
}

.privacyPolicies {
  background-color: white !important;
}

.refundPolicy {
  background-color: white !important;
}

.termsofUse {
  background-color: white !important;
}
