.back {
  height: 24px;
  width: 24px;
  margin-right: 16px;
  color: #00276d;
  cursor: pointer;
}
.mr56 {
  margin-right: 56px;
}
.myselfcard {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  width: 300px;
  height: auto;
  box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.08);
  margin-top: 24px;
  box-sizing: border-box;
  border: 2px solid white;
  margin-right: 24px;
}
.myselfcard:hover {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  width: 300px;
  height: auto;
  box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.08);
  margin-top: 24px;
  box-sizing: border-box;
  margin-right: 24px;
  border: 2px solid #00276d;
  cursor: pointer;
}
.textcontainer {
  height: 160px !important;
  overflow: hidden;
}
.textdata {
  margin-top: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.healtImgss {
  height: 154px;
  width: 195px;
  display: flex;
  margin: 16px auto 0 auto;
}
.familyImg {
  height: 154px;
  width: 100%;
  display: flex;
  margin-top: 16px;
}
