.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 0.5px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: #fff !important;
}

.container:focus-within {
  border: none;
  background: linear-gradient(to right, #ff7631, #2575fc);
  padding: 2px;
}

.label {
  font-size: 14px;
  color: #757575;
  margin-bottom: 4px;
}

.password_field {
  width: 100%;
  height: 6vh;
  padding: 10px;
  font-size: 16px;
  color: #1f1f1f;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #fff !important;
}

.field {
  width: 100%;
  height: 6vh;
  padding: 10px;
  font-size: 16px;
  color: #1f1f1f;
  border: none;
  background-color: #fff !important;
}

.errorField {
  border-color: #ff4d4f;
}

.errorMessage {
  font-size: 12px;
  margin-top: 4px;
  color: #ff4d4f;
  white-space: normal;
  word-wrap: break-word;
}

.showIcon {
  padding-right: 10px;
  color: #757575;
  /* color: #fff; */
  display: flex;
  align-items: center;
  /* background-color: #fff; */
  background-color: transparent !important;
  height: 3vh;
  padding: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}

.container:focus-within .showIcon {
  color: #fff !important;
}
.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 8px;
  margin-top: 8px;
  font-size: 14px;
}

.removeTagButton {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
}
