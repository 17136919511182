.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formcontainer {
  box-shadow: 0px 24.35px 75.27px 0px #00000025,
    0px 45.54px 140.79px 0px #0000001e, 0px 109px 337px 0px #00000015;
  padding: 20px;
  border-radius: 8px;
  width: 64.25%;
  height: 76.44%;
  max-height: 668px;
  justify-content: center;
  display: flex;
  flex-direction: column;
z-index: 10;
  background-color: #fff;
}
.backgroundimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.SwitchButtonConatiner {
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.BackIcon {
  margin-right: 8px;
  font-size: 16px;
}

.BackToHome {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 768px) {
  .SwitchButtonConatiner {
    padding: 0 5%;
  }

  .BackIcon {
    font-size: 12px;
  }

  .BackToHome p {
    font-size: 12px;
  }
}

.logoconatiner {
  width: 120px;
  margin-bottom: 26px;
}
.logo {
  width: 20%;
  height: 20%;
  object-fit: contain;
  margin: 0 auto;
  margin-bottom: 24px;
}
.colorBlue {
  color: #00276d;
}

.colorOrange {
  color: #ff7631;
}
.welcomback {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  text-decoration-skip-ink: none;
  margin-bottom: 4px;
}

.inputGroupSocial {
  display: flex;
  flex-direction: column;
  width: 405px;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

input::placeholder {
  color: #999;
}

.showIcon {
  position: absolute;
  right: 40px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #6f6f6f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputGroupBasic {
  flex: 1;
  min-width: 400px;
  margin-right: 32px;
}
.RembermeContainer {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 56px;
  /* gap: 10px; */
}

.agreementText {
  font-size: 12px;
  color: #000;
  max-width: 100%;
}

.radioContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radioInput {
  display: none;
}

.radioMark {
  width: 15px;
  height: 15px;
  border: 2px solid #ff7631;
  background-color: #fff;
  position: relative;
  margin-right: 10px;
  transition: background-color 0.2s;
}

.radioInput:checked + .radioMark {
  background-color: #0056b3;
}

.radioInput:checked + .radioMark::after {
  content: "";
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 2px;
  left: 7px;
  transform: rotate(45deg);
}
.Remberme {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttonContainer {
  margin-bottom: 8px;
  margin-top: 20px;
}

/* .............................Media Queries............................... */
@media (max-width: 2560px) {
  .formcontainer {
    width: 40%;
    height: 70%;
  }
  .logoconatiner {
    width: 120px;
    height: 140px;
    margin-bottom: 16px;
  }

  .buttonContainer {
    /* margin-bottom: 50px; */
    margin-top: 15px;
  }
}
@media (max-width: 1440px) {
  .formcontainer {
    width: 40%;
    height: 70%;
  }
  .logoconatiner {
    width: 200px;
    height: 220px;
    margin-bottom: 16px;
  }

  .buttonContainer {
    /* margin-bottom: 50px; */
    margin-top: 15px;
  }
}

@media (max-width: 1366px) {
  .formcontainer {
    width: 45%;
    height: 70%;
  }
  .logoconatiner {
    width: 200px;
    height: 220px;
    margin-bottom: 16px;
  }

  .buttonContainer {
    /* margin-bottom: 80px; */
    margin-top: 15px;
  }
}
@media (max-width: 1024px) {
  .formcontainer {
    width: 45%;
    height: 80%;
  }
  .logoconatiner {
    width: 80px;
    height: 100px;
    margin-bottom: 10px;
  }

  .buttonContainer {
    /* margin-bottom: 20px; */
    margin-top: 12px;
  }
  .welcomback {
    margin-bottom: 4px;
    /* margin-bottom: 20px; */
  }
}
@media (max-width: 768px) {
  .formcontainer {
    width: 55%;
    height: 85%;
  }
  .logoconatiner {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .buttonContainer {
    /* margin-bottom: 20px; */
    margin-top: 12apx;
  }
  .welcomback {
    margin-bottom: 4px;
    /* margin-bottom: 20px; */
  }
}

@media (max-width: 480px) {
  .formcontainer {
    margin-top: 20px;
    width: 80%;
    border-radius: 4px;
    height: 85%;
  }

  .logoconatiner {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .buttonContainer {
    /* margin-bottom: 20px; */
    margin-top: 12px;
  }
  .welcomback {
    margin-bottom: 4px;
    /* margin-bottom: 20px; */
  }
}

.LabImg {
  height: 553px;
  width: 452px;
  margin-top: 50px;
}
.MianOuter {
  padding: 100px 112px 0px 112px;
  box-sizing: border-box;
}
.lock {
  width: 337px;
  height: 552px;
  margin-right: 100px;
}
.BackIcon {
  height: 14px;
  width: 14px;
  color: #00276d;
  cursor: pointer;
  margin-right: 8px;
}
.colorGray {
  color: #313131;
}

.mt24 {
  margin-top: 24px;
}
.mt16 {
  margin-top: 16px;
}
.mt50 {
  margin-top: 50px;
}
.mt40 {
  margin-top: 40px;
}
.mt100 {
  margin-top: 100px;
}
