/* Image Swiper Container */
.image-swiper {
  position: relative;
  width: 100%;
}

/* Main Swiper Styling */
.main-swiper {
  width: 100%;
  height: 400px; 
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  /* transition: transform 0.3s ease-in-out;  */
}
/* .main-image:hover {
  transform: scale(1.2);
  border-radius: 8px;

} */
.App {
  text-align: center;
  position: absolute;
  z-index: 1000;
  bottom: 18px;
  border: 1px solid black;
}

.App-header {
  color: white;
}
.gradient-banner {
  background: linear-gradient(to right, #99471D00, #FF7631,#99471D00);
  /* padding-top: 18px; */
  /* padding-bottom: 18px; */
  font-size: 32px;
  font-weight: bold;
  color: white;
  width: 480px;
  text-align: center;
  position: absolute;
  z-index: 1000;
  bottom: 18px;

}

/* Thumbs Swiper Styling */
.thumbs-swiper {
  margin-top: 10px;
  border-radius: 8px;
  display: flex;
  overflow-x: auto;
  justify-content: center;
}/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.thumbs-swiper::-webkit-scrollbar {
  display: none;
}
.thumbnail {
  width: 100%;
  height: auto;
  /* object-fit: cover; */
  cursor: pointer;
  border-radius: 8px;
}
/* 
/* Responsive Styles */

/* For large screens */
@media (min-width: 1024px) {
  .main-swiper {
    height: 450px; /* Slightly smaller for large screens */
  }
  .thumbs-swiper {
    display: flex;
    justify-content: center;
  }
  .thumbnail {
    /* width: 100px;  */
    height: 80px;
  }
}

/* For medium screens */
@media (max-width: 1024px) {
  .main-swiper {
    height: 400px; /* Slightly smaller for medium screens */
  }
  .thumbnail {
    /* width: 80px;  */
    height: 60px;
  }
}

/* For small screens */
@media (max-width: 768px) {
  .main-swiper {
    height: 350px; /* Smaller height for tablets */
  }
  .thumbnail {
    /* width: 70px;  */
    height: 50px;
  }
  .gradient-banner {
    font-size: 26px;

  }
}

/* For very small screens (Mobile) */
@media (max-width: 480px) {
  .main-swiper {
    height: 180px; /* Much smaller height for mobile */
  }
  .thumbnail {
    /* width: 60px; */
    height: 45px;
  }
  .gradient-banner {
    font-size: 16px;
    width: 200px;

  }

 

}
