.boxm {
  height: 300px;
  width: 390px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mb24 {
  margin-bottom: 24px;
}
.imgs {
  display: flex;
  justify-content: center;
  margin: auto;
}
.textest {
  text-align: center;
  padding: 30px 10px;
}
.btnx {
  background-color: #020e35;
  width: 100%;
  color: white;
  border: 1px solid #020e35;
  border-radius: 8px;
  padding: 15px 25px;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.AddBtn {
  height: 24px;
  width: 24px;
  color: #099bed;
  margin-left: 16px;
}
.col1 {
  width: 8.3333%;
}
.col2 {
  width: 16.6667%;
}
.col3 {
  width: 25%;
}
.col4 {
  width: 33.3333%;
}
.col5 {
  width: 41.6667%;
}
.col6 {
  width: 50%;
}
.col7 {
  width: 58.3333%;
}
.col8 {
  width: 66.6667%;
}
.col9 {
  width: 75%;
}
.col10 {
  width: 83.3333%;
}
.col11 {
  width: 91.6667%;
}
.col12 {
  width: 100%;
}
.coloorb {
  background-color: #edf4fc;
  border-radius: 10px 10px 0 0;
  padding: 0 !important;
}
.edit {
  height: 40px;
  width: 117px;
  border: 1px solid #f0f2f6;
  border-radius: 5px;
  background-color: #f0f2f6;
}
.flx {
  display: flex;
  flex-direction: row;
}
.end {
  display: flex;
  justify-content: end;
}
.editbtn {
  padding: 2px 10px 0 0;
}
.mt67 {
  margin-top: 67px;
}
tr:hover {
  background-color: #020e35;
  color: white;
}
.start {
  text-align: start;
}

.img8 {
  height: 16px;
  width: 16px;
}
.boxxedit {
  width: 117px;
  background-color: #f0f2f6;
  padding: 10px;
  border-radius: 5px;
  height: 22px;
  margin-top: 60px;
}
.boxxedit:hover {
  background-color: #00276d;
  color: white;
}
.close {
  outline: none;
  font-size: 22px;
  background-color: transparent;
  color: gray;
  border: none;
}
.outer {
  height: 90vh;
}
.tabless {
  margin-top: 32px;
  height: 350px;
  overflow: hidden;
  margin-bottom: 40px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 50px;
}
.mt32 {
  margin-top: 32px;
}
.my32 {
  margin: 32px 0;
}
.back {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-right: 16px;
}
.mt32 {
  margin-top: 32px;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.btnWidth {
  width: 210px;
  margin-top: 56px;
}
.buttoons:hover {
  background-color: #f9a035;
  border-radius: 8px;
}
.reset {
  margin: 0 12px;
  height: 25px;
  width: 25px;
  color: #00276d;
  padding: 10px;
}
.end {
  margin-left: auto;
}
.cancel {
  background-color: #00276d;
  border-radius: 8px;
  width: 110px;
  padding: 5px 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #00276d;
}
.btnDelete {
  background-color: red;
  border-radius: 8px;
  width: 110px;
  padding: 5px 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid red;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #099bed;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #c9d8e2;
}
