.containerAppointment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 20px;
  justify-items: center;
  padding: 20px;
  margin: auto;
  max-width: 1000px;
}
.infoAppointment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 100px;
}

.topInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 80px;
}
.downloadButtonLogo {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.nameAppointment {
  font-size: 16px;
  color: #0e54a3;
  font-weight: bold;
  margin-bottom: 5px;
}

.dateAppointment {
  font-size: 12px;
  color: #0e54a3;
  margin-left: auto;
  padding-right: 20px;
}

.cardAppointment {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px 20px;
  position: relative;
  max-width: 500px;
  height: 150px;
}

.photoAppointment {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.infoAppointment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 100px;
}

.nameAppointment {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.specializationAppointment {
  font-size: 14px;
  color: #345185;
  margin-bottom: 2px;
}

.qualificationsAppointment,
.clinicAppointment {
  font-size: 12px;
  color: #0e54a3;
  margin-bottom: 2px;
}

.statusAppointment {
  font-size: 12px;
  color: #ea0234;
  margin-top: 4px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: auto;
  max-width: 250px;
}

.button {
  flex: 1;
  margin: 0;
  padding: 6px 12px;
  color: white;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 12px;
}

.buttonUpcoming {
  background-color: #0047ab;
}

.buttonRecord {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.button:hover {
  background-color: #0047ab;
}

.buttonRecord:hover {
  background-color: #0047ab;
  color: #ffffff;
}
/* Responsive adjustments */
@media (max-width: 600px) {
  .containerAppointment {
    grid-template-columns: 1fr;
  }
}

.loadMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0e54a3;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  outline: none;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 10px;
}

.loadMoreButton:hover {
  background-color: #0056b3;
}
/* Responsive adjustments */
@media (max-width: 600px) {
  .containerAppointment {
    grid-template-columns: 1fr;
  }
}

.loadMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0e54a3;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  outline: none;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 10px;
}

.loadMoreButton:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
  .containerAppointment {
    grid-template-columns: 1fr;
    max-width: 90%;
    padding: 10px;
    margin: auto;
    overflow: hidden;
  }

  .cardAppointment {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 70%;
    padding: 15px;
    margin: 0 auto 15px;
  }

  .photoAppointment {
    position: static;
    transform: none;
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
  }

  .infoAppointment {
    margin-left: 0;
    align-items: center;
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .topInfo {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-right: 0;
    gap: 5px;
  }
}

@media (max-width: 480px) {
  .containerAppointment {
    max-width: 75%;
  }

  .nameAppointment {
    font-size: 14px;
  }

  .dateAppointment {
    font-size: 10px;
  }

  .specializationAppointment,
  .qualificationsAppointment,
  .clinicAppointment {
    font-size: 12px;
  }
}

/* -----------Detail Section------------ */

.containerDetail {
  display: flex;
  justify-content: space-around;
  padding: 20px;

  border: 1px solid #ccc;
  margin: auto;
  margin-bottom: 10px;
  max-width: 1120px;
}

.appointmentSectionDetail {
  width: 48%;
  padding: 20px;
  background: white;
  border-radius: 8px;
}
.paymentSectionDetail {
  width: 48%;
  margin-top: 70px;
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.appointmentIdDetail {
  color: #ff7631;
  padding: 2px 6px;
  border-radius: 4px;
}

.dashedLineDetail {
  width: 100%;
  height: 2px;
  background: transparent;
  border-top: 2px dashed #0047ab;
  margin: 20px 0;
}
.dashedLineDetailPayment {
  width: 100%;
  height: 2px;
  background: transparent;
  border-top: 2px dashed #0047ab;
  margin: 20px 0;
}
.headingMainDetail {
  color: #0047ab;
  margin: 0;
}

.infoDetail,
.paymentInfoDetail {
  display: flex;
  flex-direction: column;
}

.rowDetail {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  font-size: 16px;
  color: #0047ab;
}
.rowDetailAmount {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  font-size: 16px;
  color: #ea0234;
}
.amountRightAmount {
  margin-left: auto;
  margin-right: 20px;
  color: #ea0234;
  text-align: right;
  min-width: 100px;
}

.amountRight {
  margin-left: auto;
  margin-right: 20px;
  color: #0047ab;
  text-align: right;
  min-width: 100px;
}
.paymentHeading {
  margin-top: 20px;
  color: #ea0234;
}

.paymentButtonDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  outline: none;
  width: fit-content;
  margin-bottom: 10px;
  margin-left: auto;
}
.paymentButtonDetail:hover {
  background-color: #0056b3;
}

/* -------Delete Account------ */
.deleteAccountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 50px auto;
}

.heading {
  color: #00276d;
  font-weight: 600;
  margin-bottom: 20px;
}

.emailInput {
  width: 100%;
  padding: 14px 22px;
  border: 1px solid rgba(0, 71, 171, 0.3);
  border-radius: 8px;
  font-size: 18px;
  margin-bottom: 25px;
  background-color: #f7f7f7;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.emailInput:focus {
  outline: none;
  border-color: #0047ab;
  box-shadow: 0 4px 10px rgba(0, 71, 171, 0.2);
  background-color: #fff;
}

.deactivateBtn {
  background: linear-gradient(135deg, #0047ab, #00276d);
  color: white;
  border: none;
  padding: 14px 28px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 6px 15px rgba(0, 71, 171, 0.3);
}

.deactivateBtn:hover {
  background: linear-gradient(135deg, #00276d, #00194e);
  box-shadow: 0 10px 20px rgba(0, 71, 171, 0.4);
  transform: translateY(-2px);
}

.deactivateBtn:active {
  transform: translateY(1px);
  box-shadow: 0 4px 10px rgba(0, 71, 171, 0.2);
}
.overlayDelete {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmationContainer {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.confirmationText {
  font-size: 20px;
  color: #333;
  margin-bottom: 25px;
}

.confirmationButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.noBtn {
  background-color: #0047ab;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.yesBtn {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.yesBtn:hover {
  background-color: #c9302c;
}

.YesBtn {
  background-color: #d9534f;
}

.noBtn:hover {
  background-color: #0047ab;
}
.requestImageContainer {
  text-align: center;
  margin: 50px auto;
  max-width: 500px;
}

.requestImage {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.requestSentText {
  font-size: 24px;
  color: #0047ab;
  margin-top: 10px;
}
.errorMessageConformDelete {
  color: red;
  font-size: 14px;
  margin-bottom: 5px;
}
