.modal {
  position: fixed; /* Fixing the modal overlay to the viewport */
  top: 0;
  left: 0;
  /* top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", */
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  position: relative;
  background: white;
  width: fit-content;
  max-width: 70vw;
  height: 70vh;
  max-height: 90vh;
  padding: 10px;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
  padding: 24px 36px;
}
.datacontainer {
  width: 100%;
  height: 100%;
  /* padding: 24px 36px !important; */
  box-sizing: border-box;
}

.modalContent::-webkit-scrollbar {
  display: none;
}
.pageHeading,
.modalHeading,
.sectionTitle,
.mainTitle,
.dataText {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #000000 !important;

}
.modalHeading {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000 !important;
}
.pageHeading {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000000 !important;
}

.sectionTitle {
  font-size: 14px !important;
  color: #000000 !important;
}

.mainTitle {
  font-size: 12px !important;
  color: #000000 !important;
}

.dataText {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  white-space: nowrap;
}
.invoiceheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logocontainer {
  width: 58px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
}
.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.separator {
  margin: 5px 0;
  border-bottom: 2px solid #7d7d7d;
}
.dashline {
  display: inline-block;
  width: 150px;
  border-bottom: 1px solid #000;
  line-height: 1.5;
  display: flex;
}
.Remarksdashline {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #000;
  /* line-height: 1.5; */
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
}
.Patientsection {
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
  justify-content: space-between;
}
.patientItems {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 40%;
  align-items: baseline;
}
.Patientdashline {
  width: 180px;
  border-bottom: 1px solid #000;
  line-height: 1.5;
  display: flex;
  justify-content: flex-end;
}
.downloadbtnconatiner {
  position: absolute;
  top: 10px;
  right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.downloadbutton {
  width: 44px;
  height: 36px;
  color: #fff;
  padding: 14px;
  border-radius: 24px;
  background-color: #0e54a3;
  box-shadow: 4px 4px 12px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: none !important;
  cursor: pointer;
}

.downlodimg {
  width: 16px;
  height: 16px;
}
.datatable{
  overflow: auto;
  /* display: block; */
  width: 100%;
}
.datatable,
.datatr,
.datathead,
.datatd,
.datath {
  border: 1px solid #000000 !important;
}
/* .datathaed {
  position: sticky !important;
  top: 70px;
  z-index: 100;
  background-color: aliceblue;

} */
.thsticky ,.datath{
  position: relative !important;
  /* top: 0px; */
  z-index: 100;
  background-color: aliceblue;
  transition: top 0.3s ease;
  color : #000000;
}
