.parentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadContainer {
  border-width: 1px;
  border-style: dashed;
  border-radius: 16px;
  margin-top: 16px;
  border-color: #7d7d7d;
  background-color: #e3ebed;
  margin-right: 5%;
  padding: 16px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.uploadImage {
  width: 18px;
  height: 18px;
  object-fit: contain;
  filter: invert(34%) sepia(22%) saturate(3496%) hue-rotate(202deg)
    brightness(98%) contrast(102%);
}

.fileContainer {
  position: relative;
  border-width: 1px;
  border-style: dashed;
  border-radius: 16px;
  margin-top: 16px;
  width: 40%;
  overflow: hidden;
  border-color: #7d7d7d;
  background-color: #f6f7f9;
  margin-right: 5%;
  height: 75px;
}
.travelerInfo {
  /* margin: 16px 24px; */
  background-color: #fff;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 8px;
  padding: 8px;
  margin-right: 5%;
  margin-top: 24px;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  /* padding-bottom: 32px; */
}
.mainView {
  display: flex;
  justify-content: space-between;
}
.label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #00276d;
}
.deleteButton {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.deleteIcon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
