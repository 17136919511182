.imgcontainer {
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000014;
  background-color: #f5f9ff;
  width: 160px;
  height: 160px;

  border-radius: 16px;
  overflow: hidden;
  margin: 10px 15px;
}
.cardimg {
  width: 70%;
  height: 70%;
  
  /* width: 160px;
  height: 160px; */
  object-fit: cover;
  
  overflow: hidden;
  cursor: pointer;
  padding: 10px;
}
.textOverlay {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #0e54a3;
  font-size: 18px;
  font-weight: bold;
}
.barcontainer {
  background-color: #f5f9ff;
  box-shadow: 0px 4px 4px 0px #00000014;
  border-radius: 8px;
  gap: 20px;
  margin: 15px;
}
.trustimg {
  width: 32px;
  height: 32px;
  
  overflow: hidden;
}
.uniqueTextColor{
  color: #0E54A3;
  font-weight: 400;
  margin-bottom: 32px;
  font-size: 16px;
}
.customTextStyle {
  font-size: 40px;
  margin-top: 59px;
  font-weight: 600;
  color: #0E54A3;
  margin-bottom: 150px;
}
.customParagraphStyle {

  font-weight: 400;
  color: #0E54A3;
  font-size: 16px;
}
.customMarginTextStyle {
  margin-top:59px;
}
@media (max-width: 1024px) {
  

  .customTextStyle {
    font-size: 32px;
    margin-bottom: 120px;
  }

  .customParagraphStyle, .uniqueTextColor {
    font-size: 14px;
  }

  .barcontainer {
    margin: 10px;
  }
}

@media (max-width: 768px) {
 

  .customTextStyle {
    font-size: 28px;
    margin-bottom: 100px;
    margin-top: 40px;
  }

  .customParagraphStyle, .uniqueTextColor {
    font-size: 12px;
  }

  .barcontainer {
    margin: 8px;
  }
}