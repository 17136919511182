.mainContainer {
    margin-top: 16px;
  }
  .text {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.25px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #131313;
    margin-top: 4px;
  }
  .mainHotelTable {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 4px 4px 12px 0px #00000040;
    margin-top: 16px;
    padding: 20px;
    height: 350px;
  }
  .rowBase {
    display: flex;
    align-items: center;
  }
  .hotelTable {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .loading {
    display: flex;
    margin-left: 12px;
  }
.lableText{
  font-size: 14px;
  font-weight: 400;
  
}