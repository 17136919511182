/* .card {
  width: 265px;
  height: fit-content;
  border-radius: 8px;
  margin: 20px;
  overflow: hidden;
  background-color: #e4e6e8;
} */
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  justify-content: flex-start;
  gap: 16px;
}

.card {
  flex: 0 1 calc(25% - 16px); /* 25% width for each card minus the gap */
  box-sizing: border-box;
  width: calc(25% - 16px);
  border-radius: 8px;
  /* margin: 20px; */
  overflow: hidden;
  background-color: #e4e6e8; /* Ensure the card takes up 25% of the container's width */
}
.flxWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.imgContainer {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.textcontainer {
  padding: 5px;
  height: auto;
}
.showMoreContainer {
  display: flex;
  justify-content: start;
  margin: 10px 0;
}

.showMoreButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f2f4f6;
  color: #0e54a3;

  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  background-color: #f2f4f6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.priceText {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 4px 8px;
  border-radius: 52px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #0e54a3;
  font-size: 16px;
  font-weight: bold;
}
.heartIconContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
  width: 20px;
  height: 20px;
}

.heartIcon {
  color: #0e54a3;

  font-size: 20px;
}
@media (max-width: 768px) {
  .card {
    flex: 0 1 calc(50% - 16px);
    width: calc(50% - 16px);
  }

  .imgContainer {
    height: 80px;
  }

  .priceText {
    font-size: 14px;
  }

  .showMoreButton {
    padding: 8px 16px;
    font-size: 14px;
  }

  .showMoreButton .icon {
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .card {
    flex: 0 1 100%;
    width: 100%;
  }

  .imgContainer {
    height: 60px;
  }

  .priceText {
    font-size: 12px;
  }

  .showMoreButton {
    padding: 6px 12px;
    font-size: 12px;
  }

  .showMoreButton .icon {
    font-size: 16px;
  }
}
.marginTopContainer {
  margin-top: 50px;
}
