.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 48px 0px #0000000a;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.modalContent {
  width: 560px;
  height: 255px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 16px;
  /* padding: 5px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
