.Cardcontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.Card {
  flex: 1 1 auto;
  width: 40%;
  background-color: white;
  border-radius: 16px;
  padding: 20px 32px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  color: #00276d;
  margin: 10px 0;
}

.mianCardConatiner {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 12px;
}
.mianCards {
  color: #00276d;
  flex: 1 1 1 1 auto;
  width: 23.7%;
  padding: 20px;
  background-color: white;
  border-radius: 16px;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  box-sizing: border-box;
  border: 2px solid black;
}
@media (max-width: 480px) {
  .Cardcontainer,
  .mianCardConatiner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .Card,
  .mianCards {
    width: 90%;
  }
}

.graph {
  background-color: white;
  border-radius: 15px;
  padding: 15px 0;
  margin-right: 6px;
}
.chart {
  height: 89px;
  width: 89px;
  margin-top: 20px;
}
.Profile {
  height: 50px;
  width: 50px;
}
.mt24 {
  margin-top: 24px;
}

.w188 {
  width: 188px;
  display: block;
}
.arrow {
  height: 27px;
}
.profile {
  width: 300px;
  height: 260px;
  border-radius: 16px;
  background-image: -webkit-linear-gradient(right, #ff7631, #fb2047);
}
.flx {
  display: flex;
  flex-direction: row;
}
.doctor {
  height: 257px;
  width: 177px;
  display: flex;
  margin: auto;
}
.big_avatar {
  /* position: absolute; */
  /* bottom: 15%; */
  /* display: flex; */
  margin: auto;
  border: 3px solid #a2968e;
}
.mt16 {
  margin-top: 16px;
}
.mt14 {
  margin-top: 10px;
  font-size: 15px;
}

.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.textcenter {
  text-align: center;
}
.tables {
  height: 280px;
  overflow: auto;
  margin-bottom: 50px;
}
/* width */
.tables::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.tables::-webkit-scrollbar-track {
  background: #a4a2a2;
  border-radius: 5px;
}

/* Handle */
.tables::-webkit-scrollbar-thumb {
  background: #00276d;
  border-radius: 5px;
}

/* Handle on hover */
.tables::-webkit-scrollbar-thumb:hover {
  background: #00276d;
}
.selections {
  color: #00276d;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent !important;
}
.selections:focus {
  border: none;
  outline: none;
}
.mt32 {
  margin-top: 32px;
}
.my32 {
  margin: 32px 0;
}
.arrowUp {
  height: 12px;
  width: 12px;
  color: #007201;
}
.arrowdown {
  height: 12px;
  width: 12px;
  color: #ff6600;
}
.colorOrange {
  color: #ff6600;
}
.colorGreen {
  color: #007201;
}
.iconsOuterGreen {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: #007201;
  background-color: #c4fcbb;
  margin: 0 10px;
}
.iconsOuterOrange {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: #ff6600;
  background-color: #ffedd6;
  margin: 0 10px;
}
.Refresh {
  height: 20px;
  width: 20px;
  color: #a2968e;
  margin-left: 16px;
  margin-top: 4px;
  cursor: pointer;
}
.mt24 {
  margin-top: 24px;
}
.vecttors {
  height: 80px;
  width: 80px;
  margin-top: 5px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  width: 72px;
  height: 72px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  border: none;
  object-fit: cover;
}

.modalName {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #7D7D7D;
  margin-top: 35px;
}

.modalNameWithPMDC {
  margin-bottom: 12px;
}

.modalNameWithoutPMDC {
  margin-bottom: 32px;
}

.modalPMDC {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #13A89E;
}

.modalText {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #454545;
}

.modalVerificationText {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #7D7D7D;
  margin-top: 30px;
}

.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  gap: 48px;
}

.modalButtonReject {
  width: 201px;
  height: 48px;
  border-radius: 24px;
  background-color: #7D7D7D;
  border: none;
  box-shadow: 2.79px 2.79px 8.36px 0px #00000040;
  color: #fff;
}

.modalButtonAccept {
  width: 201px;
  height: 48px;
  border-radius: 24px;
  background-color: #0E54A3;
  border: none;
  box-shadow: 2.79px 2.79px 8.36px 0px #00000040;
  color: #fff;
}
