.containerAppointmentRecord {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 20px;
  padding: 20px;
  margin: auto;
  max-width: 1000px;
}

.bodyPrescription {
  margin-top: 56px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.bodyPrescriptioncol1 {
  width: 50%;
}
.bodyPrescriptioncol2 {
  width: 50%;
  height: 315px;
  border: 1px solid #0E54A3;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;

}

.labcardcontainer{
  height: 100%;
  overflow-y: auto  !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.labcardcontainer::-webkit-scrollbar {
  display: none; 
}

.reporttext,
.testitle {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
  color: #0E54A3 !important;


}

.testitle {
  font-weight: 400 !important;
}
.testdate{
font-size: 12px;
font-weight: 400;
color: #7D7D7D;

}
.labtestimg {
  width: 11px !important;
  height: 14px !important;
  margin-top: 2px;
 
}


.labtitlehead {
  display: flex;
  align-items: flex-start;
  gap: 0 10px;
}

.labtestbtn {


  background-color: #0E54A3;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  border-radius: 24px;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 27px;
  box-sizing: border-box;


}

.labcardview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topInfoRecord {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 80px;
}

.cardAppointmentRecord {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffff;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  padding: 10px 20px;
  position: relative;
}

.selected {
  border: 2px solid #0e54a3;
}

.photoAppointmentRecord {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.cardFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.infoAppointmentRecord {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 100px;
}

.nameAppointmentRecord {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.specializationAppointmentRecord {
  font-size: 14px;
  color: #345185;
  margin-bottom: 2px;
}

.qualificationsAppointmentRecord,
.clinicAppointmentRecord {
  font-size: 12px;
  color: #0e54a3;
  margin-bottom: 2px;
}

.statusAppointmentRecord {
  font-size: 12px;
  color: #ac4040;
  margin-top: 4px;
}

.buttonContainerRecord {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: auto;
  max-width: 250px;
}

.buttonRecord {
  flex: 1;
  margin: 0;
  padding: 6px 12px;
  color: white;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 12px;
}

.buttonUpcomingRecord {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.buttonRecordRecord {
  background-color: #0047ab;
}

.buttonUpcomingRecord:hover {
  background-color: #0047ab;
  color: #ffffff;
}

.buttonRecordRecord:hover {
  background-color: #0047ab;
  color: #ffffff;
}

.buttonWithIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.clinicButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttonWithIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.menuIconDetailButtonRecordDetail {
  width: 70px;
  height: 30px;
  margin-left: 5px;
  background: transparent;
}

.loadMoreButtonRecord {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0047ab;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  outline: none;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 10px;
}

.loadMoreButtonRecord:hover {
  background-color: #0056b3;
}

/* -----------------Prescription ----------------- */

.containerPrescription {
  width: 100%;
  max-width: 960px;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.headerPrescription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.avatarPrescription {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
}

.PrescriptionDescription {
  font-size: 8px;
}

.doctorInfoPrescription {
  flex-grow: 1;
  color: #0056b3;
}

.dateInfo {
  font-size: 14px;
  color: #666;
  margin-top: 13px;
}

.videoCallPrescription {
  border-radius: 5px;
  color: rgb(32, 32, 32);
}

.downloadButtonPrescription {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0056b3;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
  margin: 0 auto;
  width: fit-content;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.sectionPrescription {
  color: #0056b3;
  margin-bottom: 18px;
  font-size: 15px;
  font-weight: 400;
}

.mediTourText {
  color: green;
}

.nameAndDetails,
.professionalDetails {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.nameAndDetails {
  color: #0056b3;
}

.professionalDetails {
  gap: 160px;
  color: #ccc;
}

.timeAndCall,
.timeAndCallVideo {
  margin-left: 10px;
}

.dateInfo,
.videoCallInfo {
  font-size: 14px;
}

.dateInfo {
  color: #0056b3;
}

.body .sectionPrescription {
  margin-bottom: 20px;
  color: #0056b3;
}

.radioGroupPrescription {
  color: #0056b3;
}

.tagsPrescription span {
  display: inline-block;
  background-color: #eee;
  padding: 5px 10px;
  color: #0056b3;
  border-radius: 5px;
  margin-right: 5px;
}

.sectionPrescriptionTop {
  margin-bottom: 10px;
  font-size: 15px;
}

.fileUploadPrescription {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  margin: 20px auto;
  width: 90%;
  border-radius: 10px;
}

.uploadArea {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.fileUploadContainer {
  text-align: center;
  padding: 20px;
  margin: auto;
}

.uploadArea {
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileDetails {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.removeFileButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.submitButton {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
}

.submitButton img {
  width: 20%;
  height: auto;
}

.fileDetails {
  margin-top: 20px;
}

.deleteButtonFile,
.submitButtonFile {
  margin-top: 10px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.submitButtonFile img {
  width: 30px;
  height: auto;
}

.uploadButtonPrescription {
  background: transparent;
  border: none;
  color: #0056b3;
  cursor: pointer;
}

.PrescriptionDescription {
  color: #0056b3;
  font-size: 20px;
  font-weight: 300;
}

.loadMoreButtonRecordPrescription {
  padding: 10px 20px;
  color: white;
  background-color: #0e54a3;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 10px;
}

.nameAppointmentRecord {
  font-size: 16px;
  color: #0e54a3;
  font-weight: bold;
  margin-bottom: 5px;
}

.dateAppointmentRecord {
  font-size: 12px;
  color: #0e54a3;
  margin-left: auto;
  padding-right: 20px;
}

.referralDetail {
  display: flex;
  width: 30%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-top: 20px;
}

.referralAvatar {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: 10px;
}

.referralInfo strong {
  font-size: 16px;
  color: #333;
}

.referralInfo p {
  font-size: 14px;
  color: #666;
  margin: 2px 0;
}

.deleteButtonFile {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.deleteButtonFile:hover {
  background-color: darkred;
}

.submitButtonFile {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: block;
  margin-top: 10px;
}

.submitButtonFile img {
  width: 200px;
  height: auto;
}

.recordPrescriptionQ {
  font-size: 11px;
}

.radioGroupPrescriptionInside {
  display: flex;
  justify-content: start;
}

.radioLabel {
  position: relative;
  padding-left: 5px;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
  line-height: 1.5;
  user-select: none;
}

.downloadButtonLogo {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.radioGroupPrescriptionInside {
  display: flex;

  margin-bottom: 20px;
}

.diagnosticCenterList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
  gap: 16px;
}

.cardWrapper {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* width: calc(24% - 16px); */
  min-height: 180px;
  /* Fixed minimum height */
  margin-bottom: 16px;
  transition: transform 0.2s ease;
  cursor: pointer;
  box-sizing: border-box;
}

.cardWrapper:hover {
  transform: scale(1.02);
  box-sizing: border-box;
}

.cardImageWrapper {
  width: 100%;

  height: 135px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImageWrapper:hover {
  cursor: pointer;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: inherit;
}

@media (min-width: 1440px) {
  .cardWrapper {
    width: calc(45% - 16px);
  }
}

@media (max-width: 1024px) {
  .cardWrapper {
    width: calc(35% - 16px);
  }
}

@media (max-width: 768px) {
  .cardWrapper {
    width: calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .cardWrapper {
    width: calc(50% - 16px);
  }
}

/* .labLogo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
} */

.labDetails {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
  height: 20px;
  overflow: hidden;
}

.medicineListContainer {
  padding: 20px;
  border-radius: 10px;
  background-color: #deeef78f;
  width: 97%;
}

.medicineList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.medicineCard {
  padding: 10px;
  border-radius: 5px;
}

.totalPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
}

.checkoutButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #deeef78f;
  color: #0e54a3;
  border: 1px solid #0e54a3;
  border-radius: 24px;
  padding: 7px 35px;


}
.checkoutButton:hover{
  background-color: #0e54a3;
  color: #ffff;
}


/* ----------Responsive---------- */
@media (max-width: 768px) {
  .containerAppointmentRecord {
    grid-template-columns: 1fr;
    overflow: hidden;
    padding: 10px;
  }

  .bodyPrescriptioncol1 {
    width: 100%;
  }

  .bodyPrescriptioncol2 {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .topInfoRecord {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 20px;
  }

  .cardAppointmentRecord {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .photoAppointmentRecord {
    position: relative;
    margin-bottom: 10px;
    left: 0;
    top: 0;
    transform: none;
  }

  .infoAppointmentRecord {
    margin-left: 0;
    width: 100%;
  }

  .buttonContainerRecord {
    max-width: 100%;
    margin: 10px 0;
  }

  .buttonRecord {
    padding: 8px;
    font-size: 14px;
    flex: 1;
  }

  .downloadButtonPrescription,
  .loadMoreButtonRecord,
  .loadMoreButtonRecordPrescription {
    width: 60%;
    padding: 12px;
  }

  .containerPrescription {
    padding: 10px;
    width: 90%;
  }

  .headerPrescription {
    flex-direction: column;
    align-items: flex-start;
  }

  .avatarPrescription {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .nameAndDetails {
    flex-direction: column;
    gap: 5px;
  }

  .professionalDetails {
    flex-direction: column;
    gap: 5px;
  }

  /* .diagnosticCenterList {
    flex-direction: column;
    align-items: center;
  } */

  .medicineList {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .totalPrice {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .medicineListContainer {
    padding: 10px;
  }

  .diagnosticCard,
  .referralDetail {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .clinicButtonContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .menuIconDetailButtonRecordDetail {
    width: 50px;
    height: 20px;
  }

  .fileUploadPrescription,
  .uploadArea,
  .fileUploadContainer {
    width: 70%;
    padding: 15px;
  }

  .checkoutButton img,
  .submitButtonFile img {
    width: 150px;
  }

  .buttonWithIcon {
    align-self: flex-start;
  }
}

/* ----------Model-------- */
.modalOverlayForPrescriptionDownload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalContentForPrescriptionDownload::-webkit-scrollbar {
  display: none;
}

.modalContentForPrescriptionDownload {
  background: white;
  padding: 2rem;
  overflow-y: auto;
  border-radius: 0.5rem;
  width: 90%;
  height: 75vh;
  max-width: 600px;
  margin-top: 5%;
  /* max-height: 90vh; */
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.closeButtonForPrescriptionDownload {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modalHeaderForPrescriptionDownload {
  text-align: left;
  margin-bottom: 2rem;
}

.boldTextForPrescriptionDownload {
  font-weight: bold;
  font-size: 1.5rem;
}

.headingNameColor {
  color: #0056b3;
}

.subTextForPrescriptionDownload {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.regNoTextForPrescriptionDownload {
  font-size: 1rem;
}

.modalImageForPrescriptionDownload {
  text-align: center;
}

.modalDoctorImageForPrescriptionDownload {
  width: 80px;
  height: auto;
}

.separatorForPrescriptionDownload {
  border: 1px solid black;
  margin: 2rem 0;
}

.modalBodyForPrescriptionDownload {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
}

.patientInfoForPrescriptionDownload {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.patientDetailsForPrescriptionDownload,
.additionalInfoForPrescriptionDownload {
  flex: 1;
}

.patientFieldForPrescriptionDownload,
.additionalFieldForPrescriptionDownload {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.labelForPrescriptionDownload {
  color: black;
  font-weight: bold;
  margin-right: 0.5rem;
}

.symptomsAndFindingsForPrescriptionDownload {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.symptomsSectionForPrescriptionDownload,
.findingsSectionForPrescriptionDownload {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.findingLabelForPrescriptionDownload {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.laboratoryTestsForPrescriptionDownload {
  margin-top: 2rem;
}

.testListForPrescriptionDownload {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 1rem;
}

.testItemForPrescriptionDownload {
  margin-bottom: 0.5rem;
}

.medicineInfoForPrescriptionDownload {
  margin-top: 2rem;
}

.medicineHeaderForPrescriptionDownload {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.medicineDataForPrescriptionDownload {
  display: flex;
  justify-content: space-between;
}

.medicineInfoForPrescriptionDownload {
  margin-bottom: 20px;
}

.medicineDataContainer {
  display: flex;
  flex-direction: column;
}

.medicineRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 170px;
}

.dataForPrescriptionDownload {
  font-size: 14px;
  padding: 5px;
  text-align: left;
  flex: 1;
}

@media (max-width: 768px) {
  .modalContentForPrescriptionDownload {
    padding: 1.5rem;
    width: 95%;
    height: 80vh;
    margin-top: 10%;
    /* height: auto; */
  }

  .boldTextForPrescriptionDownload {
    font-size: 1.3rem;
  }

  .subTextForPrescriptionDownload,
  .regNoTextForPrescriptionDownload {
    font-size: 1rem;
  }

  .closeButtonForPrescriptionDownload {
    font-size: 1.3rem;
  }

  .modalBodyForPrescriptionDownload {
    flex-direction: column;
  }

  .patientInfoForPrescriptionDownload {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .modalContentForPrescriptionDownload {
    padding: 1rem;
    width: 87vw;
    height: 80vh;
    margin-top: 20%;
  }

  .boldTextForPrescriptionDownload {
    font-size: 1.2rem;
  }

  .subTextForPrescriptionDownload,
  .regNoTextForPrescriptionDownload {
    font-size: 0.9rem;
  }
}

.marginBottomData {
  margin-bottom: 20px;
}

/* ------NEW model Patient detail design------- */
.prescriptionDownloadContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.patientInfoLeftRightContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  max-width: 800px;
  width: 100%;
}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.label {
  font-weight: bold;
  color: #0e54a3;
  flex-basis: 40%;
  font-size: 13px;
  text-align: left;
}

.value {
  flex-basis: 55%;
  text-align: right;
  color: #555;
}

.findingsContainer {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 1000px;
  margin: 0 auto;
}

.symptomsSection,
.clinicFindingsSection {
  flex-basis: 48%;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.findingLabel {
  font-weight: bold;
  color: #0e54a3;
  font-size: 1.1em;
  margin-bottom: 8px;
}

.data {
  color: #555;
  line-height: 1.6;
  font-size: 1em;
}

.labTestsContainer {
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #f8fafc;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  max-width: 700px;
  margin: 20px auto;
}

.labTestsLabel {
  font-size: 1.25em;
  color: #0e54a3;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: left;
}

.labTestsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.labTestItem {
  padding: 12px 16px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
}

.labTestItem:hover {
  background-color: #eef2f5;
}

.labTestName {
  font-size: 1em;
  color: #555;
  font-weight: 500;
}

.medicineContainer {
  padding: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  background-color: #f9fafc;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  max-width: 800px;
  margin: 20px auto;
}

.medicineHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  text-align: center;
  font-weight: bold;
  color: #0e54a3;
}

.medicineLabel {
  font-size: 1.1em;
  color: #0e54a3;
}

.medicineSeparator {
  border: none;
  height: 1px;
  background-color: #d1d5db;
  margin: 10px 0;
}

.medicineList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.medicineRow {
  display: contents;
}

.medicineData {
  font-size: 1em;
  color: #555;
  text-align: center;
  padding: 8px 0;
  border-bottom: 1px solid #e3e3e3;
}

@media (max-width: 768px) {
  .patientInfoLeftRightContainer {
    grid-template-columns: 1fr;
  }

  .findingsContainer {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }

  .symptomsSection,
  .clinicFindingsSection {
    flex-basis: 100%;
  }

  .labTestsContainer {
    max-width: 100%;
    padding: 15px;
  }

  .medicineContainer {
    max-width: 100%;
    padding: 15px;
  }

  .medicineHeader {
    grid-template-columns: 1fr 1fr;
  }

  .medicineList {
    grid-template-columns: 1fr 1fr;
  }

  .medicineRow {
    display: block;
  }

  .medicineData {
    text-align: left;
    padding: 6px 0;
  }
}

@media (max-width: 480px) {
  .label {
    font-size: 12px;
  }

  .value {
    font-size: 14px;
  }

  .medicineData {
    font-size: 0.9em;
  }

  .labTestName {
    font-size: 0.9em;
  }
}