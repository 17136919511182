.cardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 40px;
}
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
.card {
  overflow: hidden;
  text-align: center;
  width: 100%;
  max-width: 700px;
}

.card:hover {
  /* transform: translateY(-10px); */
  /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  filter: brightness(1.1);
  background-color: #fafafa; */
}

.card:hover h3 {
  /* color: #ff7631; */
}

.card h3 {
  font-size: 1.6em;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.card p {
  font-size: 16px;
  color: #666;
  text-align: justify;
  width: 90%;
  position: relative;
  z-index: 1111;
  height: 100px;
  transition: color 0.3s ease;
}
.infoBg {
  position: absolute;
  height: 130px;
  width: 130px;
  bottom: 10px;
  left: 10%;
  object-fit: contain;
  z-index: 2222;
}
.card:hover p {
  color: #444;
}

.card .icon {
  height: 21px;
  height: 21px;
}

.card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.NavIcon {
  color: white;
  font-size: 24px;
}
.card button:hover {
  background-color: #0056b3;
}
.navigationIcon {
  height: 56px;
  box-shadow: 0px 1px 3px 0px #0000004d;

  box-shadow: 0px 4px 8px 3px #00000026;

  width: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardCover {
  width: 85%;
  height: 120px;
  border-radius: 24px 24px 0 0;
  margin: auto;
  position: relative;
  overflow: visible;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardInfo {
  border-radius: 24px;
  padding: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: start;
  box-sizing: border-box;

  z-index: 111;
  overflow: hidden;
}
.coverImg {
  object-fit: cover;
}
.outerCoverImg {
  height: 350px;
  width: 350px;
  position: absolute;
  top: -120px;
  z-index: 11111111111111;
}
.h75 {
  height: 75px;
}
.CardTexxt {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: black !important;
}
@media (max-width: 425px) {
  .h75 {
    height: 0px;
  }
  .cardContainer {
    grid-template-columns: 1fr !important;
    gap: 15px;
    flex-wrap: wrap;
    padding: 15px;
  }

  .card {
    max-width: 100%;
    padding: 20px;
  }

  .card h3 {
    font-size: 1.3em;
  }

  .card p {
    font-size: 0.95em;
  }

  .card .icon {
    font-size: 2.5em;
  }

  .card button {
    padding: 8px 15px;
  }
  .outerCoverImg {
    height: 250px;
    width: 250px;
    position: absolute;
    top: -58px;
    z-index: 11111111111111;
  }
}

@media (max-width: 768px) {
  .cardContainer {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 15px;
    flex-wrap: wrap;
  }
  .navigationIcon {
    height: 40px;
    width: 40px;
  }
  .NavIcon {
    font-size: 18px;
  }
  .cardInfo {
    padding: 20px;
  }
  .card {
    max-width: 100%;
    padding: 0px;
  }

  .card h3 {
    font-size: 1.3em;
  }

  .card p {
    font-size: 0.7em !important;
  }

  .card .icon {
    font-size: 2.5em;
  }

  .card button {
    padding: 8px 15px;
  }
}

@media (max-width: 1024px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    max-width: 100%;
    padding: 0px;
  }

  .card h3 {
    font-size: 1.4em;
  }

  .card p {
    font-size: 0.9em;
  }
  .card p {
    width: 80%;
  }
}

@media (min-width: 1025px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .card h3 {
    font-size: 1.6em;
  }

  .card p {
    font-size: 16px;
    height: 100px;
  }
}
