.mainHeading {
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}

.AddBtn {
  background-color: #0e54a3;
  border: 1px solid #0e54a3;
  width: 150px;
  height: 48px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  box-shadow: 4px 4px 12px 0px #00000040;

  line-height: 12.22px;
  letter-spacing: -0.22px;
  border-radius: 24px;
}
.flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pr36 {
  padding-right: 36px;
}
.tablecontainer {
  background-color: white;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px 0px #00000040;
  padding: 24px;
  overflow: hidden;
  width: 100%;
}
.modalContent {
  background: white;
  padding: 32px;
  border-radius: 24px;
  width: 1000px;
  margin: auto;
  position: absolute;
  top: 50%;
  height: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.modalContent {
  font-weight: 700;
  font-size: 16px;
  line-height: 9.75px;
  letter-spacing: -0.17px;
  color: black;
  text-align: center;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 18px;
}
.w100 {
  width: 100%;
}
.gridContainer input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}
.flxBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.texts {
  font-weight: 700;
  font-size: 16px;
  line-height: 9.75px;
  letter-spacing: -0.17px;
}
.flexx {
  display: flex;
  align-items: center;
  gap: 12px;
}
.mt32 {
  margin-top: 32px;
}
.SaveBtn {
  cursor: pointer;
  background-color: #0e54a3;
  border: 1px solid #0e54a3;
  width: 150px;
  height: 48px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  box-shadow: 4px 4px 12px 0px #00000040;

  line-height: 12.22px;
  letter-spacing: -0.22px;
  border-radius: 24px;
}
.backBtn {
  box-shadow: 0px 1px 3px 1px #00000026;
  width: 150px;
  height: 48px;
  border-radius: 24px;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  color: #7d7d7d;
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  line-height: 12.22px;
  letter-spacing: -0.22px;
}
.errorText {
  color: red;
  font-size: 12px;
  text-align: start;
  font-weight: 400;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #0e54a3;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-left: 16px;
  background-color: #ffedd6;
  cursor: pointer;
}
