.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  flex: 1 1 160px !important;
  height: 80px;
  min-width: 355px;
  max-width: 355px;
  border-radius: 8px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border: 2px solid red;
}
.dcard {
  flex: 1 1 160px !important;
  height: 142px;
  min-width: 355px;
  max-width: 355px;
  /* width: 355px; */
  /* max-width: 355px; */
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.selected {
  border: 2px solid #0e54a3;
}
.text {
  color: #0e54a3;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.dtext {
  padding: 10px;
}
.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.showMoreButton {
  display: flex; /* Use flexbox to align text and icon */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  padding: 10px 20px;
  background-color: #0e54a3; /* Button background color */
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showMoreButton {
  /* Your existing styles */
}

.gray {
  background-color: #d9d9d9; /* Change this to the color you want for the inactive state */
  color: white; /* Text color for inactive state */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.showMoreButton:enabled {
  background-color: originalColor; /* Replace with your original color */
  color: originalTextColor; /* Replace with your original text color */
  cursor: pointer; /* Normal cursor */
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 45%;
    height: 70px;
    width: auto;
  }
  .dcard {
    flex: 1 1 50%;
    height: 90px;
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 1 100%;
    height: 60px;
    margin: 5px;
  }

  .dcard {
    flex: 1 1 100%;
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .dcard {
    flex: 1 1 1 100%;
    height: 100%;
  }
}
