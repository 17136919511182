.cardImageWrapper {
  height: 208px;
  width: 100%;
}
.cardImage {
  width: 100%;
  border-radius: 16px;
  height: 100%;
  object-fit: cover;
}
.TopDoctorList {
  margin-top: 16px;
}
.Number {
  font-size: 16px;
  font-weight: 600;
  color: #0e54a3;
}
.cardName {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: black;
  margin-top: 24px;
}
.hopTitle {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #13a89e;
  margin-top: 8px;
}
.flxB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flx {
  display: flex;
  align-items: center;
}
.w50 {
  width: 50%;
}
.w_45 {
  width: 45%;
}
.mt16 {
  margin-top: 16px;
}
.w75 {
  width: 75%;
}
.cardtime {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
.texts {
  font-family: " Poppins";
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.Iconns {
  height: 16px;
  width: 16px;
}
.CallBtn {
  width: 160px;
  height: 34px;
  border-radius: 25px;
  color: white;
  background: #0e54a3;
  border: 1px solid #0e54a3;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  cursor: pointer;
}

.mt40 {
  margin-top: 40px;
}
.departmentCardsContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  flex-wrap: wrap;
}
.departmentCards {
  background-color: white;
  border: 1px solid #7d7d7d;
  color: #7d7d7d;
  border-radius: 24px;
  padding: 15px 20px;
  font-family: "Poppins";
  width: 180px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  text-align: center;
}
.DoctorCard {
  width: 100%;
  border-radius: 16px;
  background-color: white;
  box-shadow: 4px 2px 12px 0px #00000040;
  padding: 16px;
  height: 210px;
  margin-bottom: 16px;
  display: flex;
}
.DoctoriMg {
  width: 100%;
  height: 100%;
  border-radius: 21px;
}
.DoctoriMgOuter {
  width: 20%;
}
.Carddbody {
  display: flex;
  align-items: start;
  gap: 24px;
}
.DoctorInfo {
  width: 33%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.DoctorName {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}
.Featured {
  font-family: "Poppins";
  background-color: #eaf4ff;
  font-weight: 600;
  font-size: 8px;
  padding: 2px 5px;
  border-radius: 10px;
  line-height: 14px;
  color: #0e54a3;
  letter-spacing: -0.25px;
}
.verified {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  letter-spacing: -0.25px;
  color: #13a89e;
}
.qualification {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.FontA {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.FontB {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #7d7d7d;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.Schedulecard {
  background-color: white;
  border: 1px solid #7d7d7d;
  color: #7d7d7d;
  border-radius: 24px;
  padding: 5px 10px;
  font-family: "Poppins";
  width: 120px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  text-align: center;
}
.BookBtn {
  background-color: #0e54a3;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  border: 2px solid #0e54a3;
  padding: 10px 20px;
  border-radius: 24px;
  line-height: 12.22px;
  cursor: pointer;
  letter-spacing: -0.22px;
}
.locationss {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 60%;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.ttext {
  font-family: "Poppins";
  font-weight: 400;
  margin-top: 16px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.25px;
}

.heading {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  color: #131313;
  line-height: 14px;
  text-transform: capitalize;
  letter-spacing: -0.25px;
}
.TopDoctorList {
  color: #131313;
  padding-left: 20px;
}
.TopDoctorList > li {
  margin-bottom: 8px;
  color: #131313;
}
.helpLine {
  background-color: #0e54a3;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  border: 2px solid #0e54a3;
  padding: 10px 20px;
  border-radius: 24px;
  width: 300px;
  line-height: 12.22px;
  cursor: pointer;
  letter-spacing: -0.22px;
}
.LoadBtn {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7d7d7d;
  background-color: white;
  letter-spacing: -0.5%;
  cursor: pointer;
  width: 220px;
  height: 42px;
  border-radius: 24px;
  border: 0.5px solid #7d7d7d;
}
@media (max-width: 425px) {
  .flxB {
    flex-wrap: wrap;
  }
  .departmentCardsContainer {
    justify-content: center;
  }
  .flx {
    flex-wrap: wrap;
  }
  .w50 {
    width: 100%;
  }
  .w_45 {
    width: 100%;
  }
  .w75 {
    width: 100%;
  }
  .CallBtn {
    width: 100%;
    margin-top: 24px;
  }
  .DoctoriMg {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .DoctoriMg {
    height: 200px;
  }
}
