.outer {
  padding: 30px 0px;
  margin: 0px auto 56px auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container {
  background-color: #eef5f9;
}
.mt24 {
  margin-top: 24px;
}
.pending {
  width: 100%;
  height: 85vh;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mt8 {
  margin-top: 8px;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.IMages {
  width: 100%;
  height: 320px;
}
.mt16 {
  margin-top: 16px;
}
.textcolor {
  color: #62688b;
}
.colorBlue {
  color: #0e54a3;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0%;
  text-align: justify;
}
.colorOrange {
  color: #ff7631;
  font-weight: 600;
  font-size: 24px;
  font-family: "Poppins";
  line-height: 36px;
  letter-spacing: 0%;
  text-align: justify;
}
.flx {
  display: flex;
  align-items: center;
  gap: 100px;
  justify-content: space-between;
  margin: 32px 0px;
}
.flx2 {
  display: flex;
  align-items: center;
  gap: 100px;
  justify-content: space-between;
  margin: 32px 0px;
}
.flexText {
  display: flex;
  align-items: center;
  gap: 6px;
}
.col6 {
  width: calc(50% - 38px);
}
.col5 {
  width: 30%;
}
.textjustify {
  text-align: justify;
}
.fs24 {
  font-size: 24px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .flxreverse {
    flex-direction: column-reverse !important;
  }
  .fs24 {
    font-size: 20px;
  }
  .outer {
    padding: 20px 24px;
    flex-direction: column;
    gap: 20px;
  }

  .col6 {
    width: 100%;
  }
  .flx {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin: 32px 0px;
  }
  .flx2 {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap-reverse;
    margin: 32px 0px;
  }
  .col6 {
    width: 100%;
  }
  .col5 {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .IMages {
    width: 100%;
    height: auto;
    display: flex;
    margin: auto;
  }
  .outer {
    padding: 20px 40px;
    flex-direction: column;
    gap: 20px;
  }

  .flx {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin: 32px 0px;
  }
  .flx2 {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap-reverse;
    margin: 32px 0px;
  }
  .col6 {
    width: 100%;
  }
  .col5 {
    width: 100%;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .outer {
    padding: 20px 80px;
  }

  .flx {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin: 32px 0px;
  }

  .col6 {
    width: calc(50% - 38px);
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
  .p0 {
    padding: 0% !important;
  }
}
@media (min-width: 1441px) and (max-width: 2800px) {
}
.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}
.mianheading22 {
  text-align: center;
  margin-top: 40px;
  color: #ff7631;
}

.mainDelCard {
  /* background-color: red; */
  padding: 40px;
}
.aboutext {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #131313;
  text-align: justify;
}
