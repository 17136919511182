.parent {
  background-color: #f5f5f5;
  /* padding: 36px; */
}
.main {
  background-color: #fcfcfc;
  border-radius: 10px;
  padding: 36px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  height: fit-content;
}
.title {
  color: black;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.title::after {
  content: "";
  display: block;
  width: 50%;
  border-bottom: 1px solid black;
  position: absolute;
}

.profileClick {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

/* ............................ New Design ......................... */
.button {
  background-color: #1e50a2; /* Adjust color if needed */
  height: 40px;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: semi-bold;
  border-radius: 20px; /* Rounded edges */
  margin-left: 24px;
  cursor: pointer;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: #174081; /* Slightly darker blue */
}

.button:active {
  background-color: #143666; /* Even darker when clicked */
}

.section_container {
  padding: 24px;
  margin-top: 18px;
  border-radius: 8px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}
.row > div {
  flex: 1;
}
.input_title {
  font-size: 12px;
  font-weight: regular;
  color: #7d7d7d;
  margin-left: 16px;
}
.editButton {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 17px;
  border: 1px solid rgba(30, 80, 162, 0.5);
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.editButton:hover {
  background-color: rgba(30, 80, 162, 0.1);
  cursor: pointer;
}
