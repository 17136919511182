.cardContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  padding: 16px;
}

.iconimgConatiner {
  width: 160px;
  height: 102px;
  border-radius: 8px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconimg {
  width: 100%;
  height: 100%;
  object-fit: fill;
  cursor: pointer;
  transition: all 0.3s ease;
}
.iconimg:hover {
  transform: scale(1.5);
  opacity: 0.8;
  /* filter: brightness(1.2);  */
}
.title {
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #0e54a3;
}

@media (max-width: 1200px) {
  .cardContainer {
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
}
