.container {
  position: relative;
  /* width: 100%; */
  /* max-width: 400px; */
}

.inputWrapper {
  position: relative;
  display: flex;
  justify-content: start;
  border-radius: 4px;
  border: 1px solid #ccc;
  align-items: center;
  width: 100%;
  height: 48px;
}
.paginationWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.selectedTextWrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex-wrap: nowrap;
  gap: 5px;
}

.selectedText {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 16px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedTextWrapperText {
  display: inline-block;
  max-width: calc(10ch);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input {
  width: 30%;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  outline: none;
  text-align: left;
}

.clearButton {
  margin-left: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.moreItems {
  cursor: pointer;
  font-size: 13px;
  color: #4a4a4a;
  width: auto;
  margin-left: 5px;
  position: relative;
}

.tooltip {
  position: absolute;
  top: 20px;
  right: 2px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 8px;
  z-index: 10;
  width: auto;
  max-width: 800px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  white-space: nowrap;
}

.tooltipItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdownItem.selectedItem {
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
}

.tooltipCloseButton {
  background: none;
  border: none;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}
.dropdown {
  position: absolute;
  left: 0;
  justify-content: center;
  right: 0;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 100;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
}
.arrowIcon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #333;
}

@media (max-width: 600px) {
  .selectedText {
    font-size: 10px;
    padding: 2px 4px;
    max-width: 30px;
    overflow-x: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .inputWrapper {
    max-width: 100%;
    padding: 8px;
  }

  .input {
    max-width: 31%;
    font-size: 14px;
  }

  .input::placeholder {
    font-size: 9px;
  }

  .moreItems {
    display: none;
    font-size: 3px;
  }

  .tooltip {
    max-width: none;
  }

  .inputWrapper .input {
    margin-left: 10px;
  }
}
