.imagecontainer {
  /* position: relative; */
}
.slidecontainer {
  width: 100%;
  position: relative;
  /* top: -34px; */
  height: 100vh;
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.imgsss {
  width: 100%;
  height: 120vh;
  animation: zoomIn 12s ease infinite alternate;
}

.textoverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.overlaytext {
  text-align: center;
  color: white;
}
.overlayHeading {
  font-size: 48px;
  font-weight: 800;
  text-align: center;
  color: #ff7631;
  /* animation: drop 5s ease forwards infinite;
  animation-delay: 5.5s; */
}
.drop {
  animation: drop 5s ease forwards infinite;
}
@keyframes drop {
  0% {
    transform: translateY(-500px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .textoverlay {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
  }
  .overlaytext {
    font-size: 12px !important;
    text-align: center;
    font-weight: 200;
    color: white;
  }
  .overlayHeading {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    color: #ff7631;
  }
  .Nature1 {
    font-size: 20px !important;
  }
  .headingSize {
    font-size: 20px !important;
  }
  .headingSizetoExcel {
    font-size: 20px !important;
  }
  .heals {
    font-size: 20px !important;
  }
  .slidecontainer {
    width: 100%;

    height: 100vh;
  }
  .Treatment {
    width: 230px;
    height: 56px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0e54a3;
    color: white;
    border: 2px solid #0e54a3;
    font-size: 14px;
    font-weight: 500;
    margin-right: 0px;
  }
  .OPd {
    width: 230px;
    height: 56px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff7631;
    color: #0e54a3;
    border: 2px solid #ff7631;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    margin-top: 16px;
  }
  .imgsss {
    width: 1360px;
    height: 100vh;
    animation: zoomIn 6s ease infinite alternate;
  }
  .flxcenter {
    /* margin-top: 40px; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .headingSize {
    font-size: 42px !important;
  }
  .headingSizetoExcel {
    font-size: 32px !important;
  }
  .textoverlay {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
  }
  .overlaytext {
    font-size: 16px;
    text-align: center;
    font-weight: 200;
    color: white;
  }
  .overlayHeading {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    color: #ff7631;
  }
  .imgsss {
    width: 1360px;
    height: 140vh;
    animation: zoomIn 6s ease infinite alternate;
  }
}
@media (min-width: 769px) and (max-width: 2800px) {
}

.Treatment {
  width: 230px;
  height: 56px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0e54a3;
  color: white;
  border: 2px solid #0e54a3;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 16px;
  transition: all 0.3s ease-in-out;
}
.Treatment:hover {
  background-color: white;
  color: #0e54a3;
  border: 2px solid white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 4px 12px;
  transform: scale(1.05);
}

.OPd {
  width: 170px;
  height: 56px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff7631;
  color: #0e54a3;
  border: 2px solid #ff7631;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.OPd:hover {
  background-color: white;
  color: #0e54a3;
  border: 2px solid white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 4px 12px;
  transform: scale(1.05);
}
.Icon {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}
.Iconm2 {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  color: white;
}
.OPd:hover .Iconm2 {
  color: #0e54a3;
}
.mt32 {
  margin-top: 32px;
}
.flxcenter {
  /* margin-top: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.SocialIcon {
  margin-top: 6px;
  color: white;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Initial box shadow (no glow) */
}

.SocialIcon:hover {
  box-shadow: 0 0 0 10px #ff7631;
  border-radius: 50%;
  background-color: #ff7631;
}

@font-face {
  font-family: "Nature";
  src: url(../../../../fonts/nature/Nature.ttf);
}
.Nature {
  font-family: "Nature";
}
.flxCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.white {
  color: white;
}
.orange {
  color: #ff7631;
}
.Nature1 {
  color: white;
  font-family: "Nature" !important;
  font-size: 86px;
  margin-right: 8px;
}
.headingSize {
  font-size: 86px;
}
.headingSizetoExcel {
  font-size: 60px;
}
.heals {
  font-size: 86px;
}
.mb16 {
  margin-bottom: 16px;
}
.borderright {
  border-left: 2px solid white;
  height: 72px;
  margin-left: 8px;
}
.iconsGroup {
  position: absolute;
  top: 100px;
  left: -145px;
}
.dotsGroup {
  position: absolute;
  top: 100px;
  right: -145px;
}
.flxh {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.dots {
  height: 16px;
  width: 16px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.dotsFill {
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-radius: 50%;
}
.dotsFillActive {
  height: 14px;
  width: 14px;
  background-color: #ff7631;
  border-radius: 50%;
}
/* .dots .dotsFill:hover {
  height: 14px;
  width: 14px;
  background-color: #ff7631;
  border-radius: 50%;
} */
