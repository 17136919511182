.flxWrap {
  display: flex;
  flex-wrap: wrap;
}
.TourCardss {
  width: 31.33%;
  background-color: #f4efff;
  padding: 20px;
  border-radius: 16px;
  margin-right: 18px;
  display: flex;
  flex-direction: row;
  color: #00276d;
  font-weight: 400;
  box-sizing: border-box;
  margin-top: 24px;
  min-height: 183px;
}

.TourCardss:last-child {
  margin-right: 0;
}
@media (max-width: 1024px) {
  .TourCardss {
    width: 31.33%;
    margin-right: 15px;
    /* background-color: greenyellow; */
  }
}
@media (max-width: 768px) {
  .TourCardss {
    width: 100%;
    margin-right: 0;
  }
}

/* -------------Text Area Of tour plan form code ---------------- */
.PlanTextarea {
  width: 140%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  max-height: 200px;
  min-height: 120px;
  overflow-y: auto;
  resize: vertical;
  box-sizing: border-box;
  color: #00276d;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.PlanTextarea:focus {
  border-color: #00276d;
  box-shadow: 0 4px 8px rgba(0, 39, 109, 0.3);
  outline: none;
}

.PlanTextarea::placeholder {
  color: #00276d;
  opacity: 0.8;
}

.textarea {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  max-height: 200px;
  min-height: 120px;
  overflow-y: auto;
  resize: vertical;
  color: #00276d;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.textarea:focus {
  border-color: #00276d;
  box-shadow: 0 4px 8px rgba(0, 39, 109, 0.3);
  outline: none;
}

.textarea::placeholder {
  color: #00276d;
  opacity: 0.8;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.mt32 {
  margin-top: 32px;
}
.pagesImg {
  width: 100%;
  height: 140px;
  border-radius: 8px;
}
.DateImg {
  height: 16px;
  width: 16px;
  margin-right: 16px;
  margin-top: 12px;
}
.ArrowOutward {
  height: 16px;
  width: 16px;
  margin-left: 8px;
  margin-top: 12px;
}

/* ..................media query for model  */

.mainparentdiv {
  width: 600px;
}

@media (max-width: 768px) {
  .mainparentdiv {
    width: 75vw;
  }
}
/* ..........Detail......... */

.backicon {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-right: 16px;
}
.DetailImg {
  margin-top: 32px;
  width: 100%;
  height: 300px !important;
  margin-bottom: 32px;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.deleteIcon {
  height: 24px;
  width: 24px;
  color: #00276d;
  padding: 10px;
  margin-left: 24px;
}
.editIcon {
  height: 20px;
  width: 20px;
  color: #00276d;
  padding: 12px;
}
.editIcon:hover {
  background-color: #f9a035;
  border-radius: 8px;
}
.deleteIcon:hover {
  background-color: #f9a035;
  border-radius: 8px;
}
.checkbox {
  margin-right: 32px;
  color: #00276d;
}
.close {
  margin-left: auto;
  height: 24px;
  width: 24px;
  color: #00276d;
  cursor: pointer;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  /* height: 78vh; */
  height: fit-content;
}
.outerContainer2 {
  padding: 16px;
  border-radius: 4px;
  background-color: #fff !important;
  height: auto;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #33cccc;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.fields {
  width: 47%;
  margin-bottom: 10px;
}
/* @media (max-width: 500px) {
  .fields {
    margin-bottom: 10px !important;
  }
} */
.imgs {
  height: 36px;
  width: 36px;
}
.upload {
  width: 100%;
  color: #00276d;
  text-align: center;
  border-bottom: 2px dashed #00276d;
}
.mb16 {
  margin-bottom: 16px;
}

.col4 {
  width: 31%;
}

.justifycenter {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.Picss {
  width: 70px;
  height: 70px;
  margin: 0 4px;
}
.center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.imageContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tourImg {
  width: 32%;
  border-radius: 16px;
  height: 200px;
  margin-right: 10px;
}

.imageContainer .tourImg:last-child {
  margin-right: 0;
}
.pagination {
  display: flex;
  justify-content: end;
  margin-left: auto;
}

.deleteIconTour {
  width: 20px;
  height: 20px;
  margin-left: auto;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.deleteIconTour:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}
/* Container styles for the plan and rules sections */
.planContainer,
.rulesContainer {
  padding: 12px 16px;
  margin-top: 16px;
  width: 100%;
  max-width: 800px;
  overflow-wrap: break-word;
}

.planTitle,
.rulesTitle {
  font-size: 16px;
  color: #00276d;
  font-weight: 600;
  margin-bottom: 12px;
}

.planText,
.rulesText {
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
  margin-bottom: 12px;
  /* Reduced margin */
  white-space: pre-line;
  display: block;
  width: 100%;
}

.mt16 {
  margin-top: 16px;
}

.mb24 {
  margin-bottom: 24px;
}
/* ............................. tour slider */
/* Container for the slider */ /* Container for the slider */
.displayimg {
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  display: block;
  border-radius: 8px;
}

.displayimgcontainer {
  position: relative;
  height: 235px;
  width: 544px !important;
  border-radius: 8px;
  overflow: hidden;

  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
}
.dotsContainer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s;
}
.activeDot {
  background-color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 600px) {
  .displayimg img {
    width: 300px;
  }
}
