.mainContainer {
  /* margin-top: 16px; */
}
.customcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100vw; */
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
  margin-top: 4px;
}
.button {
  /* width: 100%; */
  padding: 0px 24px;
  height: 48px;
  border-radius: 24px;
  background-color: #0e54a3;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  cursor: pointer;
}
.btnText {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}
.card {
  width: 100%;
  height: 208px;
  background-color: #fff;
  box-shadow: 4px 2px 12px 0px #00000040;
  border-radius: 16px;
  margin-top: 18px;
  overflow: hidden;
  display: flex;
  /* align-items: center; */
}
.imageContainer {
  width: 196px;
  object-fit: cover;
}
.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
}
.divcardContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.textHeader {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}
.locationText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #13a89e;
}
.rowcontent {
  display: flex;
  align-items: center;
  gap: 8px;
}
.textrow {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7d7d7d;
}
.errorText {
  color: red;
  font-size: 12px;
  text-align: start;
  font-weight: 400;
}
.footer {
  display: flex;
  flex-direction: column;
}
.footerText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}
.footerSubText {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7d7d7d;
}
.rowdisplay {
  display: flex;
  align-items: center;
  gap: 12px;
}
.bar {
  border-left: 1px solid;
  border-color: #7d7d7d;
  height: 24px;
}
.centerBar {
  border-left: 1px solid;
  border-color: #7d7d7d;
  height: 176px;
  align-self: center;
}
.end {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 469px;
  justify-content: space-between;
}
.feactureFont {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
.feacture {
  width: 150px;
  height: 34px;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;
  border: 0.87px solid;
  border-color: #0e54a3;
  align-items: center;
  justify-content: center;
  color: #0e54a3;
  font-size: 14px;
  display: flex;
  font-weight: 600;
}
.rowDetail {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.format {
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.detail {
  width: 98px;
  height: 34px;
  border-radius: 24px;
  background-color: #0e54a3;
  color: #fff;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: flex-end;
}
.rowWay {
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 18px;
}
.modalContent {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.modalActions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 32px;
}

.acceptButton {
  flex: 1;
  padding: 10px;
  background-color: #0e54a3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 4px 4px 12px 0px #00000040;
}

.cancelButton {
  flex: 1;
  padding: 10px;
  background-color: #7d7d7d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 4px 4px 12px 0px #00000040;
  border-radius: 24px;
}

.cancelButton:hover {
  background-color: #e53935;
}
.rowSearch {
  display: flex;
  align-items: center;
  gap: 24px;
}
@media screen and (max-width: 480px) {
  .text {
    font-size: 14px;
    text-align: left;
  }
  .card {
    flex-direction: column;
  }
  .imageContainer {
    width: 100%;
  }
  .rowSearch {
    gap: 12px;
    flex-direction: column;
    align-items: start;
  }
  .customcontainer {
    display: flex;
    align-items: start;
  }
  .button {
    width: 109px;
    height: 30px;
    border-radius: 16px;
    font-size: 14px;
  }
}
.modalouter {
  width: 1000px;
}
.flx {
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 18px 0;
  justify-content: space-between;
}
* {
  box-sizing: border-box;
}
.flxBtwn {
  display: flex;
  align-items: center;
  margin: 18px 0;
  gap: 18px;
  justify-content: space-between;
}
.textHeadingModel {
  font-weight: 700;
  font-size: 16px;
  line-height: 9.75px;
  letter-spacing: -0.17px;
  margin-bottom: 38px;
  text-align: center;
}
.BackBtn {
  width: 180px;
  height: 48px;
  border-radius: 24px;
  font-weight: 300;
  font-size: 18px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  color: #7d7d7d;
  box-shadow: 4px 4px 12px 0px #00000040;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  cursor: pointer;
}
.SaveBtn {
  width: 180px;
  height: 48px;
  border-radius: 24px;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.22px;
  color: white;
  box-shadow: 4px 4px 12px 0px #00000040;
  cursor: pointer;
  background-color: #0e54a3;
  border: 1px solid #0e54a3;

  /* Center text properly */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.imagePicker {
  border: 1px dashed #0e54a3;
  padding: 24px 56px;
  background-color: #f9fafc;
  display: flex;
  align-items: center;
  gap: 24px;
}
.Pickertext {
  font-weight: 600;
  font-size: 16px;
  line-height: 9.75px;
  letter-spacing: -0.17px;
  color: #7d7d7d;
}
.pickIMAGE {
  height: 56px;
  width: 56px;
  cursor: pointer;
}

.selectedImagesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 20px;
  margin-top: 18px;
}
.imageWrapper {
  width: calc(20% - 16px);
  height: 180px;
  position: relative;
}
.selectedImages {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.outerclose {
  height: 20px;
  width: 20px;
  border-radius: 8px;
  position: absolute;
  top: 1%;
  right: 1%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.iconclose {
  font-size: 16px;
  cursor: pointer;
  color: #0e54a3;
}
