.colorBlue {
  color: #00276d;
}
.my32 {
  margin: 32px 0 32px 0;
}
.end {
  margin-left: auto;
}
.profile {
  height: 120px;
  width: 120px;
  margin: 32px 0;
}
.flx {
  display: flex;
  flex-direction: row;
}
.mb8 {
  margin-bottom: 8px;
}
.back {
  margin-right: 24px;
  height: 24px;
  width: 24px;
  color: #00276d;
}
.ml16 {
  margin-left: 16px;
}
.ml56 {
  margin-left: 56px;
}
.empty3 {
  width: 256px;
  height: 189px;
  display: flex;
  margin: auto;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #746ca2;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #a2a0ac;
}
