.tableData {
  overflow: auto;
  height: 66vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.row {
  width: 100%;
  height: 8vh;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
  font-family: "Poppins", sans-serif;
}
.wapper > tr {
  color: #393a44 !important;
  /* border: 1px solid #d8e1ec; */
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px,
    rgba(136, 134, 134, 0.23) 4px 3px 10px;
  transform: scale(1);
  color: #0d47a1 !important;
  background-color: white !important;
}
.w20 {
  /* width: 16.66%; */
  font-family: "Poppins", sans-serif !important;
  text-align: center;
  /* margin-right: 4px; */
}
.headerclass {
  /* width: 18.66% !important; */
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  padding: 15px;
  font-size: 14px;
}
.headerOuter {
  display: flex;
  align-items: center;
  background-color: white;
  color: #393a44;
  border-radius: 14px 14px 0 0;
  position: sticky !important;
  top: 115px;
  font-family: "Poppins", sans-serif !important;
}
.payment {
  margin-top: 24px;
}