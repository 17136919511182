.lists > li::marker {
  font-size: 15px !important;
}
.lists > li {
  font-size: 16px;
  font-weight: 700;
  width: 120px;
  text-align: start !important;
  margin-bottom: 10px;
}
.tooltip {
  background-color: white;
  padding: 20px;
  box-shadow: 2px 2px 4px gray;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
}
.customLegend {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}
