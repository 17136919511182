/* .doctorLoginOption {
  background-color: #d4d4d4;
}
.doctorSignupOption {
  background-color: #d4d4d4;
}
.hospitalLoginOption {
  background-color: #a5ecfc;
}
.hospitalSignupOption {
  background-color: #a5ecfc;
} */
.hotel {
  background-color: #f0eee1;
}
.rentAcar {
  background-color: #e4e1e2;
}
.travelAgency {
  background-color: #ffe85d;
}
.psychologistsign {
  background-image: linear-gradient(to left, #d2d5d2, #d7dad5 50%);
}
.psychologist {
  background-image: linear-gradient(to left, #d2d5d2, #d7dad5 50%);
}
.ambulance {
  background-color: #7ac8e2;
}
.ambulancesign {
  background-color: #7ac8e2;
}
.physiotherapist {
  background-color: #f7f7f4;
}
.physiotherapistsign {
  background-color: #f7f7f4;
}
.nutritionist {
  background-color: #ffffff;
}
.nutritionistsign {
  background-color: #ffffff;
}
.selected {
  position: relative;
  color: #ff7631 !important;
}

.selected::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px; /* Adjust the size of the dot as needed */
  height: 8px;
  background-color: #ff7631; /* Adjust the color of the dot */
  border-radius: 50%;
}
.paramedicstaff {
  background-color: #f7f7f4;
}
.paramedicstaffsign {
  background-color: #f7f7f4;
}
.shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}
.nav-links {
  list-style: none;
  display: flex;
  padding-top: 25px;
}
.medical > li {
  margin: 0 100px;
  color: #00276d;
}
nav {
  /* height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 100;
  top: 0%; */
  display: none;
}

.navvlink {
  margin: 0 15px;
  font-size: 14px;
  font-weight: 600;
  color: #00276d;
}
.container {
  margin-top: 88px;
  margin-left: 112px;
}

.mr203 {
  margin-right: 203px;
}
.container2 {
  margin-left: 112px;
  padding-top: 64px;
}

.ml112 {
  margin-left: 112px;
}
.LabImg {
  height: 553px;
  width: 452px;
  margin-top: 50px;
  margin-left: 100px;
}
.cursor {
  cursor: pointer;
}
.buttonWidth {
  width: 210px;
  margin-top: 56px;
}
.flx {
  display: flex;
  flex-direction: row;
}
.border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.flxStart {
  display: flex;
  align-items: start;
}
.BackIcon {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-top: 3px;
  cursor: pointer;
}
.flxSign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 320px) and (max-width: 480px) {
  .flxBetween {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .flxSign {
    display: flex;
    flex-wrap: wrap;
  }
  .ml112 {
    margin-left: 19px;
  }
  .container {
    margin-top: 40px;
    margin-left: 19px;
  }
  .container2 {
    margin-left: 19px;
  }
  .flx {
    display: flex;
    flex-wrap: wrap;
  }
  .buttonWidth {
    width: 100%;
  }
  .mr203 {
    margin-right: 100px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .flxBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flxSign {
    display: flex;
    flex-wrap: wrap;
  }
  .ml112 {
    margin-left: 100px;
  }
  .container {
    margin-top: 40px;
    margin-left: 100px;
  }
  .container2 {
    margin-left: 100px;
  }

  .buttonWidth {
    width: 300px;
  }
  .flx {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 2800px) {
}
