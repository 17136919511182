.main {
  display: flex;
}
.col12 {
  width: 100%;
}
.sidebarContainer {
  width: 280px;
  height: calc(100vh - 48px);
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  align-items: center;
  border-radius: 16px;
  margin-right: 16px;
  border: 0px 1px 0px 0px solid #000000;
  opacity: 1;
  background-color: var(--White, #ffffff);
  box-shadow: 2px 4px 12px 0px #00000040;
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* position: fixed; */
  overflow-y: auto;

  z-index: 10;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.sidebarContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.parents {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      115.72deg,
      rgba(255, 149, 0, 0.24) 0.05%,
      rgba(4, 23, 44, 0.24) 40.87%,
      rgba(50, 89, 192, 0.24) 99.12%
    );
  background-blend-mode: multiply !important;
  min-height: 100vh !important;
  width: 100% !important;
}
.welcomeText {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7d7d7d;
}
.mainText {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  /* line-height: 14px; */
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7d7d7d;
  margin-top: 4px;
}
.notification {
  background-color: #ff9500;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  display: flex;
}
.tabbarCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* position: sticky !important;
  top: 0px !important;
  z-index: 10000 !important; */
  padding-top: 30px;
  /* clip-path: inset(0 0 0 0) !important;
  border: 2px solid red; */
}
.tabbar {
  display: flex;
  justify-content: space-between;
  padding-right: 36px;
  margin-bottom: 16px;
}
.dashboardContainer {
  flex: 1;
  overflow-y: hidden;
}
.greenTourism {
  background-color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  display: flex;
}
.notification {
  background-color: #ff9500;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  display: flex;
}
.SearchBarInput > input {
  border-radius: 24px;
  padding-right: 40px;
  padding-left: 40px;
}
.SearchBarInput {
  display: flex;
  position: relative;
  align-items: center;
}
.searchImg {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 20px;
}
.SearchIcon {
  height: 20px;
  width: 20px;
  position: absolute;
  left: 14px;
}
.logoutContainer {
  margin-top: auto !important;
}
.gap32 {
  gap: 32px;
}
@media (max-width: 768px) {
  .sidebarContainer {
    width: 70px;
  }
  .mainText {
    font-size: 16px;
  }
  .tabbar {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .gap32 {
    gap: 16px;
  }
}
