.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1200px;
  margin: 0px auto 56px auto;
}
.allContent {
  background: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.imageContainer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: auto;
  aspect-ratio: 1200 / 200;
  overflow: hidden;
}
/* .ThirdImageContainer {
  height: auto;
  
} */
.secondImageContainer {
  position: relative;
  width: 100%;
  height: 261px !important;
  margin-top: 16px;
  max-width: 728px;
  overflow: hidden;
  aspect-ratio: 728 / 261;
}
@media (max-width: 768px) {
  .imageContainer {
    max-width: 100%;
    height: 130px;
  }
}
.leftImageContainer {
  position: absolute;
  display: flex;
  align-items: flex-start;
  margin-top: 33px;
  margin-left: 32px;
  z-index: 50;
}

.leftBackgroundColor {
  background-color: #ffffff;
  width: 10vw;
  height: 10vw;
  max-width: 56px;
  max-height: 56px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.drtextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.mainHeading {
  font-size: 32px;
  margin: 0;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #ffff;
}

.subHeading {
  /* font-size: 24px;
  color: #ffff;
  font-family: "Roboto", sans-serif;
  font-weight: 400; */

  padding: 0;
  margin: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.leftSideImage {
  width: 4vw;
  height: 4vw;
  max-width: 24px;
  max-height: 24px;
  position: relative;
  z-index: 51;
}
.leftSideImageFlight {
  width: 4vw;
  height: 4vw;
  max-width: 28px;
  max-height: 24px;
  position: relative;
  z-index: 51;
}

.leftSideImageHotel {
  width: 4vw;
  height: 4vw;
  max-width: 30px;
  max-height: 15px;
  position: relative;
  z-index: 51;
}

.leftImageContainerSecond {
  position: absolute;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  margin-left: 32px;
  z-index: 50;
}

.leftBackgroundColorSecond {
  background-color: #ffffff;
  width: 10vw;
  height: 10vw;
  max-width: 56px;
  max-height: 56px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftSideImageSecond {
  width: 4vw;
  height: 4vw;
  max-width: 24px;
  max-height: 24px;
  position: relative;
  z-index: 51;
}
.serviceTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.heading {
  font-size: 32px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.serviceList {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}
@media (max-width: 768px) {
  .serviceList {
    font-size: 14px;
  }
  .heading {
    font-size: 16px;
  }
}

/* Tablet adjustments */
@media (max-width: 768px) {
  .leftImageContainerSecond {
    margin-top: 16px;
    margin-left: 16px;
  }

  .leftBackgroundColorSecond {
    width: 8vw;
    height: 8vw;
    max-width: 40px;
    max-height: 40px;
  }

  .leftSideImageSecond {
    width: 3vw;
    height: 3vw;
    max-width: 20px;
    max-height: 20px;
  }
}

/* Mobile adjustments */
@media (max-width: 480px) {
  .sideImage {
    width: 40% !important;
    position: absolute !important;
    margin-right: 8px !important;
    margin-top: 52px !important;
  }
  .textContainerDR {
    display: flex;
    margin-left: 68px !important;
    margin-top: 2px !important;
    align-items: center;
    flex-direction: column !important;
    gap: 3px;
    width: 50%;
  }
  .textContainerHome {
    display: flex;
    margin-left: 77px !important;
    margin-top: 19px !important;
    flex-direction: column !important;
    gap: 3px;
  }

  .secondSideImage {
    width: 50%;
    margin-right: 9px;
    top: 110px;
  }
  .textContainerHotel {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 32px !important;
    /* margin-top: 113px; */
    margin-left: 105px !important;
  }
  .drsubHeading {
    font-size: 14px !important;
    font-weight: 400;
    color: white !important;
    white-space: wrap;
    overflow: hidden;
    width: 70%;
  }
  .leftImageContainerSecond {
    margin-top: 12px;
    margin-left: 12px;
  }

  .leftBackgroundColorSecond {
    width: 6vw;
    height: 6vw;
    max-width: 32px;
    max-height: 32px;
  }

  .subHeadingHotel {
    font-size: 14px !important;
    font-weight: 400;
    color: white;
  }

  .headingNew,
  .headingHotel {
    font-size: 18px !important;
    font-weight: 600;
    color: white;
  }
  .subHeadingNew {
    font-size: 14px !important;
    font-weight: 600;
    color: white;
  }

  .serviceList {
    font-size: 13px;
  }
  .heading {
    font-size: 14px;
  }

  .leftImageContainer {
    margin-left: 8px;
    margin-top: 10px;
  }

  .leftBackgroundColor {
    width: 20px;
    height: 20px;
  }

  /* .leftSideImage {
    width: 10px;
    height: 10px;
  } */
  .mainHeading {
    font-size: 10px;
  }

  .subHeading {
    font-size: 9px;
  }
  .newContainerSecond,
  .FourthImageContainer,
  .ThirdImageContainer,
  .leftImageContainer,
  .imageContainer,
  .newContainer {
    height: 120px !important;
  }

  .secondImageContainer {
    height: 200px !important;
  }
  .fixedImage {
    width: 90px !important;
    height: 75px !important;

    position: relative;
    z-index: 51;
  }

  .rightSideImage {
    /* position: absolute; */
    top: 0px !important;
    right: 0 !important;
    width: 129px !important;
    height: 230px !important;
    object-fit: cover !important;
  }

  .secondBackgroundColor {
    height: 200px !important;
  }
  .secondSideImage {
    width: 50% !important;
    top: 99px !important;
  }
  .belowHeading,
  .belowHeadingPhrama {
    position: absolute;
    top: 10% !important;
    left: 22% !important;
  }
}
/* short mobile 380 */
@media (max-width: 380px) {
  .sideImage {
    width: 35% !important;
    position: absolute !important;
    margin-right: 6px !important;
    margin-top: 48px !important;
  }
  .textContainerDR {
    display: flex;
    margin-left: 58px !important;
    margin-top: 0px !important;
    align-items: center;
    flex-direction: column !important;
    gap: 2px;
    width: 45%;
  }
  .textContainerHome {
    display: flex;
    margin-left: 67px !important;
    margin-top: 15px !important;
    flex-direction: column !important;
    gap: 2px;
  }

  .secondSideImage {
    max-width: 45% !important;
    margin-right: 1px !important;
    top: 97px !important;
    border-radius: 20px;
  }
  .textContainerHotel {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 28px !important;
    margin-left: 95px !important;
  }
  .drsubHeading {
    font-size: 12px !important;
    font-weight: 400;
    color: white !important;
    white-space: wrap;
    overflow: hidden;
    width: 65%;
  }
  .leftImageContainerSecond {
    margin-top: 10px;
    margin-left: 10px;
  }

  .leftBackgroundColorSecond {
    width: 5vw;
    height: 5vw;
    max-width: 28px;
    max-height: 28px;
  }

  .subHeadingHotel {
    font-size: 12px !important;
    font-weight: 400;
    color: white;
  }

  .headingNew,
  .headingHotel {
    font-size: 16px !important;
    font-weight: 600;
    color: white;
  }
  .subHeadingNew {
    font-size: 12px !important;
    font-weight: 600;
    color: white;
  }

  .serviceList {
    font-size: 11px;
  }
  .heading {
    font-size: 12px;
  }

  .leftImageContainer {
    margin-left: 6px;
    margin-top: 8px;
  }

  .leftBackgroundColor {
    width: 18px;
    height: 18px;
  }

  .mainHeading {
    font-size: 9px;
  }

  .subHeading {
    font-size: 8px;
  }
  .newContainerSecond,
  .FourthImageContainer,
  .ThirdImageContainer,
  .leftImageContainer,
  .imageContainer,
  .newContainer {
    height: 100px !important;
  }

  .secondImageContainer {
    height: 180px !important;
  }
  .fixedImage {
    width: 80px !important;
    height: 65px !important;
    position: relative;
    z-index: 51;
  }

  .rightSideImage {
    top: 0px !important;
    right: 0 !important;
    width: 120px !important;
    height: 210px !important;
    object-fit: cover !important;
  }

  .secondBackgroundColor {
    height: 180px !important;
  }

  .belowHeading,
  .belowHeadingPhrama {
    position: absolute;
    top: 8% !important;
    left: 27% !important;
  }
}

/* tab adjustments */
@media (max-width: 768px) {
  .leftImageContainer {
    left: 3%;
    top: 10%;
    margin-top: 10px;
  }

  .leftBackgroundColor {
    width: 40px;
    height: 40px;
  }

  .leftSideImage {
    width: 24px;
    height: 24px;
  }
  .mainHeading {
    font-size: 12px;
  }

  .subHeading {
    font-size: 10px;
  }
}

.backgroundColor {
  background-color: #13a89ee5;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sideImage {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  width: 38%;
  max-width: 430px;
  height: auto;
}

.content {
  text-align: center;
  margin-top: 20px;
  max-width: 800px;
  padding: 10px;
}

@media (max-width: 768px) {
  .sideImage {
    width: 40%;
    max-width: none;
    margin-right: 12px;
    margin-top: 23px;
  }
}

.wrapperContainer {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.secondImageContainer {
  height: 240px;
  margin-top: 16px;
  max-width: 728px;
  overflow: hidden;
}

.imageContainer,
.secondImageContainer,
.newContainer,
.ThirdImageContainer,
.FourthImageContainer,
.additionalContainerFirst,
.additionalContainerSecond,
.newContainerSecond {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for transform and box-shadow */
}

.imageContainer:hover,
.secondImageContainer:hover,
.newContainer:hover,
.ThirdImageContainer:hover,
.FourthImageContainer:hover,
.additionalContainerFirst:hover,
.additionalContainerSecond:hover,
.newContainerSecond:hover {
  transform: scale(1.02); /* Applies the same scale transform on hover */
}

.secondBackgroundColor {
  background-color: #e5a200;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.secondSideImage {
  position: absolute;
  top: 62px;
  right: 0;
  width: 50%;
  max-width: 404px;
  height: auto;
  z-index: 2;
}

/* Media query for mobile screens */
@media (max-width: 520px) {
  .secondSideImage {
    width: 60%;
    margin-right: 9px;
    top: 110px;
  }
}

.ThirdImageContainer {
  width: 220px;
  height: 261px;
  position: relative;
  background-color: #ee7e37;
  border-radius: 20px;
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  overflow: hidden;
}
.FourthImageContainer {
  width: 220px;
  height: 261px;
  position: relative;
  background-color: #099bed;
  border-radius: 20px;
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  overflow: hidden;
}
.rightSideImage {
  position: absolute;
  top: 30px;
  right: 0;
  width: 129px;
  height: 240px;
  object-fit: cover;
}
.centerIconPharmacy {
  width: 20px;
  height: 26px;
  object-fit: cover;
}
@media (max-width: 871px) {
  .wrapperContainer {
    flex-direction: column;
  }
  .FourthImageContainer,
  .ThirdImageContainer {
    width: 100%;
    margin-top: 0;
  }
}
.leftWhiteBox {
  position: absolute;
  top: 4%;
  left: 2%;
  width: 56px;
  height: 56px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftWgitevertical {
  position: absolute;
  top: 5.5%;
  left: 5.5%;
  width: 56px;
  height: 56px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerIcon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.belowHeading,
.belowHeadingPhrama {
  position: absolute;
  top: 76px;
  left: 10px;
  font-size: 20px;
  color: #ffff;
  text-align: center;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .belowHeading,
  .belowHeadingPhrama {
    position: absolute;
    top: 11%;
    left: 32%;
    font-size: 20px;
    color: #ffff;
    text-align: center;
    font-weight: 600;
    /* border: 2px solid black; */
  }
  .belowHeadingPhrama {
    left: 12%;
  }
}
/* Container holding both new elements side-by-side */
.newContainerSection {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 20px;
}

.newContainer {
  width: 356px;
  height: 257px;
  background-color: #3b58b8e5;
  border-radius: 20px;

  position: relative;
}

.newContainerSecond {
  width: 356px;
  height: 257px;
  background-color: #00276de5;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.fixedImage {
  position: absolute;
  right: 1px;
  width: 159px;
  height: 139px;
  bottom: 16px;
  transform: translateY(0);
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.additionalContainerFirst {
  width: 456px;
  height: 120px !important;
  background-color: #746ca2;
  border-radius: 20px;
  display: flex;

  position: relative;
}

.imageContainerVertical {
  position: absolute;
  right: 16px;
  bottom: 50%;
  transform: translateY(50%);
}

.fixedRightImage {
  width: 78px;
  height: 78px;
}
@media (max-width: 768px) {
  .fixedRightImage {
    width: 50px;
    height: 50px;
  }
}
/* .additionalContainerSecond {
  width: 376px;
  height: 120px;
  background-color: #e25d5d;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.additionalContainerSecond {
  width: 456px;
  height: 120px !important;
  background-color: #e25d5d;
  border-radius: 20px;
  display: flex;

  position: relative;
}
@media (min-width: 872px) and (max-width: 1150px) {
  .wrapperContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .ThirdImageContainer {
    flex: 1;
    min-width: 10%;
    max-width: 34%;
  }

  .FourthImageContainer {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .additionalContainerFirst,
  .additionalContainerSecond {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}
.leftWhiteBoxTravel {
  position: absolute;
  top: 33px;
  left: 32px;
  width: 56px;
  height: 56px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textContainerNew {
  display: flex;
  margin-left: 104px;
  margin-top: 33px;
  flex-direction: column;
  gap: 3px;
}

.textContainerDR {
  display: flex;
  margin-left: 104px;

  flex-direction: column;
  gap: 3px;
}
.textContainerHome {
  display: flex;
  margin-left: 104px;
  top: 10%;
  flex-direction: column;
  gap: 3px;
}
.textContainerTravel {
  display: flex;
  margin-left: 104px;
  position: absolute;
  top: 29px;
  flex-direction: column;
  gap: 3px;
}

.headingNew {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.subHeadingContainerNew {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.subHeadingNew {
  font-size: 18px;
  color: white;
}
.textContainerHotel {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 113px;
  margin-left: 32px;
}

.headingHotel {
  font-size: 24px;
  font-weight: 600;
  font-size: 26px;
  color: white;
}

.subHeadingContainerHotel {
  display: flex;
  flex-direction: column;
}

.subHeadingHotel,
.drsubHeading {
  font-size: 16px;
  font-weight: 400;
  color: white;
}
.BgOuter {
  background-image: url("../../../../assets/images/ServicesPageBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .newContainerSection {
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .newContainer,
  .newContainerSecond {
    width: 100%;
  }
  .belowHeading,
  .belowHeadingPhrama {
    position: absolute;
    top: 10%;
    left: 15%;
    font-size: 20px;
    color: #ffff;
    text-align: center;
    font-weight: 600;
  }
}
@media (min-width: 769px) and (max-width: 1150px) {
  .newContainerSection {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .newContainer {
    width: 100%;
  }

  .newContainerSecond {
    flex: 1;
    min-width: 38%;
    max-width: 60%;
  }

  .additionalContainerFirst,
  .additionalContainerSecond {
    flex: 1;
    min-width: 10%;
    max-width: 100%;
  }
}
