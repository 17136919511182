.notificationicon {
  font-size: 24px;
  color: #0d47a1;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchcontainer > input {
  border-radius: 40px;
  height: 50px;
  width: 255px;
  padding: 10px 35px 10px 35px;
  border: none;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #909198;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px; */
}
.searchcontainer > input:focus {
  border: none;
  outline: none;
}
.searchcontainer > input::placeholder {
  color: #909198;
}
.searchcontainer {
  position: relative;
  width: 300px;
}

.searchicon {
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: #909198; /* Adjust color as needed */
  z-index: 1;
}
.Filter {
  font-size: 20px;
  position: absolute;
  top: 50%;
  color: #777; /* Adjust color as needed */
  z-index: 1;
  transform: translateY(-50%);
  right: 60px;
}
.SearchInput {
  width: calc(100% - 30px); /* Adjust to make space for the icon */
  padding-left: 30px; /* Adjust to make space for the icon */
  box-sizing: border-box;
  z-index: 0;
}
.sticky {
  border-bottom: 1px solid #949494;
  padding: 20px 0;
  background-color: #f8f8f8;
}

.primarycolor {
  color: #393a44;
}
.ProfileTab {
  position: absolute;
  z-index: 1000;
  padding: 8px 0px;
  border-radius: 8px;
  background-color: white;
  width: 150px;
  right: 40px;
  top: 80px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.profileitem {
  padding: 8px;
  cursor: pointer;
  color: #0d47a1;
  display: flex;
  align-items: center;
  justify-content: start;
}
.notificationContainer {
  margin-right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.profileitem:hover {
  background-color: #dbdbdb;
}
.itemicon {
  margin-right: 16px;
  margin-left: 16px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sticky {
    border-bottom: 1px solid #949494;
    padding: 20px 0 !important;
    background-color: #f8f8f8;
  }
  .flxBetween {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .Filter {
    font-size: 20px;
    position: absolute;
    top: 50%;
    color: #777; /* Adjust color as needed */
    z-index: 1;
    transform: translateY(-50%);
    right: 45px;
  }
  .searchcontainer {
    position: relative;
    width: 250px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .sticky {
    border-bottom: 1px solid #949494;
    padding: 19.5px 0 !important;
    background-color: #f8f8f8;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .sticky {
    border-bottom: 1px solid #949494;
    padding: 20px 0 !important;
    background-color: #f8f8f8;
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
  .sticky {
    border-bottom: 1px solid #949494;
    padding: 20px 0 !important;
    background-color: #f8f8f8;
  }
}
@media (min-width: 1441px) and (max-width: 2800px) {
  .sticky {
    border-bottom: 1px solid #949494;
    padding: 20px 0 !important;
    background-color: #f8f8f8;
  }
}

.notificationTab {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 82%; /* Adjust the top position as needed */
  right: 10.6%;
  background-color: #f5f5f5;
  padding: 20px;
  border: 3px solid white;
  border-radius: 15px;
  z-index: 1 !important;
  color: #0d47a1;
  min-height: auto;
  max-height: 280px;
  overflow: auto;
  width: auto;
}
.notificationcard {
  width: 512px;
  color: #0d47a1;
  background-color: white;
  border-radius: 16px;
  padding: 15px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}
.dot {
  height: 24px;
  width: 24px;
  margin-left: 50px;
}
