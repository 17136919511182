.mainOuter {
  width: 100%;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;

  padding: "0 0.83%";
}
.Navouter {
  position: sticky;
  z-index: 1000;
  top: 0%;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #393a44;
}
.primarycolor {
  color: #393a44;
}
.mt16 {
  margin-top: 16px;
}

.searchcontainer > input {
  border-radius: 40px;
  height: 38px;
  width: 255px;
  padding: 10px 35px 10px 35px;
  border: none;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #909198;
  margin-right: 32px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px; */
}
.searchcontainer > input:focus {
  border: none;
  outline: none;
}
.searchcontainer > input::placeholder {
  color: #909198;
}
.searchcontainer {
  position: relative;
  width: 300px;
}

.searchicon {
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: #909198; /* Adjust color as needed */
  z-index: 1;
}
.Filter {
  font-size: 20px;
  position: absolute;
  top: 50%;
  color: #777; /* Adjust color as needed */
  z-index: 1;
  transform: translateY(-50%);
  right: 60px;
}
.SearchInput {
  width: calc(100% - 30px); /* Adjust to make space for the icon */
  padding-left: 30px; /* Adjust to make space for the icon */
  box-sizing: border-box;
  z-index: 0;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.colorGray {
  color: #909198;
}
.PaymentCard {
  margin-top: 32px;
  width: 24%;
  height: 270px;
  border-radius: 8px;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.Paybutton {
  background-color: #0d47a1;
  border-radius: 8px;
  border: 2px solid #0d47a1;
  color: white;
  height: 36px;
  font-size: 16px;
  font-weight: 600;
  width: 80px;
  cursor: pointer;
}
.cardlist {
  color: #909198;
  padding-left: 20px;
  margin-top: 16px;
}
.cardlist > li {
  margin-top: 4px;
  color: #909198;
}
.mtauto {
  margin-top: auto !important;
}
.tableData {
  overflow: auto;
  height: 66vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
  font-family: "Poppins", sans-serif;
}
.wapper > tr {
  color: #393a44 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px,
    rgba(136, 134, 134, 0.23) 4px 3px 10px;
  transform: scale(1);
  color: #0d47a1 !important;
  background-color: white !important;
}
.w20 {
  width: 25% !important;
  font-family: "Poppins", sans-serif !important;
}
.headerclass {
  width: 25% !important;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  padding: 15px;
  font-size: 14px;
}
.headerOuter {
  display: flex;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  background-color: white;
  color: #393a44;
  border-radius: 14px 14px 0 0;
  position: sticky !important;
  top: 115px;
  font-family: "Poppins", sans-serif !important;
}
.payment {
  margin-top: 24px;
}
.customSelect {
  color: #393a44;
  font-size: 22px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 5px;
  width: 270px;
  border: none !important;
}
.customSelect:focus {
  border: none;
  outline: none;
}
.customOption {
  color: #393a44;
  font-size: 16px;
  font-weight: 500;
}
