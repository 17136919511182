.mb32 {
  margin-bottom: 32px;
}
.icon {
  height: 30px;
  width: 30px;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}

.mt32 {
  margin-top: 32px;
}
.TotalRequestCard {
  margin-top: 5px;
  width: 50%;
  height: 152px;
  box-sizing: border-box;
  border-radius: 24px;
  margin-right: 24px;
  background-color: #fff4de;
  padding: 15px 20px;
}
.requestIconOuter {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff947a;
}
.RevenueIconOuter {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #00276d;
}
.TotalRevenueCard {
  margin-top: 5px;
  width: 50%;
  height: 152px;
  box-sizing: border-box;
  border-radius: 24px;
  margin-right: 24px;
  background-color: #e4ecfa;
  padding: 15px 20px;
}
.HireCancelCard {
  margin-top: 24px;
  height: 175px;
  box-sizing: border-box;
  border-radius: 24px;
  margin-right: 24px;
  background-color: #f8f7f1;
  padding: 15px 20px;
}
.TotalCustomerchart {
  width: 100%;
  height: 352px;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}
.TotalRevenueChart {
  margin-top: 5px;
  margin-top: 24px;
  width: 100%;
  height: 270px;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}
.RevenueweekChart {
  margin-right: 24px;
  margin-top: 24px;
  height: 270px;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
}
