.flx {
  display: flex;
  align-items: start;
}

.container {
  background-color: #f5f5f5;
  box-sizing: border-box;
  /* width: 90%; */
  height: auto;
  max-width: 100%;
  padding: 128px 160px;
}
.DocImgOuter {
  width: calc(40% - 10px);
}
.h350 {
  height: 340px;
}
.flxBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeTable {
  border: 0.5px solid #0e54a3;
  border-radius: 24px;
  width: 100%;
  margin-top: 16px;
  padding-bottom: 16px;
}
.SpecialtiesBTN {
  font-family: "Poppins";
  background-color: white;
  color: #7d7d7d;
  box-shadow: 4px 4px 12px 0px #00000040;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5%;
  border-radius: 24px;
  width: 180px;
  border: 1px solid #7d7d7d;
  padding: 10px 0;
  cursor: pointer;
}
.lists ul > li {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #131313;
  line-height: 18px;
  letter-spacing: -0.17px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push bottom div to baseline */
  height: 100%; /* Ensure it takes full height */
}

.lists ul {
  padding-left: 20px;
  margin-top: 16px;
}
.lists ul > li::marker {
  font-size: 20px;
  color: #0e54a3;
}
.tableHeading11 {
  color: #0e54a3;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  padding: 10px 0;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.row {
  padding: 5px 16px;
  border-bottom: 0.5px solid #7d7d7d;
}
.tableHeading {
  color: #7d7d7d;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.tabletext {
  color: #7d7d7d;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
}
.tableSection {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #7d7d7d;
  border-radius: 8px;
  border: 0.5px solid #0e54a3;
  padding: 8px 0;
}
.tableSection2 {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
}
.DocImg {
  width: 100%;
  object-fit: cover;
  height: 340px;
  border-radius: 24px;
}
.headings {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.DcoName {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}
.Featured {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  color: #0e54a3;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.Verified {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #13a89e;
}

.text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #7d7d7d;
  line-height: 18px;
  letter-spacing: -0.25px;
}
.DocInfo {
  width: calc(60% - 10px);
}
.gap20 {
  gap: 20px;
}
.w30 {
  width: calc(30% - 20px);
}
.readMoreButton {
  border: none;
  outline: none;
  color: #0e54a3;
  background-color: transparent;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: right;
  vertical-align: middle;
}
.Consult {
  background-color: white;
  border-radius: 24px;
  padding: 20px;
}
.dayBtn {
  border: 1px solid #7d7d7d;
  font-family: "Poppins";
  font-weight: 400;
  background-color: white;
  color: #7d7d7d;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5%;
  border-radius: 24px;
  width: 100%;
  cursor: pointer;
  padding: 10px 0;
}
.BookingBtn {
  font-family: "Poppins";
  font-weight: 400;
  background-color: #0e54a3;
  color: white;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5%;
  border-radius: 24px;
  width: 100%;
  border: 1px solid #0e54a3;
  padding: 10px 0;
  margin-top: 16px;
  cursor: pointer;
}
.FlxClum {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.bluetext {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #0e54a3;
}
.iconss {
  color: #ff7631;
  height: 18px;
  width: 18px;
  margin-right: 12px;
}
.w70 {
  width: calc(70% - 20px);
}
.gap {
  gap: 40px;
}

.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.tcard {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: calc(40% - 16px);
}

.banner {
  background: linear-gradient(45deg, #d2eaef 17.16%, #0e54a3 89.78%);
  width: 100%;
  padding: 40px 82px 56px;
  box-sizing: border-box;
}

.maincontainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.col1 {
  display: flex;
  flex: 1 1 100%;
}

.col2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 601px) {
  .col1 {
    flex: 1 1 40%;
    margin-bottom: 20px;
  }
  .col2 {
    flex: 1 1 60%;
  }
}

.profilcard {
  max-width: 324px;
  height: 324px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}

.profileimgcontainer {
  width: 324px;
  height: 275px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #fff;
}

.profileImage {
  width: 324px;
  height: 324px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}

.textarea {
  background-color: #0e54a3;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  font-size: 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  align-items: center;
}
.heading {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  color: #ffffff;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
}

.title {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: #ffffff;
  margin-bottom: 8px;
}

.experience {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 8px;
}

.detail {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  max-width: 75%;
  color: #ffffff;
}

.showMoreButton {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background-color: #fff;
  color: #0e54a3;
  border: none;
  border-radius: 47px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: 500;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 1rem;
  color: #fff;
  padding: 5px;
  background-color: #0e54a3;
  border-radius: 50%;
  display: flex;
}

.showMoreButton:hover .icon {
  color: #0e54a3;
  background-color: #fff;
}
.showMoreButton:hover {
  background-color: #0e54a3;
  color: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.detailarea {
  background-color: #fdfdfd;
  padding: 5% 5%;
}

.areaconatiner {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: 20px;
}

.detailcol1,
.detailcol2 {
  width: 100%;
}

/* Availability */

/* DoctorAvailability.css */
.availabilityContainer {
  border: 2px dashed #b3b3b3;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #f9f9f9;
  /* max-width: 600px; */
  width: 100%;
  margin-top: 16px;
}

.availabilityText {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 500;
}

@media (min-width: 769px) {
  .detailcol1 {
    width: 50%;
  }
  .detailcol2 {
    width: 40%;

    margin-top: 25px;
  }
}

.deatilheading {
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: 500;
  color: #274760;
}

.deatiltitle,
.deatildata {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #274760;
}

.icons {
  color: #ff7631;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: "22px";
  height: "32px";
}

.dicons {
  margin-right: 15px;
  color: #0e54a3;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.flxouter {
  display: flex;
  align-items: start;
}

@media (max-width: 600px) {
  .col1,
  .col2 {
    width: 100%;
    text-align: center;
    padding-top: 32px;
    justify-content: center;
    align-items: center;
  }

  .heading {
    font-size: 1.2rem;
  }

  .title,
  .deatilheading {
    font-size: 1rem;
  }

  .detail,
  .deatiltitle,
  .deatildata {
    font-size: 0.9rem;
    max-width: 100%;
  }

  .showMoreButton {
    font-size: 0.9rem;
    padding: 10px 16px;
  }
  .col1 {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    text-align: center;
  }

  .banner {
    padding: 20px 62px 36px;
  }

  .detailcol2 {
    margin-top: 25px;
  }
}
@media (max-width: 425px) {
  .flx {
    display: flex;
    flex-wrap: wrap;
  }
  .DocImgOuter {
    width: 100%;
  }
  .w70 {
    width: 100%;
  }
  .h350 {
    height: auto;
  }
  .DocInfo {
    width: 100%;
  }
  .tableHeading {
    font-size: 13px;
  }
  .tabletext {
    font-size: 13px;
  }
  .w30 {
    width: 100%;
  }
  .flxouter {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .h350 {
    height: auto;
  }
  .flxouter {
    flex-wrap: wrap;
  }
  .w70 {
    width: 100%;
  }
  .w30 {
    width: 100%;
  }
}
@media (max-width: 1024px) {
}
@media (min-width: 1025px) {
}
