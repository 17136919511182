/* Main container to ensure full height */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure full viewport height */
}

/* Main content takes available space */
.main {
  flex: 1; /* Pushes the footer to the bottom */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 10%; */
  box-sizing: border-box;
}

.mainContent {
  max-width: 1200px; /* Restrict max width */
  width: 100%;
  text-align: center;
}

.logomedi {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.logo {
  max-width: 200px; /* Adjust logo size */
  height: auto;
}

.mediword {
  color: #ff9800;
  font-size: 32px;
  font-weight: 700;
  align-items: center;
  text-align: center;
}
.heading {
  color: #131313;
  font-size: 2rem; /* Responsive font size */
  font-weight: 700;
  text-align: center;
  max-width: 660px; /* Set fixed width */
  margin: 0 auto; /* Center the heading */
  line-height: 1.4;
  word-wrap: break-word;
  white-space: normal;
}

.highlight {
  color: #ff9800;
  font-size: 32px;
  font-weight: 700;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 800px; /* Restrict width */
  margin: 0 auto; /* Center text */
}

/* Form Styling */
.formContainer {
  width: 100%;
  /* max-width: 900px; */
  margin: 30px auto;
  padding: 20px;
  /* background: #f8f8f8; */
  border-radius: 8px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distributes space properly */
  gap: 60px; /* Maintains horizontal spacing */
}

.formGroup {
  flex: 1;
  /* min-width: 280px; */
  display: flex;
  flex-direction: column;
}

.formGroup label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left; /* Ensures labels are left-aligned */
}

.input {
  width: 98.1%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px; /* Ensure consistent height */
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: 600;
}

.submitBtn {
  width: 100%;
  max-width: 200px;
  padding: 12px;
  background: #ff9500;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;
}
.submitBtn:hover {
  background-color: #e88f00; /* Slightly darker on hover */
}
/* .............DropDown................. */

.rowSelection {
  max-width: 97.7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 48px;
}
.dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensures it stays above other elements */
  max-height: 200px;
  overflow-y: auto;
  transition: all 0.2s ease-in-out;
}

.dropdown[style*="bottom"] {
  bottom: 100%;
}
/* .......checkbox */
.checkboxGroup {
  flex: 1;
  min-width: 280px;
  display: flex;
  gap: 16px;
  align-items: center;
}
.checkboxGrouplable {
  flex: 1;
  display: flex;
  margin-left: 16px;
  gap: 16px;
  align-items: center;
}
.labletype {
  flex: 1;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

/* text area */
.medicalword {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.input_container {
  width: 100%;
}
.textArea {
  width: 98.1%;
}
/* ......placeholder text  */
.highlightedText {
  font-size: 14px;
  font-weight: 600;
}

.placeholderText {
  font-style: Montserrat;
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 400;
}
/* ......................multiselection */

.multiStyle {
  display: flex;
  flex: 1;
  text-align: left;
  min-width: 280px;
  margin-left: 24px;
  gap: 40px;
  margin-top: 8px;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.multiStyle > * {
  flex: 1 1 auto;
  min-width: 150px;
}
.radioMark {
  height: 20px;
  width: 20px;
  margin-right: 10px !important;
  color: #ff9500 !important;
}

/* Ensure footer sticks to the bottom */
.footer {
  background: #ff9800;
  color: white;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  bottom: 0;
}

/* Left Section: Logo & Copyright */
.left {
  display: flex;
  align-items: center;
}

.footer_logo {
  width: 41px;
  height: 40px;
  margin-right: 16px;
}

.left p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

/* Right Section: Social Media Icons */
.right {
  display: flex;
  gap: 16px;
}

.icon {
  color: white;
  font-size: 18px;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #ff9800;
}
/* ..........button */
.buttonstyle {
  display: flex;
  min-width: 280px;
  justify-content: space-between;
}
.textupload {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  min-width: 200px;
  text-align: left;
}

/* ,,,,error */
.error {
  color: red;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  flex: 1;
}
/* .........imageName */

.fileName {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  flex: 1;
  margin-top: 54px;
  color: red;
  text-decoration: underline;
  text-underline-offset: 4px;
}

/* .........modal content */
.modelContent {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.imgModel {
  width: 168px;
  height: 168px;
  object-fit: cover;
  align-self: center;
}

.submitText {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0%;
  color: #ff9500;
  display: inline; 
}

.submitSuccess {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  color: #131313;
  display: inline; 
}
.contact {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #131313;
  margin-top: 8px;
}
.visitWebsite {
  width: 384px;
  height: 48px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: #ff9500;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700px;
  margin-top: 36px;
}
.modelWord{
  display: flex;
  gap: 2px;
  align-items: center;
  margin-top: 36px;
}

/* .......other......submit */
/* ............Responsiveness............ */

@media (max-width: 1024px) {
  .heading {
    font-size: 1.8rem;
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .main {
    padding: 0 5%;
  }
.modelWord{
  display: flex;
  flex-direction: column;
}
  input {
    width: 97%;
  }

  .textArea {
    width: 96.7%;
  }

  .rowSelection {
    max-width: 96%;
  }
  .imgModel {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .visitWebsite {
    width: 200px;
    height: 48px;
  }

  .submitText {
    font-size: 20px;
  }
  
  .submitSuccess {
    font-size: 18px;
  }
  .contact {
    font-size: 12px;
  }
  .buttonstyle {
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
  }
  .multiStyle {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .textupload {
    font-size: 14px;
    text-align: center;
  }
  .multiStyle > * {
    flex: 1 1 45%;
    margin-bottom: 10px;
  }

  .radioMark {
    margin-right: 8px;
  }
  .heading {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.9rem;
  }
  .row {
    flex-direction: column;
    gap: 20px;
  }

  .submitBtn {
    width: 100%;
  }
  .heading {
    font-size: 1.5rem;
    max-width: 90%;
  }
  .footer {
    flex-direction: column;
    height: auto;
    padding: 15px 20px;
    text-align: center;
  }

  .left {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
  }

  .right {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.2rem;
    max-width: 95%;
  }
  .modelWord{
    display: flex;
    flex-direction: column;
  }
  .submitText {
    font-size: 18px; 
  }
  
  .submitSuccess {
    font-size: 14px; 
  }

  .visitWebsite {
    width: 180px;
    height: 48px;
  }

  .contact {
    font-size: 12px;
  }
  .textupload {
    font-size: 12px;
    text-align: center;
  }
  .multiStyle {
    justify-content: center;
    gap: 10px;
  }

  input {
    width: 94%;
  }

  .textArea {
    width: 93.2%;
  }

  .rowSelection {
    max-width: 93%;
  }

  .multiStyle > * {
    flex: 1 1 100%;
  }
}
