.outer {
  padding: 32px 160px 0px 160px;
}
.pending {
  width: 100%;
  height: 85vh;
}

.mianheading22 {
  text-align: center;
  margin-top: 40px;
  color: #ff7631;
}

.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.container {
  max-width: 1200px;
  margin: 0 auto 56px auto;
}
.colorBlue {
  color: #0e54a3;
}
.textCenter {
  text-align: center;
}
.list {
  color: #ff7631;
  margin-top: 16px;
  margin-left: 24px;
}
.list > li {
  cursor: pointer;
}
.list li:hover {
  background-color: #f0f0f0; /* Change this to your desired hover color */
}
.flx {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.mt24 {
  margin-top: 24px;
}
.flex {
  display: flex;
  align-items: start;
}
.col6 {
  width: 45% !important;
}
.mt56 {
  margin-top: 56px;
}
.fs48 {
  font-size: 48px;
}

.colmn3 {
  flex: 1 0 30%; /* Base width for three columns */
  padding: 10px; /* Adjust for spacing */
  box-sizing: border-box; /* Include padding in width */
}

.row {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to next line */
}

@media (max-width: 480px) {
  .colmn3 {
    flex: 1 0 100%; /* One column on small screens */
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .fs48 {
    font-size: 34px;
  }
  .outer {
    padding: 40px 24px 24px 20px;
  }
  .container {
    padding: 20px !important;
  }
  .flx {
    display: flex;
    flex-wrap: wrap;
  }
  .col6 {
    width: 100% !important;
  }
  .mt56 {
    margin-top: 24px;
  }
  .mtsm24 {
    margin-top: 24px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .fs48 {
    font-size: 34px;
  }
  .outer {
    padding: 50px 60px 0px 60px;
  }
  .container {
    padding: 20px 40px !important;
  }
  .flx {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
  }

  .mtmd24 {
    margin-top: 32px;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .container {
    padding: 20px 80px 20px 80px !important;
  }
}
