.outer {
  height: 91vh;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #0e54a3;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-left: 16px;
  background-color: #ffedd6;
  cursor: pointer;
}

.tabless {
  height: "100%";
  overflow: hidden;
  margin-bottom: 40px;
}

.flxbtween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.my32 {
  margin: 32px 0;
}
.outerContainer {
}

.colorBlue {
  color: #00276d;
}
.end {
  margin-left: auto;
}
.btnn {
  padding: 10px 15px;
  border-radius: 8px;
  margin-left: auto;
  font-weight: 500;
  color: #00276d;
  border: 3px solid #00276d;
}
.PaymentSS {
  height: 226px;
  width: 196px;
  border-radius: 4px;
  margin-top: 32px;
}
.colorGreen {
  color: #00b69b;
}
