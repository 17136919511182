.container {
  /* margin: 24px; */
  /* padding: 24px 24px 24px 0px; */
  margin-top: 24px;
}
.heading,
.title {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.title {
  font-size: 14px !important;
  font-weight: 400 !;
}
.profileconatiner {
  /* height: fit-content; */
  background-color: #fff;
  padding: 24px 30px;
  margin-top: 32px;
  border-radius: 16px;
  box-shadow: 4px 4px 12px 0px #00000040;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto !important;
  height: 60vh;
}
.primgconatiner {
  width: 140px;
  height: 140px;
  border-radius: 90px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 149, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}
.primg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Changebtn,
.savebtn,
.Deletebtn {
  background-color: #0e54a3;
  box-shadow: 4px 4px 12px 0px #00000040;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 4% 7%;
}
.Deletebtn {
  background-color: #cccccc !important;
  margin-top: 10px;
}
.savebtn {
  /* padding: 1% 3%; */

  padding: 10px 15px !important;
}
.datePickerContainer {
  /* width: 100%; */
  height: 48px;
  padding: 0px 10px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  background-color: #fff !important;
  /* transition: border-color 0.3s ease; */
}
.profilePage__imageWrapper {
  position: relative;
  z-index: 1;
  align-items: center;
  display: flex;
  margin-top: 16px;
}
.profileName{
  color: #000000;
  font-weight: 400;
  font-weight: 14px;
}
.profileView{
  margin-top: 32px;
  
}
.profilePage__image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 10px 10px 30px 0px #00000040;
}

.profilePage__smallImageWrapper {
  position: absolute;
  top: 30%;
  left: 85px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  transform: translateY(-50%);

}

.profilePage__editIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10;
}
.changePassword{
  font-family: Poppins;
  font-size: 14px;
  margin-top: 24px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.saveButton{
  width: 140px;
  height: 48px;
  background-color: #0E54A3;
  box-shadow: 4px 4px 12px 0px #00000040;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  display: flex;
  color: #fff;
  margin-top: 32px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .profileconatiner {
    margin-top: 10px;

  }
}
