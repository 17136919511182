.cardImageWrapper {
  height: 208px;
  width: 100%;
}
.cardImage {
  width: 100%;
  border-radius: 16px;
  height: 100%;
  object-fit: cover;
}
.cardName {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: black;
  margin-top: 24px;
}
.hopTitle {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #13a89e;
  margin-top: 8px;
}
.flxB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flx {
  display: flex;
  align-items: center;
}
.w50 {
  width: 50%;
}
.mt16 {
  margin-top: 16px;
}
.w75 {
  width: 75%;
}
.cardtime {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
.texts {
  font-family: " Poppins";
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.Iconns {
  height: 16px;
  width: 16px;
}
.CallBtn {
  width: 160px;
  height: 34px;
  border-radius: 25px;
  color: white;
  background: #0e54a3;
  border: 1px solid #0e54a3;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  cursor: pointer;
}

.mt40 {
  margin-top: 40px;
}
.departmentCardsContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  flex-wrap: wrap;
}
.departmentCards {
  background-color: white;
  border: 1px solid #7d7d7d;
  color: #7d7d7d;
  border-radius: 24px;
  padding: 10px 20px;
  font-family: "Poppins";
  width: 180px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  text-align: center;
}
.DoctorCard {
  width: 100%;
  border-radius: 16px;
  background-color: white;
  box-shadow: 4px 2px 12px 0px #00000040;
  padding: 16px;
  height: 210px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
}
.DoctoriMg {
  width: 100%;
  height: 178px;
  object-fit: cover;
  border-radius: 21px;
}
.DoctoriMgOuter {
  width: 17%;
}
.Carddbody {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 24px;
  width: 83%;
}
.DoctorInfo {
  width: 35%;
  margin-left: 16px;
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.DoctorBottom {
  margin-top: auto; /* Pushes the last div to the bottom */
  display: flex;
  align-items: center; /* Ensures text alignment */
  gap: 16px;
}

.Schedulecard {
}
.DoctorName {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  letter-spacing: -0.25px;
  color: #131313;
}
.Featured {
  font-family: "Poppins";
  background-color: #eaf4ff;
  font-weight: 600;
  font-size: 8px;
  padding: 2px 5px;
  border-radius: 10px;
  line-height: 14px;
  color: #0e54a3;
  letter-spacing: -0.25px;
}
.verified {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  letter-spacing: -0.25px;
  color: #13a89e;
}
.qualification {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.FontA {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.FontB {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #7d7d7d;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.Schedulecard {
  background-color: white;
  border: 1px solid #7d7d7d;
  color: #7d7d7d;
  border-radius: 24px;
  padding: 7px 10px;
  font-family: "Poppins";
  width: 110px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  text-transform: capitalize;
  letter-spacing: -0.22px;
  text-align: center;
}
.Schedulecard:hover {
  color: #0e54a3;
  border: 1px solid #0e54a3;
}
.w80 {
  width: 80%;
}
.BookBtn {
  background-color: #0e54a3;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  border: 2px solid #0e54a3;
  padding: 10px 20px;
  border-radius: 24px;
  line-height: 12.22px;
  cursor: pointer;
  letter-spacing: -0.22px;
}
.locationss {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 90%;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.ttext {
  font-family: "Poppins";
  font-weight: 400;
  margin-top: 16px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.25px;
}

.heading {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  color: #131313;
  line-height: 14px;
  text-transform: capitalize;
  letter-spacing: -0.25px;
}
.TopDoctorList {
  color: #131313;
  padding-left: 20px;
}
.TopDoctorList > li {
  margin-bottom: 8px;
  color: #131313;
}
.w_400 {
  width: 400px;
}
.helpLine {
  background-color: #0e54a3;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  border: 2px solid #0e54a3;
  padding: 10px 20px;
  border-radius: 24px;
  width: 300px;
  line-height: 12.22px;
  cursor: pointer;
  letter-spacing: -0.22px;
}
.w75 {
  width: 75%;
}
.NoAvaible {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.border {
  border-left: 1px solid #7d7d7d;
  height: 100%;
}
.Flexx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .Flexx {
    flex-wrap: wrap;
  }
  .departmentCardsContainer {
    justify-content: center;
  }
  .w_45 {
    width: 100%;
  }
  .w_400 {
    width: 100%;
  }
  .w80 {
    width: 100%;
  }
  .DoctoriMgOuter {
    width: 100%;
  }
  .cardImageWrapper {
    height: 180px;
    width: 100%;
  }
  .justifystart {
    justify-content: start !important;
  }
  .flx {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .border {
    border-bottom: 1px solid #7d7d7d;
    width: 100%;
    height: 0px;
  }
  .locationss {
    width: 100%;
  }
  .BookBtn {
    margin-top: 16px;
  }
  .flxBetween {
    flex-direction: column;
  }
  .w75 {
    width: 100% !important;
  }
  .DoctorCard {
    flex-direction: column;
    height: auto !important;
  }
  .Carddbody {
    width: 100%;
    flex-direction: column;
  }
  .DoctorInfo {
    width: 100% !important;
  }
  .DoctorBottom {
    margin-top: 24px;
    align-items: center;
  }
  .flxB {
    display: flex;
    flex-wrap: wrap !important;
  }

  .w50 {
    width: 100% !important;
  }
  .CallBtn {
    width: 100% !important;
    margin-top: 24px;
  }
  .Schedulecard {
    font-size: 12px;
    width: 90px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .w80 {
    width: 100%;
  }
  .DoctorBottom {
    margin-top: 16px;
  }
  .w_400 {
    width: 100%;
  }
  .DoctoriMgOuter {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .justifystart {
    justify-content: start !important;
  }
  .cardImageWrapper {
    height: 100px;
    width: 100%;
  }
  .DoctorCard {
    flex-direction: column;
    height: auto !important;
  }
  .border {
    border-left: 1px solid #7d7d7d;
    height: 200px;
  }
  .locationss {
  }
  .BookBtn {
    margin-top: 16px;
  }
  .Carddbody {
    width: 100%;
  }
  .flxBetween {
    flex-direction: column;
    align-items: baseline !important;
  }

  .DoctoriMg {
    width: 50%;
    height: 200px !important;
  }
  .DoctorInfo {
    width: 40% !important;
  }
  .Carddbody {
    margin-top: 24px;
  }
}
* {
  box-sizing: border-box;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .cardImageWrapper {
    height: 100%;
    width: 100%;
  }
  .DoctorCard {
    height: "auto";
  }
  .DoctorInfo {
    width: 40% !important;
    height: 100% !important;
  }
  .Schedulecard {
    font-size: 12px;
    width: 90px;
  }
  .w80 {
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .BookBtn {
    margin-top: 16px;
  }
  .Carddbody {
    width: 100%;
  }
  .mt16 {
    margin-top: 16px;
  }
  .flxBetween {
    flex-direction: column;
    align-items: baseline !important;
  }

  .DoctoriMg {
    height: 200px !important;
  }

  .Carddbody {
    margin-top: 24px;
  }
}
* {
  box-sizing: border-box;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .cardImageWrapper {
    height: 100%;
    width: 100%;
  }
  .DoctorCard {
    height: "auto";
  }
  .DoctorInfo {
    width: 40% !important;
    height: 100% !important;
  }
  .Schedulecard {
    font-size: 12px;
    width: 90px;
  }
  .w80 {
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
}
