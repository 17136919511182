.customTooltip {
  border-radius: 8px;
  box-shadow: 2px 2px 4px gray;
  background-color: #00276d;
  color: white;
  padding: 20px !important;
}

.flx {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
}
.value {
  font-size: 14px;
  color: white;
  font-weight: 600;
}
.date {
  border-bottom: 2px solid #e3e3e3;
  text-align: center;
  padding: 5px;
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 600;
}
.iconss2 {
  color: #f0c09d;
  margin: 0 10px 0 30px;
  height: 25px;
  width: 25px;
}
.iconss {
  color: #ff7617;
  margin: 0 10px 0 0px;
  height: 25px;
  width: 25px;
}
