.parentcont {
  width: 70%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 32px;
  margin-bottom: 32px;
}
@media (max-width: 500px) {
  .parentcont {
    width: 100%;
  }
}
.mainconatiner {
  box-shadow: 0px 4px 4px 0px #00000099;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 5px;
}
.pickdrop {
  color: #909198;
  font-size: 14px;
  font-weight: 400;
}

.pickupdetail {
  color: #0e54a3;
  font-size: 14px;
  font-weight: 400;
}

.icon {
  color: #d2092d;
  font-size: 19px;
  cursor: pointer;
}

.bidcontainer {
  display: flex;
  justify-content: end;
  margin: 10px 0;
}

.bidsBtn {
  color: #fff;
  border: 1px solid #0e54a3;
  padding: 10px 50px;
  margin: 0 10px;
  background-color: #0e54a3;
  border-radius: 40px;
  cursor: pointer;
}

.detailconatiner {
  box-shadow: 0px 4px 4px 0px #0000000d;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 5px;
  margin: 15px 0;
}
.imgcontainer {
  width: 100%;
  margin: 0 2px;
}
.ambulanceimg {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: contain;
  box-shadow: 0px 4px 4px 0px #0000000d;
}

.btncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}
.firstbtn {
  color: #d2092d;
  border: 1px solid #d2092d;
  padding: 10px 50px;
  background-color: #fff;
  border-radius: 40px;
  margin: 0 10px;
  cursor: pointer;
}
.secoundbtn {
  color: #fff;
  border: 1px solid #006838;
  padding: 10px 50px;
  margin: 0 10px;
  background-color: #006838;
  border-radius: 40px;
  cursor: pointer;
}
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  display: flex;
  width: 342px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
}
.dltbtn {
  padding: 10px;
  color: #f5f5f5;
  background-color: red;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
}
.cancelbtn {
  padding: 10px;
  color: #f5f5f5;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #808080;
}
