.mainimgconatiner {
  width: 389px;
  height: 268px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
}
.mainimg {
  width: auto;
  height: 100%;
  object-fit: cover;
}
.marginTopDonation {
  margin-top: 70px;
}
