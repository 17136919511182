.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainOuter {
  width: 100%;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;
}
.Navouter {
  position: sticky;
  z-index: 1000;
  top: 0%;
}
.mt32 {
  margin-top: 32px;
}
