.col12 {
  width: 100%;
}
.my50 {
  margin: 30px 0;
}

.outerContainer {
  height: 100vh;
}
.tabless {
  max-height: 450px !important;
  overflow: hidden !important;
  margin-bottom: 50px !important;
}
.TableOuter {
  background-color: white;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;

  padding: 24px;
}
.pendingSelect {
  padding: 10px;
  border-radius: 8px;
  color: #7b7878 !important;
  border: 2px solid #dbdbdb !important;
  background-color: white !important;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.completeSelect {
  padding: 10px;
  border-radius: 8px;
  color: #006833 !important;
  border: 2px solid #006833 !important;
  background-color: white !important;
  color: white;
}

.inProcessSelect {
  padding: 10px;
  border-radius: 8px;
  color: #ffb119 !important;
  border: 2px solid #ffb119 !important;
  background-color: white !important;
}
.sticky {
  position: sticky !important;
  top: 10px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.my32 {
  margin: 32px 0;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.ArrowBack {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-right: 24px;
}
.Abc {
  display: flex;
}
.borderTop {
  border-top: 2px dotted #00276d;
}
.w20 {
  width: 20%;
  text-align: center;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #0e54a3;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-left: 16px;
  background-color: #ffedd6;
  cursor: pointer;
}
.Icon {
  height: 24px;
  width: 24px;
}
.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}
.outerRefresh:hover {
  background-color: #ffedd6;
}
.CustomSelectOrder {
  cursor: pointer;
}
.colorGreen {
  color: #00b69b;
}

.line {
  border: 1px dashed gray;
}

.select {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  width: 100px !important;
}

.selectWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.select {
  padding: 10px 40px 10px 10px;
  font-size: 14px;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  appearance: none;
  width: 150px;
}

.icon {
  position: absolute;
  right: 10px;
  pointer-events: none;
  color: white;
}
.upload {
  color: #ff7631;
  font-size: 16px;
  cursor: pointer;
}
