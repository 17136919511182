.back {
  height: 24px;
  width: 24px;
  margin-right: 16px;
  color: #00276d;
  cursor: pointer;
}
.my40 {
  margin: 40px 0;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt32 {
  margin-top: 32px;
}
.boxm {
  height: 300px;
  width: 390px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mb24 {
  margin-bottom: 24px;
}
.imgs {
  display: flex;
  justify-content: center;
  margin: auto;
}
.textest {
  text-align: center;
  padding: 30px 10px;
}
.btnx {
  background-color: #00276d;
  width: 100%;
  color: white;
  border: 1px solid #00276d;
  border-radius: 8px;
  padding: 15px 25px;
}
.col1 {
  width: 8.3333%;
}
.col2 {
  width: 16.6667%;
}
.col3 {
  width: 25%;
}
.col4 {
  width: 33.3333%;
}
.col5 {
  width: 41.6667%;
}
.col6 {
  width: 50%;
}
.col7 {
  width: 58.3333%;
}
.col8 {
  width: 66.6667%;
}
.col9 {
  width: 75%;
}
.col10 {
  width: 83.3333%;
}
.col11 {
  width: 91.6667%;
}
.col12 {
  width: 100%;
}
.coloorb {
  background-color: #edf4fc;
  border-radius: 10px 10px 0 0;
  padding: 0 !important;
}
.edit {
  height: 40px;
  width: 117px;
  border: 1px solid #f0f2f6;
  border-radius: 5px;
  background-color: #f0f2f6;
}
.flx {
  display: flex;
  flex-direction: row;
}
.end {
  display: flex;
  justify-content: end;
}
.editbtn {
  padding: 2px 10px 0 0;
}
.mt67 {
  margin-top: 67px;
}
tr:hover {
  background-color: #00276d;
  color: white;
}
.start {
  text-align: start;
}

.img8 {
  height: 16px;
  width: 16px;
}
.boxxedit {
  width: 117px;
  background-color: #f0f2f6;
  padding: 10px;
  border-radius: 5px;
  height: 22px;
  margin-top: 60px;
}
.boxxedit:hover {
  background-color: #00276d;
  color: white;
}
.close {
  outline: none;
  font-size: 22px;
  background-color: transparent;
  color: gray;
  border: none;
  cursor: pointer;
}
.end {
  margin-left: auto;
}
.reset {
  margin: 0 12px;
  height: 25px;
  width: 25px;
  color: #00276d;
  padding: 10px;
  cursor: pointer;
}
.reset :hover {
  background-color: #00276d;
}
.delete {
  background: red;
  border: 1px solid red;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  width: 140px;
  padding: 7px 0;
  cursor: pointer;
}
.cancel {
  background: #00276d;
  border: 1px solid#00276d;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  width: 140px;
  padding: 7px 0;
  cursor: pointer;
}
.modelwidth {
  width: 300px;
}
.textjustify {
  text-align: center;
}
.buttoons:hover {
  background-color: #f9a035;
  border-radius: 8px;
}
.closeicon {
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: rgb(163, 160, 160);
}
.outer {
  height: 91vh;
}
.tabless {
  height: 450px;
  overflow: hidden;
  margin-bottom: 40px;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ff4545;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #f1cccc;
}
.fleex {
  display: flex;
  flex-direction: row;
}
.mb24 {
  margin-bottom: 24px;
}
/* ...................... */
.boxm {
  height: 300px;
  width: 390px;
}
.w95 {
  width: 90%;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: fit-content;
}
.reset {
  height: 25px;
  width: 25px;
  padding: 10px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mb24 {
  margin-bottom: 24px;
}
.imgs {
  display: flex;
  justify-content: center;
  margin: auto;
}
.textest {
  text-align: center;
  padding: 30px 10px;
}
.btnx {
  background-color: #00276d;
  color: white;
  border: 1px solid #00276d;
  border-radius: 8px;
  width: 210px;
  height: 44px;
  margin-top: 32px;
  /* margin-left: 95px; */
}
.RefreshBtn {
  height: 24px;
  width: 24px;
  color: #ff7631;
  margin-left: 16px;
}
.col1 {
  width: 8.3333%;
}
.col2 {
  width: 16.6667%;
}
.col3 {
  width: 25%;
}
.col4 {
  width: 33.3333%;
}
.col5 {
  width: 41.6667%;
}
.col6 {
  width: 50%;
}
.col7 {
  width: 58.3333%;
}
.col8 {
  width: 66.6667%;
}
.col9 {
  width: 75%;
}
.col10 {
  width: 83.3333%;
}
.col11 {
  width: 91.6667%;
}
.col12 {
  width: 100%;
}
.coloorb {
  background-color: #edf4fc;
  border-radius: 10px 10px 0 0;
  padding: 0 !important;
}
.edit {
  height: 40px;
  width: 117px;
  border: 1px solid #f0f2f6;
  border-radius: 5px;
  background-color: #f0f2f6;
}
.flx {
  display: flex;
  flex-direction: row;
}
.modelwidth {
  width: 300px;
}

.editbtn {
  padding: 2px 10px 0 0;
}
.mt67 {
  margin-top: 67px;
}
tr:hover {
  background-color: #020e35;
  color: white;
}
.start {
  text-align: start;
}
.my50 {
  margin: 30px 0;
}
.end {
  display: flex;
  justify-content: end;
  cursor: pointer !important;
}
.img8 {
  height: 16px;
  width: 16px;
}
.boxxedit {
  width: 117px;
  background-color: #f0f2f6;
  padding: 10px;
  border-radius: 5px;
  height: 22px;
  margin-top: 60px;
}
.boxxedit:hover {
  background-color: #00276d;
  color: white;
}
.empty3 {
  width: 256px;
  height: 189px;
}
.circle {
  height: 24px;
  width: 24px;
  background-color: #00276d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.borderb {
  border-bottom: 1px solid #00276d;
  width: 183px;
  margin: 10px 2px;
}
.circle2 {
  height: 24px;
  width: 24px;
  background-color: #b2bed3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description > textarea {
  margin-top: 32px;
  width: 490px;
  padding: 10px;
  border: 1px solid #6f6f72;
  outline: none;
  height: 93px;
  width: 100%;
  box-sizing: border-box;
}
.start {
  display: flex;
  justify-content: start;
}

.close {
  outline: none;
  font-size: 22px;
  background-color: transparent;
  color: gray;
  border: none;
  cursor: pointer;
}

.outer {
  height: 90vh;
}
.tabless {
  /* height: 530px; */
  overflow: hidden;
  margin-bottom: 40px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mt32 {
  margin-top: 32px;
}
.my32 {
  margin: 32px 0;
}
.back {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-right: 16px;
}
.mt32 {
  margin-top: 32px;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.btnWidth {
  width: 210px;
  margin-top: 56px;
}
.buttoons {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttoons:hover {
  background-color: #f9a035;
  border-radius: 8px;
  cursor: pointer;
}

.cancel {
  cursor: pointer;
  background-color: #00276d;
  border-radius: 8px;
  width: 110px;
  padding: 5px 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #00276d;
}
.btnDelete {
  cursor: pointer;
  background-color: red;
  border-radius: 8px;
  width: 110px;
  padding: 5px 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid red;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.addIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #ffedd6;
}
/* ............................... */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  display: flex;
  width: 342px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
}
.dltbtn {
  padding: 10px;
  color: #f5f5f5;
  background-color: red;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
}
.cancelbtn {
  padding: 10px;
  color: #f5f5f5;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #808080;
  cursor: pointer;
}
.closeicon {
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: rgb(163, 160, 160);
}
