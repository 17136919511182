.maincontainer {
    margin-top: 24px;
  }
.Heading{
    font-family: "Poppins";
    font-size: 24px !important;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.25px;
    text-align: left;
    text-underline-position: "from-font";
    text-decoration-skip-ink: none;
    color: #131313 !important;
    
}
.Cardcontainer{
    margin-top: 32px;
}


.card{
    background-color: #ffff;
    box-shadow: 4px 2px 12px 0px #00000040;
    border-radius: 16px;
   padding: 24px ;
margin-bottom: 12px;

}
.cardheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cardheading,
.cardPrice,.textdata,.value
{

    font-family: "Poppins";
font-size: 24px;
font-weight: 400;
/* line-height: 14px; */
letter-spacing: -0.25px;
text-align: left;
text-underline-position: "from-font";
text-decoration-skip-ink: none;
color: #131313;

}
.value{
    color: #000000 !important;
    font-size: 14px;
    font-weight: 400;
}
.cardPrice{
    font-weight: 600 !important;
}
.textdata{
    color: #7D7D7D;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.bottomseprator {
    align-self: stretch;
    border: 0.5px solid #7d7d7d !important;
    margin-top: 12px;
    margin-bottom: 16px;

  }
  .columndivider{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
   
  }
  .column1,.column2{
    width: 33.33%;
    flex-grow: 1;
  }
  .column3{
    width: 33.33%;
    display: flex;
    flex-grow: 1;
  }


  .columncontainer {
    display: flex;
    flex-direction: column; 
    gap: 12px;
  }
  .columndata{
    display: flex;
    gap:0 12px;

  }
 .Paybutton{
    background-color:#0E54A3 ;
    box-shadow: 4px 4px 12px 0px #00000040;
    height: 42px;
    font-size: 14px;
    font-weight: 400;
    color: #ffff;
    cursor: pointer;
    border-radius: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
   

 }
 .col3data{
    display: flex;
    flex-direction: column;
   flex-grow: 1;

 }
 /* ............... */
 .incrementcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
  margin-bottom: 8px;
  gap: 0 10px;
  }
  
  .circleButton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #131313;
    font-size: 14px;
    background-color: transparent !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: "background" 0.3s ease;
    border: 1px solid #7D7D7D !important;
  }
  
  /* .circleButton:hover {
    background-color: #0e8b7f;
  }
   */
  
 



  @media (max-width: 769px) {
    .columndivider {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      gap: 16px;
    }
  
    .column1 {
      grid-column: 1;
      grid-row: 1;
      width: 100%;
    }
  
    .column2 {
      grid-column: 1;
      grid-row: 2;
      width: 100%;
    }
  
    .column3 {
      grid-column: 2; 
      grid-row: 1 / span 2;
      width: 100%;
    }
  }
  


  @media (max-width: 426px) {
    .columndivider {
    display: flex;
      gap: 16px; 
    }
  
    .column1,
    .column2 {
      width: 50%; 
    }
  
    .column3 {
      width: 50%; 
      justify-content: center;
    }
  }