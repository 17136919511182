.tabContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-bottom: 20px;
  width: 100%;
  margin-top: 10px;
}

.tab {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
  color: #2b6cb0;
  font-size: 16px;
  font-weight: 500;
  margin: 5px;
  border: none;
  background-color: #f0f0f0;
}

.activeTab {
  background-color: #007bff;
  color: white;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.cardWrapper {
  flex: 1 1 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  box-shadow: 0px 4px 4px 0px #00000014;
  height: 200px;
  padding: 0;
}

.cardBody {
  /* flex-grow: 1; */
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  /* padding: 10px; */
  width: 100%;
}
.imgContainer {
  flex: 0 0 auto;
  width: 256px;
  border-radius: 8px;
  height: 200px;
  overflow: hidden;
}
.cardimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
@media (max-width: 480px) {
  .imgContainer {
    width: 50%;
    height: auto;
  }
  .textContainer {
    width: 50%;
    height: auto;
  }
  .cardImg {
    width: 100%;
    height: auto;
  }
}
.textContainer {
  flex: 1 1;
  padding: 10px;
}
.cardDetails {
  display: flex;
  justify-content: space-between;
  color: #909198;
}
.hearticon {
  color: #fc0019;
}
.footer {
  margin-top: 50px;
}
.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.subtitle {
  font-size: 16px;
  color: #0e54a3;
  font-weight: 400;
}

.details {
  font-size: 16px;
  color: #0e54a3;
  font-weight: 400;
}
.icons {
  font-size: 16px;
  color: #0e54a3;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .cardWrapper {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .cardWrapper {
    flex: 1 100%;
  }
}
.viewCardContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 25%;
}

.viewCardPrice {
  font-size: 24px;
  color: #1a73e8;
  text-align: right;
}

.viewPricePerPack {
  font-size: 14px;
  color: #888;
}

.viewCardQuantity {
  margin: 20px 0;
}

.viewCardQuantityTitle {
  font-size: 18px;
  color: #1a73e8;
  margin: 0;
}
