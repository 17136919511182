.container {
  /* margin: 24px; */
  /* padding: 24px 24px 24px 0px; */
  margin-top: 24px;
}
.heading {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #131313;
}
.dropdownWrapper {
  align-items: center;
  display: flex;
  position: relative;
  /* justify-content: center; */
}
.modalButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
}

.buttonCancel {
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 120px;
  height: 40px;
  cursor: pointer;
  cursor: pointer;
}

.buttonProceed {
  background-color: green;
  color: white;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 120px;
  height: 40px;
  cursor: pointer;
}