.parentcont {
  width: 70%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 32px;
  margin-bottom: 32px;
}
@media (max-width: 500px) {
  .parentcont {
    width: 100%;
  }
}
.mainconatiner {
  box-shadow: 0px 4px 4px 0px #000000f1;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 20px;
}
.pickdrop {
  color: #909198;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
}

.pickupdetail {
  color: #0e54a3;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
}
.pickupdetailPrice {
  color: #0e54a3;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 600;
}
.pickupdetailLogo {
  color: #0e54a3;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 600;
}
.icon {
  color: #d2092d;
  font-size: 19px;
  cursor: pointer;
}

.bidcontainer {
  display: flex;
  justify-content: end;
  margin: 10px 0;
}

.bidsBtn {
  color: #fff;
  border: 1px solid #0e54a3;
  padding: 10px 50px;
  margin: 0 10px;
  background-color: #0e54a3;
  border-radius: 40px;
  cursor: pointer;
}

.detailconatiner {
  box-shadow: 0px 4px 4px 0px #0000000d;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 5px;
  margin: 15px 0;
}
.imgcontainer {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #0000000d;
}
.travelimg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.btncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}
.firstbtn {
  color: #d2092d;
  border: 1px solid #d2092d;
  padding: 10px 50px;
  background-color: #fff;
  border-radius: 40px;
  margin: 0 10px;
  cursor: pointer;
}
.secoundbtn {
  color: #fff;
  border: 1px solid #006838;
  padding: 10px 50px;
  margin: 0 10px;
  background-color: #006838;
  border-radius: 40px;
  cursor: pointer;
}
