.amincontiner {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.conatiner {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}
.Number {
  font-size: 16px;
  font-weight: 600;
  color: #0e54a3;
}
.scheduleWrapper {
  padding: 20px;
  margin-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.title {
  display: flex;
  align-items: center;
  color: #0e54a3; /* Blue color for text and icons */
}

.title svg {
  margin-right: 8px;
  color: #0e54a3; /* Blue color for icon */
}

.price p {
  margin: 0;
  color: #0e54a3; /* Blue color for price text */
}

.scheduleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 1rem 0;
}

.card {
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  padding: 10px;
  flex: 1;
  min-width: 150px;
  max-width: 200px;
}
.reviewcard {
  background-color: #ffffff;
  box-shadow: 0px 5px 8px 0px #0000000a;
  border-radius: 8px;
  padding: 20px;
  flex: 0 1 calc(33.333% - 20px); /* Calculate width for three cards with equal spacing */
  box-sizing: border-box;
}
.reviewlcardHeader,
.reviewcardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.cardHeader svg,
.cardBody svg {
  margin-right: 5px;
  color: #0e54a3; /* Blue color for icons */
}

.cardHeader span,
.cardBody span {
  color: #0e54a3;
}
.ratingBox {
  width: 30px;
  height: 29px;
  text-align: center;
  margin: 0 10px;
  padding: 4px;
  gap: 10px;
  border-radius: 6px;
  background-color: #0e54a3;
  color: #ffffff;
}
.reviews {
  color: #0e54a3;
}

.cardContainer {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 5px 8px 0px #0000000a;
  flex-wrap: wrap; /* Allows cards to wrap to the next row if necessary */
}
.departmentCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 43px; /* Adjust gap between cards as needed */
  /* justify-content: center; */
}
.departmentCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.departmentCards {
  width: 160px;
  height: 80px;
  cursor: pointer;
  position: relative;
  background: #f2f1f1;
  border-radius: 8px;
  border: 0.5px solid transparent;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgcontainer {
  width: 160px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
}
.departmentCardsImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;

  object-fit: cover;
}

.departmentCardsText {
  margin-top: 8px;
  font-size: 14px;
  text-align: center;
}
