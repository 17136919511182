.mainprogressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 500px;
}

.circleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.progressCircle1,
.progressCircle2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  background-color: #0e54a3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  z-index: 0;
}

.circleText {
  margin-top: 10px;
  color: #0e54a3;
}

.line {
  position: absolute;
  top: 30%;
  left: 25px;
  right: 25px;
  height: 3px;
  background-color: #b2bed3;
  z-index: -1;
}
.title {
  font-size: 14px;
  color: #0e54a3 !important;
  font-weight: bold;
}
.subtitle {
  font-size: 14px;
  color: #0e54a3 !important;
}
.educontainer {
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000014;
  background: #fff;
  width: 100%;
  margin-top: 10px;
}
.paymentCard {
  margin-top: 10px;

  gap: 0px;
  border-radius: 8px 0px 0px 0px;

  box-shadow: 0px 4px 4px 0px #00000014;
  background: #fff;
  padding: 16px;
}

.paymentOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.option {
  display: flex;
  flex-direction: column;
}

.paymentDetails {
  display: flex;

  justify-content: space-between;
  margin-bottom: 16px;
}

.detail {
  display: flex;
  flex-direction: column;
}
.mainpayment {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: end;

  /* justify-content: center; */
  margin: 40px 10px 10px 0;
}

.showMoreButton {
  display: flex;

  align-items: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UserLoginmodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 48px 0px #0000000a;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.viewconatiner {
  margin-top: 10px;
  background: #fff;
  padding: 0 25px;
  border-radius: 8px;
  width: 352px;
  height: 262px;
  position: relative; /* Ensure positioning context for the close button */
  box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.1);
  gap: 10px;
  position: relative;
}
.viewdata {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}
.inputmain {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 10px;
}
.inputmain,
input {
  color: #999;
}
.inputaccoumt {
  border: none;
  outline: none;
  width: 100%;
  background-color:#f2f2f2;
  margin: 0 10px;


}
.inputaccoumt::placeholder{
  width: 100%;
  color: #0e54a3;
}
.visa {
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0e54a3;
}
