.label {
  display: block;
  font-size: 14px;
  color: #1e3a8a;
  margin-bottom: 4px;
}

.input {
  border: 1px solid #d1d5db;
  background-color: #d1d5db;
  border-radius: 8px;
  padding: 8px;
  color: #1e3a8a;
  width: 100%;
  box-sizing: border-box;
}

.input:focus {
  border: 1px solid #d1d5db;
  outline: none;
}

.placeholder {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 8px;
  color: #1e3a8a;

  background-color: #f3f4f6;
  width: 100%;
  box-sizing: border-box;
}
.input::placeholder {
  color: #1e3a8a;
  opacity: 1;
}

.card {
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #fff;

  box-shadow: 0px 4px 4px 0px #00000014;
  padding: 16px;
  /* width: 29.5%; */
}
.mainprogressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 500px;
}

.circleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.progressCircle1,
.progressCircle2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  background-color: #0e54a3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  z-index: 0;
}

.circleText {
  margin-top: 10px;
  color: #0e54a3;
}

.line {
  position: absolute;
  top: 30%;
  left: 25px;
  right: 25px;
  height: 3px;
  background-color: #b2bed3;
  z-index: -1;
}
.title {
  font-size: 14px;
  color: #0e54a3 !important;
  font-weight: bold;
}
.subtitle {
  font-size: 14px;
  color: #0e54a3 !important;
}
.showMoreContainer {
  display: flex;
  justify-content: end;
  margin: 10px 0;
}

.showMoreButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: #fff;

  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.decrementButton,
.incrementButton {
  width: 40px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #1a73e8;
  border: none;
  outline: none;

  background-color: transparent;
}
.quantity {
  width: 76px;
  height: 18px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e5da3;
  font-weight: 600;
}
.viewQuantitybox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #1e5da3;
  padding: 4px 2px;
}
.totalAmount {
  color: #1e5da3;
  font-size: 16px;
}
.inputContainer {
  width: 100%; /* Default to full width */
  margin: 5px 0; /* Adjust spacing as needed */
}

@media (min-width: 768px) {
  .inputContainer {
    width: 48%; /* Half-width on larger screens */
  }
}

.flx {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to a new line for smaller screens */
  gap: 10px; /* Space between items */
}

.addMoreContainer {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.addMoreButton {
  padding: 10px 20px;
  background-color: #0e54a3;
  color: #fff;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

@media (max-width: 768px) {
  .addMoreButton {
    font-size: 12px;
    padding: 8px 16px;
  }
}
@media (max-width: 768px) {
  .label {
    font-size: 12px;
  }

  .input {
    font-size: 12px;
    padding: 6px;
  }

  .card {
    padding: 12px;
    /* Reduce padding in cards */
  }

  .title,
  .subtitle {
    font-size: 12px;
    /* Smaller titles for mobile */
  }

  .showMoreButton {
    padding: 8px 16px;
    /* Reduce button size */
    font-size: 14px;
    /* Adjust font size */
  }

  .totalAmount {
    font-size: 14px;
    /* Smaller font size for total amount */
  }

  .quantity {
    font-size: 14px;
  }

  .decrementButton,
  .incrementButton {
    width: 5px;
    font-size: 14px;
  }

  .progressCircle1,
  .progressCircle2 {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .line {
    height: 2px;
  }

  .showMoreContainer {
    margin: 8px 0;
  }

  .viewQuantitybox {
    padding: 2px;
  }
}

@media (max-width: 480px) {
  .inputContainer {
    width: 100%;
  }
}
