.outer {
  width: 477px;
  height: 248px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: none !important;
  outline: none !important;
}
.width {
  width: 30%;
  display: flex;
  justify-content: center;
  justify-self: center;
}
.imgWidth {
  width: 100px;
  display: flex;
  margin: auto;
}
.p {
  margin-top: 30px;
  margin: auto;
  font-size: 20px;
}
