* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container {
  position: sticky !important;
  top: 0% !important;
  left: 0% !important;
  width: 254px;
}
.menuIcons {
  height: 20px;
  width: 20px;
}
.sidebar {
  height: 100vh !important;
  background-color: #f8f8f8 !important;
  color: #949494 !important;
  overflow-y: auto;
}

.link {
  display: flex;
  color: black;
  height: 28px;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
  transition: all 0.5s;
  color: #949494 !important;
}

.center {
  display: flex;
}
.mb {
  margin-bottom: 35px;
}
.user {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 15px;
  height: 49px;
  align-items: center;
  padding: 0 0 0 33px;
  gap: 16.42px;
}

.icon {
  height: 20px;
  width: 20px;
  text-align: center;
  margin-left: 20px;
}
.centerlogo {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #949494;
  color: #0d47a1;
  font-size: 28px;
  font-weight: 700;
}
.logout {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.active {
  color: #0d47a1 !important;
}
.boccc {
  width: 8px;
  height: 48px;
  border-radius: 0 8px 8px 0;
  background-color: #0d47a1;
}
.block {
  width: 8px;
  height: 48px;
  background-color: white;
}
.logo {
  width: 8px;
  height: 48px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .sidebarNames {
    display: none;
  }
  .link {
    display: flex;
    color: #fff;
    height: 49px;
    align-items: center;
    margin-bottom: 16px;
    padding-inline: 16px;
    gap: 16.42px;
    transition: all 0.5s;
  }
  .container {
    position: sticky !important;
    top: 0% !important;
    left: 0% !important;
    width: auto;
    height: 100vh;
  }
  .icon {
    height: 20px;
    width: 20px;
    text-align: center;
    margin-left: 0px;
  }
  .centerlogo {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .icon {
    display: none;
  }
  .container {
    position: sticky !important;
    top: 0% !important;
    left: 0% !important;
    width: auto;
  }
  .centerlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 31px 0;
    border-bottom: 1px solid #949494;
    color: #0d47a1;
    font-size: 18px;
    font-weight: 700;
  }
  .barbtn {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 2800px) {
  .barbtn {
    display: none;
  }
}

.logo {
  width: 60px;
  height: 60px;
  margin-left: 8px;
  margin-right: 16px;
}
