.cursor {
  cursor: pointer;
}
.textCenter {
  text-align: center;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mt24 {
  margin-top: 24px;
}
.mt16 {
  margin-top: 16px;
}
.mt50 {
  margin-top: 50px;
}
.mt40 {
  margin-top: 40px;
}
.mt100 {
  margin-top: 100px;
}
.BackIcon {
  height: 14px;
  width: 14px;
  color: #00276d;
  margin-right: 8px;
}
.LabImg {
  height: 80vh;
  width: 100%;
  margin-top: 50px;
}
.flxCenter {
  display: flex;
  justify-content: center;
}
.container {
  margin-left: 112px;
  padding-top: 64px;
}
.checkBoxx {
  height: 20px;
  width: 20px;
  margin-right: 16px !important;
  color: #a2968e !important;
}
.ml16 {
  margin-left: 16px;
}
.mt8 {
  margin-top: 8px;
}
.flx {
  display: flex;
  align-items: start;
}
