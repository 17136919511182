.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  min-height: 78vh;
  max-height: auto;
  width: 70vw;
}
.Carda {
  width: 328px;
  height: 128px;
  background-color: #e3f5ff;
  border-radius: 16px;
  padding: 0 32px;
  box-shadow: 0 5px 5px 0 gray;
}
.Cardb {
  width: 328px;
  height: 128px;
  background-color: #e5ecf6;
  border-radius: 16px;
  margin: 0 25px;
  padding: 0 32px;
  box-shadow: 0 5px 5px 0 gray;
}
.chart {
  height: 89px;
  width: 89px;
  margin-top: 20px;
}
.selection > select {
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  padding-top: 25px;
}
.medical > li {
  margin: 0 100px;
}
.doctorss > nav {
  height: 72px;
  display: flex;
  justify-content: center;
  background-color: white;
  height: 50px;
  position: sticky;
  z-index: 1;
  top: 15%;
}

.navvvlink {
  margin: 0 15px;
  font-size: 14px;
  font-weight: 600;
  color: #d9d9d9 !important;
  font-size: 16px;
  font-weight: 600;
}
.selected {
  border-bottom: 2px solid #020e35;
  color: #020e35 !important;
}
.empty {
  width: 256px;
  height: 250px;
  display: flex;
  margin: auto;
}

.start {
  display: flex;
  justify-content: start;
}
.end {
  display: flex;
  justify-content: end;
}
.close {
  outline: none;
  font-size: 20px;
  background-color: transparent;
  color: #00276d;
  border: none;
  cursor: pointer;
}
.datebox {
  height: 45px;
  border-radius: 8px;
  width: 532px;
  padding: 2px 40px;
  background-color: #00276d;
  margin-top: 8px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.closee {
  height: 24px;
  width: 24px;
  color: #fb2047;
}
.mrauto {
  margin-left: auto;
}
.dropdown {
  background-color: #f5f5f5 !important;
  border-radius: 8px !important;
  width: 612px !important;
  color: #00276d !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.borderbttom {
  border-bottom: 1px solid #6f6f72;
  width: 190px;
  margin-right: 32px;
}
.editbox {
  height: 35px;
  background-color: white;
  color: #a2a3a4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
}
.deletebtn {
  height: 35px;
  width: 35px;
  background-color: white;
  color: #a2a3a4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin-left: -8px;
}
.editbtn {
  color: #00276d;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
}
.ml20 {
  padding-left: 25px;
}
.pr20 {
  padding-right: 25px;
}
.cancel {
  padding: 5px 15px;
  margin-top: 56px;
  margin-right: 32px;
  background-color: #f0f2f6;
  color: #f93a5d;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #f0f2f6;
}
.save {
  padding: 5px 15px;
  margin-top: 56px;
  margin-right: 32px;
  background-color: #f0f2f6;
  color: #0b7328;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #f0f2f6;
}
.logo {
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 8px;
}
.Faedit {
  margin-right: 8px;
}
.mainSelection {
  display: flex;
  justify-content: center;
}
.Selections {
  width: 150px;
  color: #00276d;
  font-size: 16px;
  font-weight: 600;
  outline: none !important;
  border-bottom: 2px solid #00276d;
}
.Selections ::after {
  outline: none !important;
  border: none !important;
}

.clinic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
  color: #00276d;
}
.Inhouse {
  display: fl ex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
  color: #00276d;
}

.mr16 {
  margin-right: 16px;
}
.ml16 {
  margin-left: 16px;
}
.cursor {
  cursor: pointer;
}
/* Catgeroy */
.cardIconContainer {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.cardIcon {
  width: 24px;
  height: 24px;
  color: #00276d;
}
.cardWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: flex-start;
  padding: 15px 30px;
}
.cardContainer {
  flex: 1 1 calc(33% - 18px);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 8px;
  height: 250px;
  /* margin-top: 24px; */
  background-color: #f5f5f5;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.textContainer {
  margin-top: 24px;
}
.cardHeading {
}
.cardSubHeading {
  color: gray;
}

@media (max-width: 768px) {
  .cardContainer {
    flex: 1 1 100%;
  }
}
.HospitalCard {
  color: white;
  width: 34%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 16px;
  height: 265px;
  margin-top: 24px;
  background-image: url("../../../assets/images/AvailabilityHospital.png");
  background-size: cover;
  margin-right: 24px;
  cursor: pointer;
}
.ConsultancyCard {
  color: white;
  width: 34%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 16px;
  height: 265px;
  margin-top: 24px;
  margin-right: 24px;
  background-image: url("../../../assets/images/VideoConsultancyBack.png");
  background-size: cover;
  cursor: pointer;
}
.mt16 {
  margin-top: 16px;
}
.texts {
  margin-top: 206px;
}

.flx {
  display: flex;
  flex-direction: row;
}
.end {
  display: flex;
  justify-content: end;
}
.start {
  text-align: start;
}

.btnx {
  background-color: #00276d;
  color: white;
  border: 1px solid #00276d;
  border-radius: 8px;
  width: 210px;
  height: 44px;
  margin-top: 32px;
  /* margin-left: 95px; */
}
.flx {
  display: flex;
  flex-direction: row;
}
.actual {
  border-bottom: 1px solid #6f6f72;
  width: 190px;
  margin-left: 64px;
  /* margin-right: 32px; */
}
.back {
  color: #00276d;
  margin-right: 16px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.hospitalAvailabes {
  color: white;
  width: 34%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 16px;
  background-color: #00276d;
  height: 140px;
  margin-top: 24px;
  margin-right: 24px;
}
.hospitalLogo {
  height: 60px;
  width: 60px;
}
.error {
  color: #fb2047;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 100%;
}
.flexxx {
  display: flex;
  flex-direction: column;
}
.Refresh {
  height: 24px;
  width: 24px;
  color: #a2a3a4;
  margin-left: 16px;
  cursor: pointer;
}
.btnwidth {
  width: 120px;
  margin-top: 24px;
}
.emptyOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
.Refresh {
  height: 24px;
  width: 24px;
  color: #a2a3a4;
  margin-left: 16px;
  cursor: pointer;
}
.addbtn {
  height: 24px;
  width: 24px;
  color: #a2a3a4;
  margin-left: 16px;
  cursor: pointer;
}

.heading {
  margin-left: 16px;
  font-weight: bold;
  color: #00276d;
  font-size: 18px;
}
