.Carda {
  height: 97px;
  border-radius: 16px;
  background-color: #fea6b0;
  padding: 20px;
  color: white;
  width: 260px;
  margin-right: 32px;
  box-sizing: border-box;
}
.Cardb {
  height: 97px;
  border-radius: 16px;
  background-color: #3d45a2;
  padding: 20px;
  color: white;
  width: 260px;
  box-sizing: border-box;
  margin-right: 32px;
}

.Cardc {
  height: 97px;
  border-radius: 16px;
  background-color: #feb163;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  width: 260px;
}
.mt32 {
  margin-top: 32px;
}
.mt24 {
  margin-top: 24px;
}
.mt8 {
  margin-top: 8px;
}
.graph {
  width: 100%;
  height: 335px;
  border-radius: 16px;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 32px;
}
.graph2 {
  width: 40%;
  height: 380px;
  border-radius: 16px;
  background-color: white;
  padding: 20px;
  margin-top: 32px;
  margin-right: 32px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.graph3 {
  width: 58%;
  height: 380px;
  border-radius: 16px;
  background-color: white;
  padding: 20px;
  margin-top: 32px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.end {
  margin-left: auto;
}
.Avatar {
  margin-right: 16px;
  height: 32px;
  width: 32px;
}
.activites {
  width: 85%;
  padding: 20px;
  border-radius: 16px;
  background-color: white;
  margin-top: 32px;
}
.flx {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.my16 {
  margin: 16px 0 16px 0;
}
.w70 {
  width: 70%;
  margin-right: 32px;
}
.w30 {
  width: 30%;
}
.mr32 {
  margin-right: 32px;
}

.flagyesterday {
  overflow-y: auto;
  height: 466px;
}
.flagyesterday::-webkit-scrollbar {
  display: none;
}

/* Track */
.flagyesterday::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.flagyesterday::-webkit-scrollbar-thumb {
  display: none;
}

/* Handle on hover */
.flagyesterday::-webkit-scrollbar-thumb:hover {
  display: none;
}
/* today */

.flagToday {
  overflow-y: auto;
  height: 266px;
}
.flagToday::-webkit-scrollbar {
  display: none;
}

/* Track */
.flagToday::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.flagToday::-webkit-scrollbar-thumb {
  display: none;
}

/* Handle on hover */
.flagToday::-webkit-scrollbar-thumb:hover {
  display: none;
}
/* Tooop Dooner */

.flagTop {
  overflow-y: auto;
  height: 286px;
}
.flagTop::-webkit-scrollbar {
  display: none;
}

/* Track */
.flagTop::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.flagTop::-webkit-scrollbar-thumb {
  display: none;
}

/* Handle on hover */
.flagTop::-webkit-scrollbar-thumb:hover {
  display: none;
}
