.box {
  width: 700px;
  border-radius: 16px;
  background-color: #f6f7fa;
  padding: 30px;
  margin-right: 16px;
}
.mt40 {
  margin-top: 40px;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #ffedd6;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #13a89e;
}
.flx {
  display: flex;
  flex-direction: row;
}
.alignItemsCenter {
  align-items: center;
}

.flxp {
  display: flex;
  flex-direction: row;
}

.card {
  width: 400px;
  border-radius: 16px;
  background-color: #f5fcff;
  padding: 20px;
  margin-left: 16px;
}
.alignItemsCenter {
  align-items: center;
}
.card2 {
  margin-top: 24px;
  width: 400px;
  border-radius: 16px;
  background-color: #f5fcff;
  padding: 20px;
  margin-left: 16px;
}
.check {
  background-color: #0b7328;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit {
  background-color: #ff7631;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.file {
  height: 24px;
  width: 24px;
  margin-right: 24px;
}
.download {
  height: 24px;
  width: 24px;
  margin-left: auto;
}
.user {
  width: 300px;
  border-right: 2px dashed #00276d;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.textcenter {
  text-align: center;
}
.middel {
  text-align: center;
  border-right: 2px dashed #00276d;
  width: 50%;
}
.sendbtn {
  background-color: transparent;
  border-radius: 8px;
  border: 2px solid #00276d;
  color: #00276d;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  margin: 24px auto 0 auto;
}
.center {
  display: flex;
  justify-content: center;
}
.user2 {
  padding: 20px;
  width: 320px;
}
.table {
  width: 750px;
  margin-top: 40px;
}
.my32 {
  margin: 32px 0;
}
.outer {
  height: 91vh;
}
.tabless {
  height: 350px;
  overflow: hidden;
  margin-bottom: 40px;
}
.flxbtween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.my32 {
  margin: 32px 0;
}

.btnWidth {
  width: 210px;
  margin-top: 56px;
}

.DeleIons {
  margin: 0 12px;
  height: 25px;
  width: 25px;
  color: #00276d;
  padding: 10px;
}
.DeleIons:hover {
  background-color: #f9a035;
  border-radius: 8px;
}

.end {
  margin-left: auto;
}
.profile {
  height: 100px;
  width: 100px;
  color: #00276d;
}
.avatar {
  height: 120px;
  width: 120px;
  margin-top: 32px;
}
.mt32 {
  margin-top: 32px;
}
.mt8 {
  margin-top: 8px;
}
.center {
  display: flex;
  height: 100px;
  width: 100px;
  justify-content: center;
}
.back {
  height: 24px;
  width: 24px;
  margin-right: 24px;
  color: #00276d;
}
