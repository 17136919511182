.cardcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  /* margin: 40px 0; */
  margin-bottom: 56px;
}

.card {
  flex: 1 1 calc(25% - 1rem);
  max-width: 360px;
  max-height: 240px;
  position: relative;
  transition: 0.3s ease-in-out;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
}
.card:hover {
  box-shadow: 8px 8px 24px 0px #0000005c, -8px -8px 24px 0px #0000005c; /* Combined box-shadow */
  transform: scale(1.05);
  width: 380px;
  height: 253.3px;
  border-radius: 12px;
}
.cardImageContainer {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.cardimg {
  width: 360px;
  height: 240px;
}
.iconcontainer {
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 16px; */
  /* margin-right: 12px; */
}
.iconimg {
  width: 100%;
  height: 100%;

  object-fit: contain;
}
.iconimgcontainer {
  width: 20px;
  height: 20px;
}

.carddata {
  position: absolute;
  bottom: 13px;
  left: 24px;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  gap: 16px;
  align-items: center;
}

.description {
  color: white;
  font-size: 12px;
}
.name {
  color: white;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .card {
    /* flex: 1 1 calc(33.33% - 1rem); */
    flex: 1 1 calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .card {
    /* flex: 1 1 calc(50% - 1rem); */
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 1 100%;
  }
}

@media (max-width: 375px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .cardImageContainer {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .cardimg {
    width: 100%; /* Make the image scale dynamically */
    height: 100%; /* Maintain aspect ratio */
  }
  .carddata {
    position: absolute;
    left: 0px;
    gap: 3px;
  }
}
.CardOuter {
  height: 260px;
  width: 360px;
  cursor: pointer;
  border-radius: 24px;
  box-shadow: 0px 2px 12px 0px #00000029;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}
.CardOuter:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
}
.ImgeContainer {
  height: 153px;
  border-radius: 24px 24px 0 0;
  display: flex;
  justify-content: center;
  align-items: end;
}
.ContentContainer {
  height: 107px;
  border-radius: 0 0 24px 24px;
  padding: 24px;
  display: flex;
  gap: 16px;
  align-items: start;
}
* {
  font-family: "poppins", sans-serif;
}
.numbers {
  color: white;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  text-transform: capitalize;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #7d7d7d;
}
.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  text-transform: capitalize;
  color: #131313;
  margin-bottom: 8px;
}
.content {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
  vertical-align: middle;
  text-transform: capitalize;
  color: #7d7d7d;
}
.cardimage {
  height: 140px;
  width: 280px;
  object-fit: contain;
}
