.boxm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer {
  height: 90vh;
}
.tabless {
  height: 450px;
  overflow: hidden;
  margin-bottom: 40px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 50px;
}
.mt32 {
  margin-top: 32px;
}
.my32 {
  margin: 32px 0;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  /* height: 78vh; */
  height: fit-content;
}
.ArrowBack {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-right: 16px;
}
.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}
.w20 {
  width: 20%;
  text-align: center;
}
.borderTop {
  border-top: 2px dotted #00276d;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #099bed;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #c9d8e2;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #393a44;
}

.loader {
  margin-left: 24px;
}
