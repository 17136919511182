.title {
  font-size: 32px;
  font-weight: bold;
  color: #1e5da3 !important;
}
.subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #1e5da3 !important;
}
.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.image {
  width: 367px;
  height: 192px;
  border-radius: 16px;
  object-fit: cover;
}
.textbox {
  display: flex;
  width: 100%;
  margin-top: 8px;
}
.roundimg {
  width: 56px;
  height: 56px;
  border-radius: 50px;
  box-shadow: 0px 4px 8px 0px #00000029;
}
.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.showMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  background-color: #0e54a3;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 12px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* DonationPackeg.module.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 48px 0px #0000000a;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.modalVisible {
  opacity: 1; /* Make visible */
}

.modalContent {
  width: 300px;
  height: 217px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 16px;
  /* padding: 5px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.drmodalContent {
  width: 342px;
  height: 400px;
  display: flex;
  flex-direction: column;
  background: #f1f1f1; /* Gray background */
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.drmodalContent ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-x: hidden;

  overflow-y: auto; /* Allow the list to scroll */
  flex-grow: 1; /* Make the list take available space */
}

.drmodalContent li {
  padding: 10px 0;
  font-size: 16px;
  color: #0e54a3;
  border-bottom: 1px solid #ccc;
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.drmodalContent li:active {
  background-color: #1e5da3;
  color: #f1f1f1;
}

.showMoreContainer {
  margin-top: 10px;
  align-self: flex-end;
}

.amount {
  border: none;
  outline: none;
}
.inputarea {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #1e5da3;
}
.donateicon {
  color: #0e54a3;
  margin-bottom: 10px;
  font-size: 20px;
}
