.mainOuter {
  width: 100%;
  padding: 32px 40px 32px 0;
  box-sizing: border-box;
}
.Navouter {
  position: sticky;
  z-index: 1000;
  top: 0%;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #393a44;
}
.primarycolor {
  color: #393a44;
}
.mt16 {
  margin-top: 16px;
}

.searchcontainer > input {
  border-radius: 40px;
  height: 38px;
  width: 255px;
  padding: 10px 35px 10px 35px;
  border: none;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #909198;
  margin-right: 32px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px; */
}
.searchcontainer > input:focus {
  border: none;
  outline: none;
}
.searchcontainer > input::placeholder {
  color: #909198;
}
.searchcontainer {
  position: relative;
  width: 300px;
}

.searchicon {
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: #909198; /* Adjust color as needed */
  z-index: 1;
}
.Filter {
  font-size: 20px;
  position: absolute;
  top: 50%;
  color: #777; /* Adjust color as needed */
  z-index: 1;
  transform: translateY(-50%);
  right: 60px;
}
.SearchInput {
  width: calc(100% - 30px); /* Adjust to make space for the icon */
  padding-left: 30px; /* Adjust to make space for the icon */
  box-sizing: border-box;
  z-index: 0;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.colorGray {
  color: #909198;
}
