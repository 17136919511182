.TopView {
  margin: 16px 24px;
  padding-bottom: 40px;
}

.card {
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.travelerInfo {
  margin: 16px 0;
}
.rowVertical {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.touchable {
  border: 1px dashed #0e54a3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
}

.DownloadImage {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.justifyStyle {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.paginationStyles {
  background-color: transparent;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 8px;
}

.dotStyles {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(226, 93, 93, 1);
  margin: 0 -8px;
}

.bgStyles {
  background-color: rgba(0, 39, 109, 1);
}

.ImageContainer {
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.ImageStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.header {
  font-size: 16px;
  font-weight: bold;
  color: #0e54a3;
}

.subHeader {
  font-size: 12px;
  color: #7d7d7d;
}

.eTicketContainer {
  gap: 2px;
}

.buttonContainer {
  margin-top: 8px;
}
