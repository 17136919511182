.outer {
  padding: 32px 160px 0px 160px;
}
.pending {
  width: 100%;
  height: 85vh;
}
.navIMG {
  height: 350px;
  width: 100%;
  background-position: center;
  background-size: cover;
}
.mianheading22 {
  text-align: center;
  margin-top: 40px;
  color: #ff7631;
}

.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}
.container {
  padding: 70px 160px 70px 160px;
}
.colorBlue {
  color: #0e54a3;
}
.mt8 {
  margin-top: 8px;
}
.mt24 {
  margin-top: 24px;
}
.list {
  color: #ff7631;
  margin-top: 16px;
  margin-left: 24px;
}
.list > li {
  cursor: pointer;
  text-transform: capitalize;
}
.list li:hover {
  background-color: #f0f0f0;
}

.two_line {
  display: -webkit-box; /* Use a flexible box layout */
  -webkit-box-orient: vertical; /* Align items vertically */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis (...) at the end */
}

.ConsultancyBox {
  width: 100%;
  border-radius: 32px;
  background-color: #edf3f6;
  padding: 32px 24px;
  text-align: center;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.inputs {
  width: 100%;
  border-radius: 30px;
  padding: 10px 25px;
  box-sizing: border-box;
  background-color: white;
  color: black;
  border: none;
  outline: none;
  color: #0e54a3;
}

.select {
  width: 100%;
  border-radius: 30px;
  padding: 10px 25px;
  box-sizing: border-box;
  background-color: white;
  border: none;
  outline: none;
}

.iconnns {
  color: #ff7631;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.textcenter {
  text-align: center;
}
.labels {
  text-align: start;
  color: #2d3663bf;
  font-size: 16px;
  font-weight: 400;
}
.colorGray {
  color: #2d3663bf;
}
.textaera {
  width: 100%;
  border-radius: 30px;
  padding: 20px 25px;
  box-sizing: border-box;
  background-color: white;
  border: none;
  outline: none;
  height: 230px;
  resize: none;
  color: #0e54a3;
}
.btnSubmit {
  background-color: #ff7631;
  color: #0e54a3;
  width: 190px;
  height: 60px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  margin-top: 32px;
  cursor: pointer;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.col7 {
  width: 58.3333%;
}
.col4 {
  width: 33.3333%;
}
.fs48 {
  font-size: 48px;
}
.fs36 {
  font-size: 36px;
}
.flxcenter {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.headingpath {
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.headingpathOrange {
  color: #ff7631;
  font-size: 16px;
  font-weight: 600;
}
@media (min-width: 320px) and (max-width: 480px) {
  .container {
    padding: 20px !important;
  }
  .navIMG {
    height: 230px;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
  .flxBetween {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
  }
  .col7 {
    width: 100%;
  }
  .col4 {
    width: 100%;
  }
  .mtsm32 {
    margin-top: 32px;
  }
  .fs48 {
    font-size: 20px;
  }
  .fs36 {
    font-size: 20px;
  }
  .headingpath {
    color: white;
    font-size: 8px;
    font-weight: 600;
  }
  .headingpathOrange {
    color: #ff7631;
    font-size: 8px;
    font-weight: 600;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .headingpath {
    color: white;
    font-size: 12px;
    font-weight: 600;
  }
  .navIMG {
    height: 280px;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
  .headingpathOrange {
    color: #ff7631;
    font-size: 12p x;
    font-weight: 600;
  }

  .container {
    padding: 30px 60px 30px 60px !important;
  }
  .flxBetween {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
  }
  .col7 {
    width: 100%;
  }
  .col4 {
    width: 100%;
  }
  .mtsm32 {
    margin-top: 32px;
  }
  .fs48 {
    font-size: 34px;
  }
  .fs36 {
    font-size: 32px;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .container {
    padding: 50px 80px 50px 80px !important;
  }
  .flxBetween {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
  }
  .col7 {
    width: 100%;
  }
  .col4 {
    width: 100%;
  }
  .mtsm32 {
    margin-top: 32px;
  }
  .fs48 {
    font-size: 48px;
  }
  .fs36 {
    font-size: 36px;
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
  .container {
    padding: 50px 100px 50px 100px !important;
  }
}
@media (min-width: 769px) and (max-width: 2800px) {
}
