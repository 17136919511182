.navbar {
  background-color: #fff;
  color: #fff;
  background: transparent;
  height: 71.76px;
}
.flx {
  display: flex;
  flex-direction: row;
}
.nav-links {
  list-style: none;
  display: flex;
  padding-top: 25px;
}
.medical > li {
  margin: 0 10px;
  color: #020e35;
}
.medical > li:hover {
  border-bottom: 2px solid #020e35;
  color: #020e35;
}
.container {
  margin-top: 88px;
  margin-left: 112px;
}

.mr203 {
  margin-right: 203px;
}
.container2 {
  margin-left: 112px;
  padding-top: 64px;
}

.ml112 {
  margin-left: 112px;
}
.LabImg {
  height: 553px;
  width: 452px;
  margin-top: 50px;
  margin-left: 100px;
}

.buttonWidth {
  width: 210px;
  margin-top: 56px;
}
.flx {
  display: flex;
  flex-direction: row;
}
.border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.flxStart {
  display: flex;
  align-items: start;
}
.BackIcon {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-top: 3px;
  cursor: pointer;
}
.flxSign {
  display: flex;
  justify-content: space-between;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 320px) and (max-width: 480px) {
  .flxBetween {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .flxSign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .ml112 {
    margin-left: 19px;
  }
  .container {
    margin-top: 40px;
    margin-left: 19px;
  }
  .container2 {
    margin-left: 19px;
  }
  .flx {
    display: flex;
    flex-wrap: wrap;
  }
  .buttonWidth {
    width: 100%;
  }
  .mr203 {
    margin-right: 100px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .flxBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flxSign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .ml112 {
    margin-left: 100px;
  }
  .container {
    margin-top: 40px;
    margin-left: 100px;
  }
  .container2 {
    margin-left: 100px;
  }

  .buttonWidth {
    width: 300px;
  }
  .flx {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 2800px) {
}
