.card {
  box-shadow: 0px 4px 4px 0px #00000014;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  gap: 10px;
  width: calc(50% - 10px);
  margin-bottom: 20px;
  box-sizing: border-box;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 181px;
  gap: 20px;
}

.textContainer {
  margin: 10px;
}
.cardViewMore {
  font-size: 14px;
  color: #0e54a3;
  text-decoration: none;
  margin-right: 8px;
}
.cardArrow {
  width: 16px;
  height: 16px;
  background-color: #0e54a3;
  color: white;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgcontainer {
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 10px;
}
.treeimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
