.tableData {
  overflow-y: auto;
  height: 62vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.1) 0px 1px 3px;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease-in-out;
}
.wapper > tr {
  color: #000000 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
}
.w20 {
  width: 20%; /* Ensure consistency with header widths */
  text-align: center;
  padding: 15px 10px;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0.5px 1px 6px,
    rgba(0, 0, 0, 0.23) 0.5px 1px 6px;
  color: #000000 !important;
  background-color: white !important;
}
.w20 {
  width: 20%;
  text-align: center;
  padding: 10px 5px;
}
.headerclass {
  width: 20%; /* Adjust based on your layout needs */
  text-align: center;
  padding: 15px 10px;
}
.headerOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #746ca2;
  color: white;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 600;
  position: sticky;
  top: 0;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
