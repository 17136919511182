.card {
  /* width: "100%"; */
  border-radius: 8px;
  /* background-color: #13a89e; */
  background-color: #f5f5f5;
  margin-bottom: 8px;
  padding: 10px;
  color: #00276d;
  box-sizing: border-box;
  cursor: pointer;
}
.check {
  width: 16px;
  height: 16px;
  margin-right: 35px;
  color: #0b7328;
  cursor: pointer;
}
.reject {
  color: #fb2047;
  text-transform: capitalize;
  margin-right: 32px;
}
.back {
  color: #00276d;
  margin-right: 16px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.booked {
  color: green;
  text-transform: capitalize;
  margin-right: 32px;
}
.textAlignCenter {
  text-align: center;
}
.btnwidth {
  width: 120px;
  margin-top: 24px;
}
.done {
  color: green;
  height: 90px;
  width: 90px;
  display: flex;
  margin: 0 auto 24px auto;
}
.textcenter {
  text-align: center;
}
.cross {
  width: 16px;
  height: 16px;
  margin-right: 35px;
  margin-left: 70px;
  color: #fb2047;
  cursor: pointer;
}

.seletion {
  color: #00276d;
  font-weight: 600;
  font-size: 16px;
  font-weight: 800;
  border: none;
  outline: none;
  margin-bottom: 32px;
}
.flxend {
  display: flex;
  justify-content: end;
  margin-left: auto;
  align-items: center;
}
.end {
  margin-left: auto;
}
.empty3 {
  width: 256px;
  height: 250px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.emptyOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: fit-content;
}
.icon {
  height: 16px;
  width: 16px;
  color: #ff4545;
  margin-right: 10px;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ff4545;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #f1cccc;
}
.detailCard {
  width: 100%;
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 16px;
  color: #7d7d7d;
  box-shadow: 0px 4px 4px 0px #0000000d;
}
.detailCard2 {
  display: flex;
  width: 100%;
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 16px;
  color: #7d7d7d;
  box-shadow: 0px 4px 4px 0px #0000000d;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.mt32 {
  margin-top: 32px;
}
.mt24 {
  margin-top: 24px;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ff4545;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.checkbox {
  margin-right: 16px;
}
.mr33 {
  margin-right: 32px;
}
.contact {
  margin-left: 7%;
}
