.mainconatiner {
  box-shadow: 0px 4px 4px 0px #00000014;
  background-color: #fefefe;
  height: fit-content;
  overflow: hidden;
  border-radius: 16px;
  padding: 10px;
}
.imgcontainer {
  width: 30%;
  height: 120px;
  overflow: hidden;
  margin: 10px;
}

.headerconatiner {
  width: 70%;
}
.treeimg {
  width: 80%;
  border-radius: 80%;
  height: 100%;
}
@media (max-width: 768px) {

  .imgcontainer {
    width: 100%;
    height: auto;
  }

  .treeimg {
    height: auto;
    max-height: 120px;
  }
}
.payDetailList {
  padding: 0 10px;
}

.payDetailItem {
  color: #909198;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .payDetailItem {
    font-size: 9px;
  }
}
.label {
  display: block;
  font-size: 14px;
  color: #1e3a8a;
  margin-bottom: 4px;
}

.input {
  border: 1px solid #d1d5db;
  background-color: #d1d5db;
  border-radius: 8px;
  color: #1e3a8a;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.input:focus {
  border: 1px solid #d1d5db;
  outline: none;
}
.inputCNIC {
  border: 1px solid #d1d5db;
    background-color: #ffff;
    border-radius: 8px;
    color: #1e3a8a;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
}
.input:focus {
  border: 1px solid #d1d5db;
  outline: none;
}

.placeholder {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 8px;
  color: #1e3a8a;

  background-color: #f3f4f6;
  width: 100%;
  box-sizing: border-box;
}
.input::placeholder {
  color: #0D47A1;
  opacity: 1;
}
.inputCNIC::placeholder {
  color: #0D47A1;
  opacity: 1;
}
.marginBottom {
  margin-bottom: 100px;
}
.containerUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.uploadBox {
  width: 60%;
  border: 2px dotted #0D47A1;
  padding: 10px;
  border-radius: 8px;
  justify-content: center;
    align-items: center;
  box-sizing: border-box;
}

.uploadText {
  font-size: 14px;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

@media (max-width: 768px) {
  .uploadBox {
    width: 80%;
    padding: 6px;
  }

  .uploadText {
    font-size: 10px;
  }

  .errorMessage {
    font-size: 8px;
  }
}