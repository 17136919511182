.tableData {
  overflow: auto;
  height: 62vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}
.empty3 {
  width: 256px;
  height: 189px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}
.tableRow:last-child {
  border-bottom: none;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
  font-family: "Poppins", sans-serif;
}

.wapper > tr {
  color: #000000 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0.5px 1px 6px,
    rgba(0, 0, 0, 0.23) 0.5px 1px 6px;
  color: #000000 !important;
  background-color: white !important;
}
.w20 {
  width: 25% !important;
  text-align: center;
  height: 8vh;
}
.headerclass {
  width: 25% !important;
  text-align: center;
  padding: 15px;
}
.headerOuter {
  display: flex;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  background-color: #e25d5d;
  color: white;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 600;
  position: sticky !important;
  top: 0px;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
