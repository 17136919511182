.navContainer {
  border: none;
  display: flex;
  align-items: center;
  color: #0e54a3;
  max-width: 1200px;
  margin: 0px auto;
  width: 100%;
  height: 64px;
  margin: 0 auto;
}
.navouter {
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 4px 0px #00000040;
}
.login_button {
  height: 40px;
  width: fit-content;
  border-radius: 47px;
  padding: 10px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  cursor: pointer;
  color: white;
  background-color: #0e54a3;
}
/* .login_button:hover {
  border: 1px solid #ff7631;

  background-color: #ff7631;
  color: white;
  box-sizing: border-box;
} */
.cart {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: #ff7631;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.profile {
  height: 45px;
  width: 170px;
  border-radius: 32px;
  /* border: 1px solid white; */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 150px;
  cursor: pointer;
  color: white;
  border: 1px solid#ff7631 !important;
  margin-right: 24px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .login_button {
    height: 35px;
    width: 100px;
    margin-right: 10px;
    font-size: 14px;
    padding: 8px;
  }
}

.user_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;

  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.user_name:hover {
  color: #ff7631 !important;
}

.logoContainer {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.dropdownlogoContainer {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 24px; */
  margin-top: 10px;
}
.NavBar > li {
  list-style: none;
  font-family: "Poppins";
  cursor: pointer;
  margin-right: 24px;
  color: #131313;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;

  position: relative;
}

.NavBar {
  display: flex;
  margin: auto;
}
.NavBar > li:hover {
  color: #ff7631 !important;
  /* border-bottom: 1px solid #ff7631; */
}
.activeLink {
  color: #ff7631 !important;

  border-bottom: 1px solid #ff7631 !important;
}
.colorGray {
  color: #6f6c90;
}
.mobileMenu {
  position: absolute;
  top: -7px;
  right: -10px;
  background-color: #0e54a3;
  margin-right: 10px;
  margin-top: 8px;
  z-index: 100000000;
  font-size: 16px;
  font-weight: 700;
  color: white;
  border-radius: 24px;
  text-align: start;
  width: 500px;
  height: auto;
  transition: right 0.5s ease;
}
.mobileMenu.open {
  right: 0; /* Slide the menu in from the right */
}
.menulist {
  padding: 15px 20px;
  text-align: start;
  color: white;
}
.menulist:hover {
  background-color: white;
  color: #0e54a3;
  border-radius: 32px;
}
.join {
  /* border-left: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important; */
  border: 1px solid #0e54a3;
  border-radius: 20px;
  /* border-color: white; */
  padding: 0 16px;
  font-weight: 500;
  color: #0e54a3 !important;
  cursor: pointer;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.joinactive {
  padding: 0 16px;
  height: 40px;
  display: flex;
  border: 1px solid;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  color: #ff7631 !important;
  border-color: #ff7631 !important;
}
.join:hover {
  color: #ff7631 !important;
  border: 2px solid #ff7631 !important;
}
.barbtn {
  /* color: white !important; */
  color: #0e54a3 !important;
}
.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 20px 30px;
  border-radius: 24px 24px 0 0;
}
/* .Headerlogo {
  height: 80px;
  width: 80px;
} */

.Headerclose {
  font-size: 40px;
  font-weight: 900;
  color: #0e54a3;
  cursor: pointer;
}
.hoverCard {
  position: absolute;
  margin-top: 16px; /* Adjust as needed */
  left: 50%; /* Start at the center of the li */
  transform: translateX(-50%) translateY(-20px); /* Start slightly above */
  background-color: white;
  border-radius: 16px;
  padding: 16px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100000000;
  width: 250px;
  opacity: 0; /* Start hidden */
  transition: transform 0.6s ease, opacity 0.6s ease; /* Slow transition */
}

.NavBar > li:hover .hoverCard,
.hoverCard:hover {
  transform: translateX(-50%) translateY(0); /* Move to final position */
  opacity: 1; /* Show */
  transition-delay: 0.1s; /* Delay for smoother open */
}
.listIetm {
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 500;
  color: #0e54a3;
}
.listIetm:hover {
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 500;
  background-color: #ff7631;
}

@media (min-width: 320px) and (max-width: 480px) {
  .logo {
    height: 40px;
    width: 40px;
    margin-left: 16px;
    margin-top: 5px;
  }
  .navset {
    width: 50%;
    justify-content: end;
  }
  .join {
    display: none;
  }
  .NavBar > li {
    display: none;
  }
  .profile {
    display: none;
  }
  .navContainer {
    background-color: transparent;
    border: none;
    align-items: center;
    color: #0e54a3;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }

  .barbtn {
    height: 24px;
    width: 24px;
    margin-left: auto;
    color: #0e54a3 !important;
  }
  .mobileMenu {
    position: absolute;
    top: -7px;
    right: -10px;
    background-color: #0e54a3;
    margin-top: 8px;
    z-index: 100000000;
    font-size: 16px;
    font-weight: 700;
    color: white;
    text-align: start;
    width: 100%;
    height: auto;
    transition: right 0.5s ease;
  }
  .login_button {
    font-size: 12px;
    width: auto;
    padding: 0px 10px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .login_button {
    font-size: 13px;
    width: auto;
    padding: 0px 10px;
  }
  .navset {
    width: 65%;
    justify-content: end;
  }
  .mobileMenu {
    position: absolute;
    top: -3px;
    right: -5px;
    height: auto;
    border-radius: 24px;
  }
  .join {
    display: none;
  }
  .NavBar > li {
    display: none;
  }
  .profile {
    display: none;
  }
  .navContainer {
    padding: 10px 40px;
    display: flex;
    align-items: center;
    color: #0e54a3;
    /* margin: 0px 60px; */
    display: flex;
    justify-content: space-between;
  }
  .barbtn {
    height: 24px;
    width: 24px;
    margin-left: auto;
    margin-right: 16px;
    color: #0e54a3 !important;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .navset {
    width: 80%;
    justify-content: end;
  }
  .login_button {
    font-size: 14px;
    width: auto;
    padding: 0px 15px;
  }
  .mobileMenu {
    position: absolute;
    top: -7px;
    right: -10px;
    height: auto;
  }
  .barbtn {
    height: 24px;
    width: 24px;
    margin-left: auto;
    margin-right: 16px;
    color: #0e54a3 !important;
  }
  .join {
    display: none;
  }
  .NavBar > li {
    display: none;
  }
  .profile {
    display: none;
  }
  .navContainer {
    padding: 10px 80px;
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
  .profile {
    /* height: 48px; */
    width: 120px;
    border-radius: 32px;
    border: 1px solid white;
    /* padding:  10px; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-left: 50px; */
    cursor: pointer;
    color: white;
  }
  .barbtn {
    display: none;
  }
  /* .NavBar > li {
    list-style: none;
    cursor: pointer;
    margin-right: 24px;
    color: white;
    font-size: 12px;
  } */
  /* .join {
    border-left: 2px solid;
    border-right: 2px solid;
    border-color: white;
    padding: 0 10px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-size: 12px;
  } */

  /* .joinactive {
    border: 2px solid;
    border-radius: 8px;
    padding: 10px 10px;
    font-weight: 600;
    cursor: pointer;
    color: #ff7631;
    border-color: #ff7631;
  } */
  /* .logo {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
  } */
  .navContainer {
  }
}
@media (min-width: 1441px) and (max-width: 2800px) {
  .barbtn {
    display: none;
  }
}

/* Dropdown Menu */
.dropdownMenu {
  position: absolute;
  top: 70px;
  padding-left: 10px;
  right: 32px;
  background-color: white;
  list-style: none;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  color: #0e54a3 !important;
}

.dropdownMenu li {
  padding: 12px;
  cursor: pointer;
  color: #0e54a3 !important;

  /* transition: background-color 0.3s ease; */
}

/* .dropdownMenu li:hover {
  background-color: #f1f1f1;
} */

.dropdownMenu li a {
  position: relative; /* Ensure the pseudo-element is positioned relative to this */
  padding-left: 10px; /* Adjust as needed to create space for the border */
  display: inline-block; /* To ensure padding and pseudo-element work as expected */
  text-decoration: none; /* Optional: Remove underline from links */
}

.dropdownMenu li a::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 4px;
  color: #0e54a3 !important;

  transition: background-color 0.3s ease;
}

.dropdownMenu li a:hover::before {
  background-color: #ff7631; /* Border color on hover */
}

.dropdownMenu li a:hover {
  color: #ff7631 !important;
}
.menuIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuItem:hover {
  color: #ff7631 !important;
  background-color: aliceblue;
}
/* navBarr.module.css */
.userIcon {
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  transform: translateY(-4px);
  cursor: pointer;
}
/* Hide user icon on screens wider than 1440px */
@media (min-width: 1119px) {
  .userIcon {
    display: none;
  }
  .dropdownMenuMobile {
    display: none;
  }
}
.dropdownMenuMobile {
  position: absolute;
  top: 35px;
  padding-left: 10px;
  right: 1px;
  background-color: white;
  list-style: none;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  color: #0e54a3 !important;
  border: 2px solid red;
}
.dropdownMenuMobile li {
  padding: 12px;
  cursor: pointer;
  color: #0e54a3 !important;

  /* transition: background-color 0.3s ease; */
}

/* .dropdownMenu li:hover {
  background-color: #f1f1f1;
} */

.dropdownMenuMobile li a {
  position: relative;
  /* Ensure the pseudo-element is positioned relative to this */
  padding-left: 10px;
  /* Adjust as needed to create space for the border */
  display: inline-block;
  /* To ensure padding and pseudo-element work as expected */
  text-decoration: none;
  /* Optional: Remove underline from links */
}

.dropdownMenuMobile li a::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 4px;
  color: #0e54a3 !important;

  transition: background-color 0.3s ease;
}

.dropdownMenuMobile li a:hover::before {
  background-color: #ff7631;
  /* Border color on hover */
}

.dropdownMenuMobile li a:hover {
  color: #ff7631 !important;
}
