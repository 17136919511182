.insured-person-file {
    width: 100%;
    margin-top: 20px;
}

.file-header {
    margin-bottom: 10px;
}

.file-header h4 {
    font-size: 18px;
    color: #1959a0;
    /* Blue color for header */
    margin: 0;
}

.file-header p {
    font-size: 12px;
    color: #808080;
    /* Light gray color for subtitle */
    margin: 0;
}

.file-upload {
    display: flex;
    justify-content: flex-start;
}

.file-box {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #e6eff3;
    /* Light blue background */
    border: 1px dashed #d3d3d3;
    /* Dashed border */
    border-radius: 8px;
    cursor: pointer;
}

.file-box span {
    font-size: 14px;
    margin-left: 10px;
    color: #1959a0;
    /* Blue color for file name */
}

.upload-icon {
    font-size: 18px;
    color: #1959a0;
}