.content-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #0d47a1;
  cursor: pointer;
}

.time-text {
  font-size: 12px;
  color: #7d7d7d;
}

.time-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
