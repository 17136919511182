.parent {
  background: linear-gradient(135deg, #d2eaef 9%, #00276d 100%);
  width: 100%;
  height: 100vh;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logocontainer {
  width: 64px;
  height: 62px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  left: 50px;
}
.imglogo{
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.card {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  height: 90%;
  border-radius: 12px;
  overflow: hidden;
}

.cardimgconatiner {
  width: 80%;
  height: 45%;

  max-height: 50%;
  overflow: hidden;
}

.cardimg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.textcontainer {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50%;
}

.heading {
  font-size: 2.5rem;
  font-weight: bolder;
  color: #1a1c1ecc;
  text-align: center;
}

.title {
  color: #1b1a1a;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  margin-top: 2%;
}
.buttoncontainer {
  width: 100%;
  margin: 20px 0 30px 0;
}
@media (max-width: 768px) {
  .parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logocontainer {
    position: static;
    top: 10px;

    display: flex;
    justify-content: center;
  }
  .card {
    width: 50%;
    height: auto;
    padding: 20px;
    margin: 5px 0;
  }

  .heading {
    font-size: 2rem;
  }

  .title {
    font-size: 1rem;
    margin-top: 10px;
  }

  .buttoncontainer {
    width: 100%;
    margin: 20px 0 30px 0;
  }
}

@media (max-width: 480px) {
  .parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logocontainer {
    position: static;
    top: 10px;
    margin: 0 auto;
    height: auto;
    display: flex;
    justify-content: center;
  }

  .card {
    width: 80%;
    height: 80%;
    padding: 16px;
    margin-top: 10px;
  }
  .cardimgconatiner {
    height: 35% !important;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .textcontainer {
    width: 100%;
    height: 55%;
    margin: 10px 0;
  }

  .heading {
    font-size: 1.8rem;
  }

  .title {
    font-size: 0.9rem;
  }

  .buttoncontainer {
    width: 100%;
    margin: 20px 0 30px 0;
  }
}
