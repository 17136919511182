.word {
  word-spacing: 5px;
}
.main {
  /* padding: 40px 120px 40px 120px; */
  padding: 30px;
  border-radius: 8px;
  min-height: 76.3vh;
  max-height: 1000vh;
  background-color: #f5f5f5;
  color: #00276d;
}
.outerContainer {
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  height: 78vh;
}
.mt24 {
  margin-top: 24px;
}
.mt40 {
  margin-top: 40px;
}
.pt16 {
  padding-top: 16px;
}
.mt16 {
  margin-top: 16px;
}
.refund > ul > li {
  margin-top: 16px;
}
.refund > ul {
  list-style-type: decimal;
}
.pt24 {
  padding-top: 24px;
}
.agree > ul {
  list-style-type: lower-alpha;
}
.agree > ul > li {
  padding-top: 16px;
}
.colorBlue {
  color: #0e54a3;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
}
.textcolor {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #131313;
  letter-spacing: 0%;
}

.colorOrange {
  color: #ff7631;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
}
.flexText {
  display: flex;
  align-items: center;
  gap: 6px;
}
.fs24 {
  font-size: 24px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .fs24 {
    font-size: 16px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
}
@media (min-width: 769px) and (max-width: 1040px) {
}
@media (min-width: 1041px) and (max-width: 1440px) {
}
@media (min-width: 769px) and (max-width: 2800px) {
}
