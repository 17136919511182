.container {
  padding: 24px;
  overflow-y: auto;
  max-height: 70vh;
  max-width: fit-content;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.regular {
  font-weight: 400;
}
.padding16 {
  padding: 16px;
}

.mt16 {
  margin-top: 16px;
}
.alignItemsCenter {
  align-items: center;
}

.textCenterPadding {
  text-align: center;
  padding: 0 10px;
}

.fontSize22 {
  font-size: 22px;
}

.borderBottom {
  border-bottom: 1px solid #000;
}

.borderTop {
  border-top: 1px solid #000;
}

.logo {
  width: 20%;
  height: auto;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.noMargin {
  margin: 0;
}

.noPadding {
  padding: 0;
}

/* .footer {
  position: sticky;
  display: flex;
  flex-direction: row-reverse;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 18px;
} */

.largeText {
  font-size: 26px;
  color: #000;
}
.button {
  padding: 14px 20px;
  margin: 10px 5px;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.mediumText {
  font-size: 20px;
  color: #000;
}

.smallText {
  font-size: 18px;
  color: #000;
  text-align: center;
  width: 30%;
}

.primaryColor {
  color: #1a3d7c;
}

/* Add styles for margin and padding */
.marginTop16 {
  margin-top: 16px;
}

.padding16 {
  padding: 16px 0;
}

.width45 {
  width: 45%;
}

.width33 {
  width: 33%;
}

.textCenterPadding {
  text-align: center;
  padding: 0 10px;
}

.fontSize22 {
  font-size: 22px;
}

.marginRight34 {
  margin-right: 34px;
}

.marginTop10 {
  margin-top: 10px;
}
