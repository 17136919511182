.mt40 {
  margin-top: 40px;
}
.flx {
  display: flex;
  flex-direction: row;
}
.flxp {
  display: flex;
  flex-direction: row;
}
.container {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
  margin: 10px;
}
.textcenter {
  text-align: center;
}
.middel {
  text-align: center;
  border-right: 2px dashed #00276d;
  width: 50%;
}

.center {
  display: flex;
  justify-content: center;
}
.user2 {
  padding: 20px;
  width: 320px;
}
.table {
  width: 750px;
  margin-top: 40px;
}
.colorblue {
  color: #00276d;
}
.mt32 {
  margin-top: 32px;
}
.outer {
  height: 91vh;
}

.flxbtween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.my32 {
  margin: 32px 0;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.outerContainer2 {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: auto;
}
.back {
  height: 24px;
  width: 24px;
  margin-right: 24px;
  color: #00276d;
}
.close {
  margin-left: auto;
  height: 24px;
  width: 24px;
  color: #00276d;
}
.bookingSelection {
  font-size: 24px;
  font-weight: 600;
  color: #00276d;
}

/* .............Ticket Detail.............. */
.flightcard {
  padding: 20px;
  border-radius: 24px;
  background-color: #f5f5f5;
  width: 80%;
  box-sizing: border-box;
  margin-top: 24px;
  height: 400px;
  color: #00276d;
  font-weight: 400;
}
.flightLogo {
  width: 80px;
  height: 68px;
  margin-right: 16px;
  border-radius: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt32 {
  margin-top: 32px;
}
.mt8 {
  margin-top: 8px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.borderBottom {
  border-bottom: 2px dotted #00276d;
  width: 200px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.plane {
  height: 30px;
  width: 40px;
}
.center {
  text-align: center;
}
.icons {
  height: 30px;
  width: 30px;
  color: black;
  margin: 0 10px;
}

/* 
............TOUR............... */
.imgConatiner {
  height: 150px;
  width: 30%;
  box-sizing: border-box;
  margin-top: 32px;
  border-radius: 24px;
  margin-right: 32px;
  margin-bottom: 32px;
}
.tourImg {
  width: 100%;
  height: 200px;
}

/* TABLES */
.tableData {
  overflow: auto;
  height: 62vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
}
.wapper > tr {
  color: #000000 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0.5px 1px 6px,
    rgba(0, 0, 0, 0.23) 0.5px 1px 6px;
  color: #000000 !important;
  background-color: white !important;
}
.w20 {
  width: 12.5% !important;
}
.w16 {
  width: 16.66% !important;
  text-align: center;
}
.headerclass {
  width: 12.5% !important;
  text-align: center;
  padding: 15px;
}
.headerclass3 {
  width: 18% !important;
  text-align: center;
  padding: 15px;
}
.headerclass2 {
  width: 16.66% !important;
  text-align: center;
  padding: 15px;
}
.headerOuter {
  display: flex;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  background-color: #33cccc;
  color: white;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 600;
  position: sticky !important;
  top: 0px;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #33cccc;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.DetailCard {
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
}
.mt24 {
  margin-top: 24px;
}
.colorGray {
  color: #7d7d7d;
}
.bitBtn {
  margin-top: 24px;
  margin-left: auto;
  width: 160px;
  display: flex;
  justify-content: end;
}
.mb16 {
  margin-bottom: 16px;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.w25 {
  width: 22%;
  margin-right: 32px;
}
.amenties {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #00276d;
  width: 250px;
}
.outerpicker {
  width: 341px;
  background-color: #e3ebed;
  border-radius: 8px;
  border: 1px dashed #7d7d7d;
  height: 44px;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
}
.download {
  height: 20px;
  width: 20px;
}
.imgwidth {
  width: 23px;
}
