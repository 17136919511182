.Heading {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 40px 0;
}
@media (min-width: 320px) and (max-width: 480px) {
  .Heading {
    padding: 20px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .Heading {
    padding: 20px 40px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .Heading {
    padding: 20px 80px;
  }
}
@media (min-width: 769px) and (max-width: 2800px) {
}
.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}
.mianheading22 {
  text-align: center;
  margin-top: 40px;
  color: #ff7631;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
