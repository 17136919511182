.outerRefresh {
  /* height: 300px;
    width: 390px; */
  margin-left: 16px;
  margin-top: 3px;
}

.addIcon {
  height: 24px;
  width: 24px;
  color: tomato;
}

.RefreshIcon {
  height: 24px;
  width: 24px;
}
.backOuter {
  margin-right: 16px;
}

.searchResults {
  position: absolute;
  width: 42.7%;
}
.searchResults::-webkit-scrollbar {
  display: none;
}
.suggestionItem {
  height: 36px;
  display: flex;
  align-items: center;
  color: #131313;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;
}

.suggestionItem:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}
.pr36 {
  padding-right: 36px;
}
.subHeading {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #131313;
  letter-spacing: -0.25px;
}
.outer {
  width: calc(100% - 36px);
  background-color: white;
  border-radius: 24px;
  padding: 32px;
  height: 445px;
  overflow: scroll;
  margin-right: 36px;
}
.outer::-webkit-scrollbar {
  /* WebKit */
  display: none;
}
.SaveBtn {
  height: 48px;
  width: 110px;
  border: 2px solid #0e54a3;
  background-color: #0e54a3;
  color: white;
  cursor: pointer;
  border-radius: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.5%;
  margin-top: 16px;
}
