.container {
  /* margin: 24px; */
  /* min-height: 100vh; */
  margin-top: 24px;
}
.branch {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #393a44;
}
.Account {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #131313;
}
.accountbtn {
  width: 213px;
  height: 48px;
  border-radius: 24px;
  background-color: #0e54a3 !important;
  border: none !important;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
}
.btntext {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 12.22px;
  letter-spacing: -0.2181818187236786px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #ffffff;
  white-space: nowrap;
}
@media (max-width: 480px) {
  .accountbtn {
    margin-top: 10px;
  }
}
