.bordr {
  border-bottom: 1px solid #c7c7c7;
}
.click {
  cursor: pointer;
}
.empty3 {
  width: 256px;
  height: 189px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
.tablecontainer {
  width: 100%;
  margin: 0;
  height: 58vh;
}
.tableparents {
  width: 100%;
  margin: 0;
  height: 56vh;
  overflow-y: hidden;
}
.tableData {
  overflow: hidden;
  height: 58vh;
}

.tableData::-webkit-scrollbar {
  display: none;
}

.tableRow {
  width: 100%;
}

.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
}

.wapper {
  width: 100%;
  border-collapse: collapse; /* Ensures borders and spacing are consistent */
}

.wapper > tr {
  color: #000000 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
}

.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px,
    rgba(136, 134, 134, 0.23) 4px 3px 10px;
  transform: scale(1);
  color: #000000 !important;
  background-color: white !important;
}

.w20 {
  width: 12.5%;
  text-align: center;
  padding: 0.2rem;
}

.headerclass {
  width: 12.5%;
  text-align: center;
  padding: 10px;
  position: sticky;
  top: 0;
  background-color: #099bed;
}
.tableHeader th {
  z-index: 0;
  color: white;

  padding: 10px;
}

.headerOuter {
  display: flex;
  align-items: center;
  background-color: #099bed;
  color: white;
  border-radius: 4px 4px 0px 0px !important;
  font-size: 16px;
  font-weight: 600;
  position: sticky;
  top: 0px;
  z-index: 1;
}
