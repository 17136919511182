.flycard {
  border-radius: 16px;
  padding: 15px;
  margin-top: 24px;
  background-color: #f5f5f5;
  box-sizing: border-box;
}
.FlightCard {
  height: 170px;
  width: 50%;
  background-color: #8cb7a3;
  color: white;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 24px;
  margin-right: 24px;
}
.FlightIcon {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  margin-right: 8px;
}
.iconss {
  height: 32px;
  width: 32px;
  color: #00276d;
}
.TourCard {
  height: 170px;
  width: 50%;
  background-color: #4d4e8d;
  color: white;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 24px;
  margin-right: 24px;
}
.RecentTour {
  height: 336px;
  width: 100%;
  background-color: white;
  color: #4d4e8d;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 20px;
}
.RecentFlight {
  height: 336px;
  width: 50%;
  background-color: white;
  color: #4d4e8d;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 20px;
}
.TourFlight {
  margin-right: 24px;
  margin-top: 24px;
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  height: 142px;
}
.weekRevenue {
  margin-right: 24px;
  margin-top: 24px;
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  height: 330px;
}
.overallRevenue {
  margin-top: 24px;
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  height: 330px;
}
.colorGreen {
  color: #8cb7a3;
}
.flxb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mb8 {
  margin-bottom: 8px;
}
.border {
  border-top: 2px dotted #4d4e8d;
}
.colorgary {
  color: #949494;
}
.flages {
  height: 260px;
  overflow-y: auto;
}

.flages::-webkit-scrollbar {
  display: none;
}

/* Track */
.flages::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.flages::-webkit-scrollbar-thumb {
  display: none;
}

/* Handle on hover */
.flages::-webkit-scrollbar-thumb:hover {
  display: none;
}
.BorderRight {
  border-right: 3px solid #8cb7a3;
  height: 100px;
}
.flx {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.textCenter {
  display: flex;
  justify-content: center;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: auto;
}
.Mainflx {
  display: flex;
  align-items: start;
}
.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  width: 72px;
  height: 72px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  border: none;
  object-fit: cover;
}

.modalName {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #7D7D7D;
  margin-top: 35px;
}

.modalNameWithPMDC {
  margin-bottom: 12px;
}

.modalNameWithoutPMDC {
  margin-bottom: 32px;
}

.modalPMDC {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #13A89E;
}

.modalText {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #454545;
}

.modalVerificationText {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #7D7D7D;
  margin-top: 30px;
}

.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  gap: 48px;
}

.modalButtonReject {
  width: 201px;
  height: 48px;
  border-radius: 24px;
  background-color: #7D7D7D;
  border: none;
  box-shadow: 2.79px 2.79px 8.36px 0px #00000040;
  color: #fff;
}

.modalButtonAccept {
  width: 201px;
  height: 48px;
  border-radius: 24px;
  background-color: #0E54A3;
  border: none;
  box-shadow: 2.79px 2.79px 8.36px 0px #00000040;
  color: #fff;
}

