.mainconatiner {
  box-shadow: 0px 4px 4px 0px #0000000d;
  /* background-color: #f5f5f5; */
  border-radius: 8px;
  width: 61.11vw;
  height: fit-content;
  margin: 0;
  padding: 16px;

  background-color: #006838;
}
.colwidth {
  width: 33%;
  text-align: end;
}
.pickdrop {
  color: #909198;
  font-size: 14px;
  /* margin-bottom: 4px; */
  font-weight: 700;
  text-align: start;
}
.pickdropTO {
  color: #909198;
  width: 40px;
  max-width: 50px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 700;
}

.pickupdetail {
  color: #0e54a3;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: start;
}
.pickupdetailTO {
  color: #0e54a3;
  font-size: 14px;
  width: 40px;
  max-width: 50px;
  font-weight: 400;
}

.icon {
  color: #d2092d;
  font-size: 19px;
  cursor: pointer;
}

.bidcontainer {
  display: flex;
  justify-content: end;
  margin: 10px 0;
}

.bidsBtn {
  color: #fff;
  border: 1px solid #0e54a3;
  padding: 10px 50px;
  margin: 0 10px;
  background-color: #0e54a3;
  border-radius: 40px;
  cursor: pointer;
}

.detailconatiner {
  box-shadow: 0px 4px 4px 0px #0000000d;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 5px;
  margin: 15px 0;
}
.imgcontainer {
  width: 100%;
  margin: 0 2px;
}
.travelimg {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: contain;
  box-shadow: 0px 4px 4px 0px #0000000d;
}

.btncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}
.firstbtn {
  color: #d2092d;
  border: 1px solid #d2092d;
  padding: 10px 50px;
  background-color: #fff;
  border-radius: 40px;
  margin: 0 10px;
  cursor: pointer;
}
.secoundbtn {
  color: #fff;
  border: 1px solid #006838;
  padding: 10px 50px;
  margin: 0 10px;
  background-color: #006838;
  border-radius: 40px;
  cursor: pointer;
}
