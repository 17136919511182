.feacture {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95.5%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: #fff !important;
  height: 5.7vh;
  justify-content: space-between;
  padding: 0px 12px;
}
.selectionDrop {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 48px;
  width: 49.2%;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.locationButton {
  width: 180px;
  height: 30px;
  background-color: #0e54a3;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  border-radius: 12px;
  margin-top: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.locationButton:hover {
  background-color: #094080;
  transform: scale(1.05);
}

.locationButton:active {
  background-color: #072c5c;
  transform: scale(0.98);
}

.dropdown {
  box-shadow: 2.79px 2.79px 8.36px 0px #00000040;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 50%;
  margin-top: 2px;
  max-height: 150px; /* Fixed height */
  overflow-y: auto;
}
.customText {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0%;
  color: #7d7d7d;
}
.countryText {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0%;
  padding: 10px;
  cursor: pointer;
}
.countrySelected {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: #fff;
  letter-spacing: 0%;
  padding: 10px;
  cursor: pointer;
  background-color: #0e54a3;
}
.feacture:focus-within {
  border: none;
  background: linear-gradient(to right, #ff7631, #2575fc);
  padding: 2px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none; /* Prevents interaction */
}
.selectCompany {
  display: flex;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: #fff !important;
  height: 4.7vh;
}
.justifyContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
}
.selectCompany:focus-within {
  border: none;
  background: linear-gradient(to right, #ff7631, #2575fc);
  padding: 2px;
}
.lable {
  color: #7d7d7d;
  font-weight: 400;
  size: 12px;
}
.selectLable {
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  display: flex;
  border-radius: 16px;
  padding: 4px 16px;
  height: fit-content;
  width: fit-content;
  background-color: rgba(14, 84, 163, 1);
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}
.tab {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjusted gap for better spacing */
  width: 100%;
  padding: 8px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-width: none; /* Hide scrollbar (Firefox) */
  -ms-overflow-style: none;
}
.tab::-webkit-scrollbar {
  display: none; /* Hide scrollbar (Webkit browsers) */
}
.absolute {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 4px 4px 12px 0px #00000040;
  background-color: white;
  height: 20vh;
  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.absolute::-webkit-scrollbar {
  display: none;
}

.absolute {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.rowBasic {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  margin: 16px 0;
}

.inputGroupBasic {
  flex: 1;
  /* min-width: 48%; */
  width: 45%;
  /* margin-right: 32px; */
}

.inputGroupBasic:last-child {
  margin-right: 0;
}
.addressWidthSet {
  width: 100%;
}

/* Selector */
.custom_selector {
  position: relative;
  width: 100%;
}

.custom_selector input {
  height: 3vh;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.input_container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0px;
}

.dropdown_icon {
  position: absolute;
  right: 10px;
  font-size: 12px;
  pointer-events: none;
  color: gray;
  /* transition: transform 0.3s ease; */
  transition: border-color 0.3s ease;
  /* background-color: #fff !important; */
}

.dropdown_icon.open {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  width: 100%;
  max-height: 150px;
  margin-top: 10px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.placeholder {
  color: #757575;
  font-size: 16px;
}
.option {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option {
  padding: 10px;
  cursor: pointer;
}
.fiftyPercentWidth {
  width: 100%;
  font-size: 14px;
}

.tagsContainer {
  width: 30%;
  height: 39px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border-radius: 4px;
  justify-content: flex-end;
  flex-direction: row;
  box-sizing: border-box;
  border: none;
}

.tag {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9e9e9;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  white-space: nowrap;
  max-width: 100px;
  min-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 25px;
  border: none;
}

.tag span {
  max-width: 6ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.tag button {
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  margin-left: 8px;
}

.option:hover {
  background-color: #f1f1f1;
}
.optionText {
  flex-grow: 1;
}
.selected {
  background-color: #ece7e7;
  color: #333333;
}
.tick {
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 13px;
  text-align: center;
  width: 17px;
  height: 17px;
}

.selectedOptionsContainer {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.selectedOptionText {
  flex-grow: 1;
}

.removeOption {
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  margin-left: 5px;
}

.removeOption:hover {
  color: red;
}

/* Date Picker styling */
.datePickerContainer {
  /* width: 100%; */
  height: 48px;
  padding: 0px 10px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  background-color: #fff !important;
  /* transition: border-color 0.3s ease; */
}

.dateInput {
  height: 48px;
  font-size: 1rem;
  outline: none;
  border: none;
  color: #1f1f1f;
}

.dateInput:focus {
  border-color: #007bff;
}

.datePickerIcon {
  position: absolute;
  right: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.datePickerIcon:hover {
  opacity: 1;
}

/* Error styling */
.error {
  margin-top: 4px;
  font-size: 12px;
  color: #ff4d4f;
  white-space: normal;
  word-wrap: break-word;
}

.boxContainer {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9f9f9;
}
.widthDatePicker {
  width: 100%;
}

@media (max-width: 770px) {
  .rowBasic {
    flex-direction: column;
    gap: 12px;
    margin: 10px 0;
    padding-right: 15px;
  }

  .widthDatePicker {
    margin-bottom: 25px;
    width: 100%;
  }

  .inputGroupBasic {
    flex: 1;
    min-width: 100%;
    margin-right: 0;
  }

  .input_container,
  .options {
    width: 100%;
  }
  .custom_selector {
    width: 100%;
  }
  .addressWidthSet {
    width: 100%;
  }

  .datePickerContainer {
    width: 102%;
    height: 24px;
    padding: 8px;
  }

  .dateInput {
    height: 100%;
    font-size: 1rem;
  }

  .dropdown_icon,
  .datePickerIcon {
    right: 10px;
    width: 18px;
    height: 18px;
  }
}

@media (min-width: 770px) and (max-width: 977px) {
  .rowBasic {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: space-between;
    margin: 12px 0;
    padding-right: 16px;
  }

  .inputGroupBasic {
    flex: 1;
    min-width: 320px;
    margin-right: 24px;
  }

  .inputGroupBasic:last-child {
    margin-right: 0;
  }

  .custom_selector,
  .input_container,
  .options {
    width: 100%;
  }
  .custom_selector {
    position: relative;
    width: 107%;
  }

  .datePickerContainer {
    width: 100%;
    height: 40px;
    padding: 8px;
  }

  .dateInput {
    height: 2.5vh;
    font-size: 0.9rem;
  }

  .dropdown_icon,
  .datePickerIcon {
    right: 8px;
    width: 18px;
    height: 18px;
  }
}
.truncatedSelection {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
}

.selectedOption {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 4px 8px;
  margin-right: 8px;
  white-space: nowrap;
}

.optionText {
  margin-right: 4px;
}

.removeOption {
  background: none;
  border: none;

  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.removeOption:hover {
  color: darkred;
}

.ellipsis {
  margin-left: 8px;
  font-size: 10px;
  color: gray;
  display: flex;
  align-items: center;
}

.select_input {
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #1f1f1f;
}
@media (min-width: 379px) and (max-width: 767px) {
  .agreementContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .agreementText {
    font-size: 12px;
    color: #000;
    max-width: 100%;
  }

  .linkText {
    font-size: 12px;
    color: orange;
    text-decoration: underline;
    cursor: pointer;
  }
  .radioContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
  }
}

.agreementContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.radioContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radioInput {
  display: none;
}

.radioMark {
  height: 20px;
  width: 20px;
  margin-right: 10px !important;
  color: #e25d5d !important;
}
.radioMark2 {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #e25d5d;
  background-color: white;
  margin-right: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radioMark2.checked {
  background-color: #e25d5d;
  border: none;
}

.radioInput:checked + .radioMark {
  background-color: #0056b3;
}

.radioInput:checked + .radioMark::after {
  content: "";
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 2px;
  left: 7px;
  transform: rotate(45deg);
}

.agreementText {
  color: #000;
}

.linkText {
  color: orange;
  text-decoration: underline;
  cursor: pointer;
}
@media (min-width: 1850px) {
  .agreementText {
    font-size: 19px;
    color: #000;
    max-width: 800px;
  }

  .agreementContainer {
    display: flex;
    /* margin-left: 120px; */

    align-items: center;
    margin-top: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .agreementContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }

  .agreementText,
  .linkText {
    font-size: 14px;
  }

  .radioContainer {
    margin-bottom: 15px;
    width: 100%;
  }
}
@media (min-width: 1850px) {
  .agreementText {
    font-size: 19px;
    color: #000;
    max-width: 800px;
  }

  .agreementContainer {
    display: flex;
    margin-left: 120px;
    align-items: center;
    margin-top: 10px;
  }
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.continueButton {
  padding: 1% 9%;
  background-color: #ff7631;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 30%; /* Adjust as needed */
}

.continueButton:hover {
  background-color: #ff7631;
}
@media (max-width: 980px) {
  .continueButton {
    padding: 10px 30px;
    font-size: 14px;
    width: 30%;
  }
}
@media (max-width: 768px) {
  .continueButton {
    padding: 8px 30px;
    font-size: 13px;
    width: 100%;
  }
}
.branchContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.backButton {
  background-color: #f6f6f6;
  color: #7d7d7d;
  font-weight: 300;
  width: 180px;
  height: 48px;
  border-radius: 24px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.nextButton {
  background-color: #0e54a3;
  color: #fff;
  font-weight: 300;
  width: 180px;
  height: 48px;
  border-radius: 24px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
