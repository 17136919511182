.appointmentTitle {
  text-align: center;
  margin: 20px 0;
}

.navBar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  max-width: 681px;
  /* border: 1px solid #b2bed3; */
  border-top: 0;
  background: #fff;
}
.hospitalBar {
  display: flex;
}
.hospitalButton,
.navButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #0d47a1;
  font-weight: bold;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.hospitalButton:hover,
.navButton :hover {
  color: #1565c0;
}
.hospitalButton.active,
.navButton.active {
  background-color: #0d47a1;
  color: white;
  border-radius: 8px;
}

.underline {
  position: absolute;
  height: 2px;
  background: var(--Primary-Color-One, #0d47a1);
  bottom: -4px;
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  width: 681px;
}
.showMoreContainer {
  display: flex;
  justify-content: end;
}
.paymentCard {
  width: 448px;
  height: 253px;

  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  opacity: 1; /* Changed from 0 to 1 */
  box-shadow: 0px 4px 4px 0px #00000014;
  background: #fff; /* Assuming a white background */
  padding: 16px;
  box-sizing: border-box;
}

.paymentOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.option {
  display: flex;
  flex-direction: column;
}

.paymentDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.detail {
  display: flex;
  flex-direction: column;
}

.paymentButton {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
