.custominput > input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.input-element {
  height: 32px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 0px;
  color: #001f57 !important;
}

.input-element:focus {
  outline: none;
}

.inputStyle {
  color: #00276d !important;
  font: italic !important;
  font-size: 14px;
  font-weight: 300;
}
