.lists > li::marker {
  font-size: 35px !important;
}
.lists > li {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 2px;
  width: 120px;
}
.tooltip {
  background-color: white;
  padding: 20px;
  box-shadow: 2px 2px 4px gray;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
}
