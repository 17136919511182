.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0px 191px;
  margin-top: 24px;
}
.header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.customTest {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0%;
  color: #000000;
  margin-top: 4vh;
}
.tourism {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #edf3f6;
  width: 100%;
  max-width: 48%;
  height: auto;
  overflow: hidden;
  position: relative;
}
.topImage {
  width: 100%;
  height: auto;
  object-fit: fill;
  display: block;
}

.greenImage {
  position: absolute;
  top: 10px;
  left: 10px;
  object-fit: cover;
  width: 78.53px;
  height: 32px;
}
.bottomDiv {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.topView {
  padding: 8px 12px;
}
.topText {
  font-size: 28px;
  font-weight: 400;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
.bar {
  width: 134px;
  border: 3px solid #ff7631;
  height: 0;
}

.bookNow {
  width: 120px;
  height: 40px;
  border-radius: 47px;
  background-color: #ff7631;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: flex-end;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
.right {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.para {
  flex-grow: 1;
  min-height: 100px;
  max-height: 300px;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 320px) and (max-width: 480px) {
  .mainContainer {
    margin: 0px 24px;
    margin-top: 24px;
    flex-direction: column;
    gap: 24px;
  }
  .header {
    height: auto;
  }
  
  .tourism {
    width: 100%;
    max-width: 100%;
  }
  
  .para {
    flex-grow: 1;
    height: auto; 
    min-height: auto;
    max-height: none; 
    overflow: visible; 
    text-overflow: unset; 
    white-space: normal; 
    word-wrap: break-word; 
    margin-top: 24px;
    font-size: 14px;
  }
  
  .topText {
    font-size: 18px;
    width: 100%;
  }
  .bar {
    width: 50px;
    border: 2px solid #ff7631;
  }
  .bookNow {
    width: 110px;
    height: 35px;
    border-radius: 28px;
    font-size: 14px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .mainContainer {
    display: flex;
    margin: 0px 24px;
    margin-top: 24px;
  }
  .topText {
    font-size: 20px;
  }
  .tourism {
    width: 48%;
  }
  .bookNow {
    width: 80.27px;
    height: 27px;
    border-radius: 31px;
    font-size: 10px;
  }
  .bar {
    width: 89px;
    border: 2px solid #ff7631;
    height: 0;
  }
  .para {
    margin-top: 16px;
    font-size: 8px;
  }
}

/* Tablet View - Row wise */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .mainContainer {
    display: flex;
    margin: 0px 100px;
    margin-top: 24px;
  }
  .para {
    flex-grow: 1;
    height: auto; 
    min-height: auto;
    max-height: none; 
    overflow: visible; 
    text-overflow: unset; 
    white-space: normal; 
    word-wrap: break-word; 
    margin-top: 24px;
    font-size: 14px;
  }
}
@media (min-width: 1441px) and (max-width: 2560px) {
  .mainContainer {
    margin-top: 80px;
  }
  .topText {
    font-size: 36px;
  }
  .bar {
    width: 160px;
    border: 3px solid #ff7631;
  }
  .bookNow {
    width: 150px;
    height: 45px;
    font-size: 20px;
  }
  .para {
    font-size: 22px;
  }
  .topView {
    padding: 12px 16px;
  }
  .customTest {
    font-size: 24px;
    line-height: 32px;
  }
}
