.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.flx {
  display: flex;
  align-items: center;
  gap: 24px;
}
.refreshdiv {
  display: flex;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #fb2047;
}
.nameCard {
  background-color: white;
  border-radius: 24px;
  padding: 16px;
  width: 40%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #00276d;
}
.bidCard {
  background-color: white;
  border-radius: 24px;
  padding: 16px;
  width: 20%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #00276d;
}
.iconsOuterGreen {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: #007201;
  background-color: #c4fcbb;
  margin: 0 10px;
}
.iconsOuterOrange {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: #ff6600;
  background-color: #ffedd6;
  margin: 0 10px;
}
.arrowdown {
  height: 12px;
  width: 12px;
  color: #ff6600;
}
.colorOrange {
  color: #ff6600;
}
.colorGreen {
  color: #007201;
}
.arrowUp {
  height: 12px;
  width: 12px;
  color: #007201;
}
/* .AbmbulanceDashBoard {
  margin-top: 24px;
}
.AbmbulanceDashBoard > table > thead {
  background-color: transparent !important;
}
.AbmbulanceDashBoard > table > tbody > tr {
  text-align: center !important;
} */
