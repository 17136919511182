.menuItem {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menuItem:hover {
  background-color: #f5f5f5;
}
