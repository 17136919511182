/* .border ensures the container's width is 100%, and padding-right reserves space for the button */
.border {
  /* width: 102%; */
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  padding-right: 12px;
}

.flx {
  display: flex;
  flex-direction: row;
  padding-left: 5px;

  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.text {
  color: #666666;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.imge3 {
  height: 24px;
  width: 24px;
  cursor: pointer;
  filter: grayscale(100%);
}

@media (max-width: 770px) {
  .border {
    width: 97%;
  }
}
