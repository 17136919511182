.customTooltip {
  border-radius: 16px;
  box-shadow: 3px 3px 5px gray;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flx {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
}
.Tourists {
  background-color: #4d4e8d;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
  border-radius: 8px;
}
.Flights {
  background-color: #8cb7a3;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
  border-radius: 8px;
}
.dis {
  display: flex;
  font-style: italic;
}
