
.scroolContentView {
  display: flex;
  flex-direction: column;
  margin: 15vh auto;
  text-align: center;
  max-width: 1200px;
}

.MainHeading {
  font-size: 3rem;
  font-weight: 400;
  color: #0E54A3;
}

.paklistan {
  color: #FF7631;
}

.headingText {
  font-weight: 400;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-top: 1rem;
}

.subText {
  font-weight: 400;
  font-size: 1rem;
  padding: 0 15%;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 1.6;
}

.footerWrapper {
  position: relative;
  z-index: 20;
  margin-top: 5vh;
}
/* .......cardlayout */
.marginCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin: 80px auto;
}

.cardLayout {
  width: 100%;
  max-width: 380px;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 4px 24px 0px #FF763140;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c1{
  width: 111px;
  height: 113px;
  object-fit: contain;
}
.customText {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0%;
  color: #000000; 
  margin-top: 8vh;
}
.customTextpara{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0%;
  color: #000000; 
  margin-top: 12px;
  height: 70px;
}
/* Responsive Styles */
@media (max-width: 1024px) {
  .MainHeading {
    font-size: 2.5rem;
  }
  
  .headingText {
    font-size: 1.3rem;
  }

  .subText {
    font-size: 0.95rem;
    padding: 0 10%;
  }
  .marginCard {
    gap: 16px;
  }
  
  .cardLayout {
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .MainHeading {
    font-size: 2rem;
  }
  
  .headingText {
    font-size: 1.2rem;
  }

  .subText {
    font-size: 0.9rem;
    padding: 0 5%;
  }
  .marginCard {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 16px;
  }

  .cardLayout {
    max-width: 90%;
  }

  .c1 {
    width: 90px;
    height: 90px;
  }

  .customText {
    font-size: 20px;
    margin-top: 6vh;
  }

  .customTextpara {
    font-size: 12px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .MainHeading {
    font-size: 1.8rem;
  }

  .headingText {
    font-size: 1rem;
  }

  .subText {
    font-size: 0.85rem;
    padding: 0 3%;
  }
}


@media (min-width:1441px) and (max-width:2800px){
  .cardLayout {
    max-width: 411px;
  }
}
