.imgcontainer {
  width: 100%;
  height: 200px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
}

.mainimg {
  /* width: 640px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.dotsContainer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s;
}

.activeDot {
  background-color: #ffffff;
}
.showMoreContainer {
  display: flex;
  justify-content: start;
  margin: 10px 0;
}
.row-payment-styles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.dashed-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #000;
  margin: 16px 0;
}

.payment-title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(234, 2, 52, 1);
}

.payment-label,
.payment-amount {
  font-size: 14px;
  font-weight: 600;
}

.dotted-line {
  border-bottom: 0.5px dashed #000;
  margin: 16px 0;
}

.showMoreButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: #f2f4f6;

  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}
.paymentcomplted {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #13a89e;
  color: #f2f6f5;

  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}
.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .imgcontainer {
    width: 100%;
    /* Full width on mobile */
    height: 150px;
    /* Adjust height for mobile */
    border-radius: 16px;
    /* Adjust border-radius for mobile */
  }

  .payment-title {
    font-size: 14px;
    /* Smaller font size for mobile */
  }

  .payment-label,
  .payment-amount {
    font-size: 12px;
    /* Smaller font size for mobile */
  }

  .showMoreButton {
    padding: 8px 16px;
    /* Reduce padding for smaller buttons */
    font-size: 14px;
    /* Smaller font size for buttons */
  }

  .showMoreButton .icon {
    font-size: 16px;
    /* Smaller icon size */
  }
}

@media (max-width: 480px) {
  .imgcontainer {
    height: 120px;
    /* Further adjust height for very small screens */
  }

  .payment-title {
    font-size: 12px;
    /* Even smaller font size */
  }

  .payment-label,
  .payment-amount {
    font-size: 10px;
    /* Smaller font size for labels and amounts */
  }
}
