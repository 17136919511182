.orderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.orderCard {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  width: 60%;
  position: relative;
}

.logoContainer {
  padding: 10px;
  border-radius: 30%;
  display: flex;
  align-items: center;
}

.orderLogo {
  width: 150px;
  border-radius: 20%;
  height: 150px;
}

.orderDetails {
  flex-grow: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orderTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2px;
  color: #0e54a3;
}

.orderQuantity,
.orderPrice {
  font-size: 16px;
  color: #0e54a3;
}

.orderStatus {
  font-size: 12px;
  margin-top: 40px;
  color: #ea0234;
}

.statusInProgress {
  color: #fdb032ff;
}

.statusPending {
  color: #ea0234;
}

.orderMeta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderTime,
.orderId {
  font-size: 16px;
  color: #666;
}

.orderId {
  color: #ff7631;
}
.downloadButtonLogo {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.detailsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0e54a3;
  color: white;
  padding: 12px 24px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.detailsButton:hover {
  background-color: #0056b3;
}
/* ------------Order Detail----------- */
.orderDetailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  min-height: 100vh;
}

.orderCardDetail {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 750px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.logoHeaderDetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.logoDetail {
  width: 170px;
  height: 170px;
  border-radius: 10%;
}

.orderInfoDetail {
  text-align: center;
  color: #0e54a3;
  width: 100%;
}

.orderIdTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.orderIdTimeColor {
  color: #ff7631;
  font-weight: 500;
}

.orderDetailsRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderDetailsRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.statusPendingDetail {
  color: #ea0234;
  font-weight: 500;
}
.statusPending {
  color: #ea0234;
  font-weight: 500;
}

.statusInProgress {
  color: #fdb032;
  font-weight: 500;
}
.itemListDetail {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.itemDetail {
  font-size: 16px;
  text-align: left;
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-weight: 500;
  font-size: 14px;
  color: #00276d;
}
.itemListDetailHeaderName {
  color: #00276d;
  font-size: 16px;
  margin-bottom: 8px;
}
.itemListDetailItemHeaderName {
  color: #00276d;
  font-weight: 500;
  font-size: 12px;
}

.description {
  font-size: 14px;
  color: #909198;
  padding-top: 8px;
  margin-bottom: 16px;
}

.totalAmountDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.totalLabel {
  font-size: 20px;
  font-weight: 500;
  margin-top: 56px;
  color: #00276d;
}

.totalPrice {
  font-size: 20px;
  font-weight: 500;
  margin-top: 56px;
  color: #00276d;
}

.margin {
  margin-top: 20px;
}
