.boxq {
  height: 250px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flx {
  display: flex;
  flex-direction: row;
}
.end {
  display: flex;
  justify-content: end;
}
.col6 {
  width: 50%;
}
.pl20 {
  padding-left: 22px;
}
.outer {
  height: 90vh;
}
.tabless {
  height: 450px;
  overflow: hidden;
  margin-bottom: 40px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mt32 {
  margin-top: 32px;
}
.my32 {
  margin: 32px 0;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.btnn {
  padding: 10px 15px;
  border-radius: 8px;
  margin-left: auto;
  font-weight: 500;
  color: #00276d;
  border: 3px solid #00276d;
}
.end {
  margin-left: auto;
}
.mt32 {
  margin-top: 32px;
}
.colorBlue {
  color: #00276d;
}
.mt16 {
  margin-top: 16px;
}
.PaymentSS {
  height: 226px;
  width: 196px;
  border-radius: 4px;
}
.borderRight {
  border-right: 2px solid #00276d;
  width: 200px;
  text-align: center;
}
.textcenter {
  width: 200px;
  text-align: center;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
