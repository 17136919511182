.parent {
  background-color: #f5f5f5;
}
.main {
  background-color: #fcfcfc;
  border-radius: 10px;
  padding: 1.3rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  height: fit-content;
}
.title {
  color: black;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.title::after {
  content: "";
  display: block;
  width: 50%;
  border-bottom: 1px solid black;
  position: absolute;
}

.profileClick {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.profilePic {
  width: 70px;
  height: 70px;
  margin-top: 2rem;
  border-radius: 50%;
  object-fit: contain;
}
/* Container for centering the buttons */
.centerButtons {
  display: flex;
  justify-content: center;

  margin-top: 16px;
}

/* Common styles for both buttons */
.button {
  width: 101px;
  height: 36px;
  margin-right: 10px;
  padding: 8px 4px;
  border-radius: 5px;
  border: 1px solid transparent; /* Transparent border by default */
  opacity: 1; /* Ensure the buttons are visible */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Save button styles */
.SaveButton {
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  border-color: #4caf50; /* Green border */
}

.notValidate {
  background-color: gray; /* Green background */
  color: white; /* White text */
  border-color: gray; /* Green border */
}

/* Cancel button styles */
.cancelButton {
  background-color: transparent; /* No background */
  color: red; /* Red text */
  border-color: red; /* Red border */
}

/* Add hover effects if needed */
.SaveButton:hover {
  background-color: #45a049; /* Darker green on hover */
}

.cancelButton:hover {
  background-color: rgba(255, 0, 0, 0.1); /* Light red background on hover */
}

.inputContainer {
  width: 150%;
  /* Make the container take full width of its parent */
  max-width: 300px;
  /* Set a max width for the input */
}
.qualificationContainer {
  width: 500%;
  /* Make the container take full width of its parent */
  max-width: 900px;
  /* Set a max width for the input */
}
.textareaAboutUs {
  min-height: 100px;
  max-height: 300px;
  resize: vertical;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}
