.maincontainer {
  box-shadow: 0px 4px 4px 0px #0000000f;
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 10px;
  height: fit-content;
  margin-bottom: 100px;
  margin-top: 100px;
}
.inputContainer {
  width: 80%;
  margin: 5px;
}

@media (max-width: 768px) {
  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.errorMessage {
  color: red; /* Change to the desired color */
  font-size: 12px; /* Adjust the size as needed */
  margin-top: 5px; /* Space between input and error message */
}
.flx {
  display: flex; /* Ensure the container is a flex container */
  gap: 10px; /* Adjust the gap size as needed */
}
.showMoreContainer {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.showMoreButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: #fff;

  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select_Container {
  /* border: 1px solid #000; */
}
.select_custom {
  color: #0e54a3;
  width: 175px;
  border: none;
  outline: none;
  padding: 10px;
  overflow: hidden !important;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
@media (max-width: 600px) {
  .selectContainer {
    width: 100%;
  }

  .select {
    width: 100%;
    font-size: 14px;
  }
}

.select:focus,
.select:not(:empty) {
  color: #0e54a3;
  background-color: #fff;
}

.select option[disabled] {
  display: none;
}
.card {
  margin-top: 20px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #fff;
  z-index: 1000;
  position: absolute;
  box-shadow: 0px 4px 4px 0px #00000014;
  padding: 16px;
  /* width: 29.5%; */
}
.line {
  position: absolute;
  top: 30%;
  left: 25px;
  right: 25px;
  height: 3px;
  background-color: #b2bed3;
  z-index: -1;
}
.decrementButton,
.incrementButton {
  width: 40px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #1a73e8;
  border: none;
  outline: none;

  background-color: transparent;
}
.quantity {
  width: 76px;
  height: 18px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e5da3;
  font-weight: 600;
}
.viewQuantitybox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #1e5da3;
  padding: 4px 2px;
}
.totalAmount {
  color: #1e5da3;
  font-size: 16px;
}

.dropdownContainer {
  /* position: absolute; */
  z-index: 1000;
  bottom: -10px;
  display: flex;
}
@media (max-width: 600px) {
  .dropdownContainer {
    width: 100%;
    justify-content: space-between;
  }
}
.dropdownOptions {
  /* position: absolute; */
  background: white;
  border: 1px solid #ccc;
  z-index: 10;
}

.dropdownOption {
  padding: 10px;
  cursor: pointer;
}

.dropdownOption:hover {
  background-color: #f0f0f0;
}
@media (max-width: 768px) {
  .maincontainer {
    padding: 8px;
    /* Adjust padding */
  }

  .inputContainer {
    width: 100%;
    /* margin: 5px auto; */
  }

  .showMoreButton {
    padding: 8px 16px;
    font-size: 14px;
  }

  .card {
    padding: 12px;
    margin-bottom: 12px;
  }

  .quantity {
    font-size: 14px;
  }

  .totalAmount {
    font-size: 14px;
  }

  .select {
    padding: 8px;
    font-size: 14px;
  }

  .decrementButton,
  .incrementButton {
    font-size: 14px;
  }

  .line {
    top: 25%;
  }
}
