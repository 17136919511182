.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.column {
  margin-top: 8px;
  gap: 4px;
}

.label {
  font-size: 12px;
  color: #0e54a3;
}

.value {
  font-size: 14px;
  color: #7d7d7d;
}

.fileSection {
  margin-top: 8px;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #0e54a3;
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.buttonText {
  font-size: 12px;
  color: #0e54a3;
}

.downloadImage {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.travelerCard {
  margin-top: 16px;
  padding: 16px;
  gap: 10px;
  width: 40%;
}

.travelerText {
  font-size: 14px;
  font-weight: 500;
  color: #0e54a3;
}
