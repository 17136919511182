.parents{
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      115.72deg,
      rgba(255, 149, 0, 0.24) 0.05%,
      rgba(4, 23, 44, 0.24) 40.87%,
      rgba(50, 89, 192, 0.24) 99.12%
    );
    background-blend-mode: multiply !important;
    min-height: 100vh !important;
    width: 100%;
}





.contentContainer {
  display: flex;
 justify-content: flex-start;
 flex-grow: 1;
  flex-direction: column;
position: relative;
z-index: 999;
  padding: 0px 24px 24px 24px;
  box-sizing: border-box;
  overflow-y: auto; 
  height: calc(100vh - 48px);
  overflow: auto;
  scrollbar-width: none; 
  -ms-overflow-style: none;
  box-sizing: border-box;
  width: 100%;
}
.contentContainer::-webkit-scrollbar {
  display: none; 
}

.sidebarContainer {
  width: 280px;
  height: calc(100vh - 48px);
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  align-items: center;
  border-radius: 16px;
  margin-right: 16px;
  border: 0px 1px 0px 0px solid #000000;
  opacity: 1;
  background-color: var(--White, #ffffff);
  box-shadow: 2px 4px 12px 0px #00000040;
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* position: fixed; */
  overflow-y: auto;
  
  z-index: 10;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.sidebarContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}



.tabbarCard{
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* position: sticky !important;
  top: 0px !important;
  z-index: 10000 !important; */
  padding-top: 30px;
  /* clip-path: inset(0 0 0 0) !important;
  border: 2px solid red; */


  
}
.tabbar{
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  margin-bottom: 16px;
}
.dashboardContainer {
  flex: 1;
  overflow-y: hidden;
}
.welcomeText {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7D7D7D; 
}
.mainText {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  /* line-height: 14px; */
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7D7D7D; 
  margin-top: 4px;
}
.notification{
  background-color:#FF9500 ;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  display: flex;
}
.dashboardContainer {
  flex: 1; 
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 320px;
  padding-right: 24px;
  overflow-y: auto; 
  height: calc(100vh - 48px);
  overflow: auto;
  scrollbar-width: none; 
  -ms-overflow-style: none;
}
.greenTourism{
  background-color:#fff ;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  display: flex;
}
.dashboardContainer::-webkit-scrollbar {
  display: none; 
}

@media (max-width: 480px) {
  /* .sidebarContainer {
    position: absolute;
    top: 94px !important;
  } */


}
@media (max-width: 769px) {
  .mainContainer {
    min-height: 100vh !important;
  }
  .sidebarContainer {
    /* position: absolute;
    top: 55px;
    right: 10px;
    z-index: 1000;
    height: 90vh !important; */
    width: 74px !important;
  }

  .dashboardContainer {
    padding: 0px 0px 0px 24px;
  }


  .mainText {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 24px !important;
    letter-spacing: -0.25px;
  }
}
/* @media (max-width: 1025px) {
  .sidebarContainer {
    width: 280px;
  }
  .mainContainer {
    min-height: 100vh !important;
  }
} */
