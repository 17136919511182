.paramedicFormContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
}

.paramedicFormHeading {
  font-size: 2rem;
  color: #1e5da3;
  text-align: center;
  margin-bottom: 1.5rem;
}

.formSectionsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.leftFormSection,
.rightFormSection {
  flex: 1;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.rightFormSection {
  width: 50%;
}
@media (max-width: 768px) {
  .leftFormSection,
  .rightFormSection {
    width: 100%;
  }
}
.paramedicForm {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.paramedicForm {
  display: block;
}

.inputGroupContainerParamedicForm {
  width: 90%;
  margin-bottom: 1.5rem;
}

.areaCityContainerParamedicForm {
  display: flex;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .inputGroupContainerParamedicForm,
  .areaCityContainerParamedicForm {
    flex: 1 1 100%;
  }
}

.inputLabelParamedicForm {
  font-size: 1rem;
  color: #1e5da3;
  margin-bottom: 0.5rem;
}
.remarks {
  font-size: 1rem;
  color: #1e5da3;
  margin-bottom: 0.5rem;
  text-align: start;
  width: 100%;
}
.inputGroupContainerParamedicFormDate {
  margin-top: 2.3rem;
}

.timeInputParamedicFormTime {
  margin-top: 0.8rem;
  color: #1e5da3;
}

.inputFieldParamedicForm,
.reviewTextAreaParamedicForm {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-bottom: 0.5px solid #1e5da3;
  font-size: 1rem;
  color: #1e5da3;
  transition: border-bottom 0.2s ease;
  background-color: transparent;
}

.inputFieldParamedicForm:focus,
.reviewTextAreaParamedicForm:focus {
  border-bottom: 0.5px solid #1e5da3;
  outline: none;
}

.radioOptionsContainerParamedicForm {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.radioOptionLabelParamedicForm {
  display: flex;
  margin-top: 1rem;
  font-size: 1rem;
  max-width: 100px;
  color: #1e5da3;
}

.radioInputParamedicFormRadioRadio {
  margin-right: 0.5rem;
}

.timeInputContainerParamedicForm {
  display: flex;
  justify-content: space-between;
}

.timeInputParamedicForm {
  flex: 1;
  margin-right: 10px;
  padding: 0.75rem;
  border: none;
  border-bottom: 0.5px solid #1e5da3;
  font-size: 1rem;
  color: #1e5da3;
  background-color: transparent;
  outline: none;
  transition: border-bottom 0.2s ease;
}

.timeInputParamedicForm:focus {
  outline: none;
  border-bottom: 0.5px solid #1e5da3;
}

.timeInputParamedicForm:last-child {
  margin-right: 0;
}

.reviewTextAreaParamedicForm {
  resize: vertical;
  min-height: 100px;
  max-height: 300px;
  width: 100%;
  padding: 0.75rem;
  border: 0.5px solid #1e5da3;
  border-radius: 8px;
  background-color: transparent;
  font-size: 1rem;
  color: #1e5da3;
  transition: border-color 0.2s ease;
}

.patientDetailsHeading {
  font-size: 1.5rem;
  color: #1e5da3;
  margin-bottom: 1rem;
  text-align: left;
}

.reviewContainerParamedicForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center;
}

.reviewTextAreaParamedicForm:focus {
  outline: none;
  border-color: #1e5da3;
}

.submitButtonParamedicForm {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c468c;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 40px;
  height: 50px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
  margin: 0 auto;
  width: fit-content;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.submitButtonParamedicForm:hover {
  background-color: #0b3a6d;
}

.timeInputParamedicForm::placeholder,
.inputFieldParamedicForm::placeholder,
.reviewTextAreaParamedicForm::placeholder {
  color: #1e5da3;
  opacity: 1;
}

.downloadButtonLogo {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.requirementsHeading {
  font-size: 1.5rem;
  color: #1e5da3;
  margin-bottom: 1rem;
}
