/* Card container */
.cardContainer {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.sectionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.detailCard {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1 1 100%;
  border-radius: 8px;
  padding: 16px;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 45%;
  margin-bottom: 9px;
}

.labelText {
  font-size: 14px;
  font-weight: 500;
  color: #7a7a7a;
  margin-right: 10px;
  text-align: left;
}

.valueText {
  font-size: 16px;
  font-weight: 400;
  color: #0e54a3;
  margin-right: 30px;
  text-align: right;
}

.subTitle {
  font-size: 18px;
  font-weight: 600;
  color: #005b9f;
  margin-top: 20px;
  margin-bottom: 10px;
}

.companyLogo {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  object-fit: contain;
  border-radius: 50%;
}

.subTitle {
  font-size: 18px;
  font-weight: 600;
  color: #005b9f;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 50px;
}

.amenitiesList {
  display: flex;
  gap: 20px;
  margin-top: 8px;
  flex-wrap: wrap;
}

.amenity {
  background-color: #f0f0f0;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  color: #003366;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  text-align: center;
}

.amenity:hover {
  background-color: #003366;
  color: #fff;
}

.headingsWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .sectionContainer {
    flex-direction: column;
    gap: 15px;
  }

  .section {
    flex: 1 1 100%;
  }

  .detailCard {
    flex-direction: column;
  }

  .headingsWrapper {
    flex-direction: column;
    gap: 10px;
  }
}
