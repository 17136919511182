.container {
  /* margin: 24px; */
  padding: 24px 24px 24px 0px;
}
.heading {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #131313;
}
.backButton {
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  text-align: center;
  display: flex;
  margin-bottom: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
/* ................................... */
.bar {
  background-color: #0e54a3;
  width: 97%;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 0 8px;
  z-index: 0;
  color: transparent;
}
.active {
  width: 29.33%;
  height: 40px;
  border-radius: 24px;
  background-color: #ffffff;
  position: absolute;
  top: 4px;
  right: 1px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Ensure active text is above the non-active text */
}

.activetext {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: #000000;
  z-index: 2;
  position: relative;
}

.nonactive {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: #ffffff !important;
  cursor: pointer;
  position: relative;
  z-index: -1000;
}
/* ..................... */
.rightside {
  background-color: #ffffff;
  border-radius: 24px;
  height: fit-content;
  display: flex;
box-sizing: border-box;
padding: 16px;
  justify-content: center;
  align-items: start;
}

.Patient {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #7d7d7d;
}
/* ........................ */
.leftside {
  /* height: 100vh; */
  width: 97%;
}

.drimg {
  width: 120px;
  height: 112px;
}

.cardcontainer {
  height: 108vh;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: none; 
  margin-top: 12px;
}
.maincontainer{
  /* border: 2px solid red; */
  width: auto;
}
@media (max-width:1024px) {
  .maincontainer{
    width: 97%;
    margin: 0 auto !important;

  }

  }
  @media (min-width:1025px) {
    .maincontainer{
      width: 75%;
      margin-left: 16px;
  
  
    }
  
}

.card {
  min-width: 95%;
  width: auto;
  height: 105px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px #00000040;
  padding: 8px;
  /* margin-right: 8px; */
  /* margin-left: 8px; */
  margin-bottom: 8px;
  box-sizing: border-box;
  transition: width 0.3s ease; /* Add transition for smooth width change */
  display: flex;
  flex-direction: column;
}

.card:hover {
  width: 100%; /* Increase the width on hover */
}

.selectedCard {
  width: 100%; /* Set the width for the selected card */
  border: 1px solid #0e54a3
}

.cardheader,
.cardbottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardprofile {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 149, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}
.cardtitle {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #454545;
}
.cardname {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.cardid {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;

  text-decoration-skip-ink: none;
  color: #0e54a3;
  display: flex;
  gap: 10px;
}

.paid {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #0e54a3;
}
.carddate {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #7d7d7d;
}
.cardbottom {
  margin-top: 14px;
}
/* ..............media query............... */
@media  (max-width: 1024px) {
  .leftside {
    width: 100%;
    margin: 0 auto;
  }
  .cardcontainer{
    display: flex;
    flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  height: fit-content !important;
  margin-bottom: 16px;
  }

  .card {
    min-width:288px !important;
    height: 120px;
    /* gap: 0 10px; */
    /* display: flex; */
    margin: 0 5px;
    box-sizing: border-box;
  }

  .selectedCard {
    width: 290px; /* Set the width for the selected card */
    border: 1px solid #0e54a3
  }
}
/* @media (max-width:480) {
  .cardcontainer{
    display: flex;
    flex-direction: row !important;
  align-items: flex-start;
  box-sizing: border-box;
  height: fit-content !important;
  margin-bottom: 16px;

  }
  .card {
    min-width:241px !important;}
  
} */