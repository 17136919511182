.maincontainer {
  position: relative;
  overflow-x: auto; 
  overflow-y: auto; 
  height: 390px !important;
  width: 100%;
  /* min-width: 600px;  */
  white-space: nowrap; 
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f1f1f1;
  
  
}


.maincontainer::-webkit-scrollbar {
  width: 10px;
}

.maincontainer::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 12px;
}

.maincontainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.headertag,
.titletag,
.invoicetitle,
.qty,
.advanced,
.total {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;

  text-underline-position: " from-font";
  text-decoration-skip-ink: none;
  color: #000000;
}
.invoicetitle {
  color: #0e54a3 !important;
  font-size: 14px !important;
  cursor: pointer;
  /* margin-left: 40px; */
  margin-left: 4%;
}
.titletag {
  font-size: 14px !important;
}
.qty {
  font-size: 12px !important;
  color: #7d7d7d !important;
}
.advanced {
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.total {
  font-weight: 600 !important;
  color: #000000 !important;
  font-size: 16px !important;
}
.downloadbutton,
.draftbutton {
  width: 90px;
  height: 36px;
  color: #fff;
  padding: 14px;
  border-radius: 24px;
  background-color: #0e54a3;
  box-shadow: 4px 4px 12px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: none !important;
  cursor: pointer;
}
.downloadbutton {
  width: 44px;
  height: 36px;
}
.downlodimg {
  width: 16px;
  height: 16px;
}
.borderline {
  border-bottom: 1px solid #0e54a3;
  margin: 12px 0;
}
.itemcontainer,
.extraitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.datainput {
  width: 100px;
  height: 16px;
  border-radius: 6px;

}
.extraheader {
  height: 12px;
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitbtncontainer {
  display: flex;
  gap: 25px;
  margin-bottom: 15px;
}
.submitbtn,
.cancelbtn {
  padding: 14px;
  gap: 10px;
  border-radius: 24px;
  background-color: #0e54a3;
  box-shadow: 4px 4px 12px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  border: none;
}
.cancelbtn {
  background-color: #cccccc;
}

/*  */
.maincontainer {
  position: relative;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 390px !important;
  width: 100%;
  /* min-width: 600px;  */
  white-space: nowrap; /* Prevent content from breaking into new lines */
  scrollbar-width: thin;
  scrollbar-color: #cccccc #f1f1f1;
  /* border: 2px solid red; */
}

/* Ensure child content respects the min-width */
.innerContent {
  min-width: 600px; /* Adjust based on content needs */
}









