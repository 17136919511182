.tabContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  width: auto;
  transition: background-color 0.3s, color 0.3s;
}

.tab:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tab:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tabActive {
  background-color: #0a49a0;
  color: white;
}

.tabInactive {
  background-color: #d3d3d3;
  color: #0a49a0;
}
