.formcontainer {
  box-shadow: 0px 4px 4px 0px #00000014;
  background-color: #f5f9ff;
  padding: 15px;
}
.selectbutton {
  cursor: pointer;
  background-color: #0e54a3;
  color: #ffffff;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 115px;
  border-radius: 8px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowIcon {
  margin-left: 8px;
}
.customInsuranceImage {
  margin-bottom: 181px;
}