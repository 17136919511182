.avatar {
  height: 60px;
  width: 60px;
  margin-right: 16px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.mt32 {
  margin-top: 32px;
}
.callbox {
  height: 76px;
  width: 95%;
  background-color: #edf4fc;
  border-radius: 16px;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.UserLogo {
  height: 70px !important;
  width: 70px !important;
  margin-right: 32px !important;
}
.end {
  margin-left: auto;
}
.calls {
  height: 32px;
  width: 32px;
  margin-right: 24px;
  color: #00276d;
  font-weight: 800;
}
.message {
  min-width: 27%;
  max-width: 70%;
  border-radius: 16px;
  background-color: white;
  box-shadow: 2px 2px 4px gray;
  padding: 10px;
}
.message2 {
  min-width: 27%;
  max-width: 70%;
  border-radius: 16px;
  background-color: #e5ecf6;
  box-shadow: 2px 2px 4px gray;
  padding: 10px;
}
.outerContainer {
  background-color: white;
  border-radius: 24px;
  padding: 16px;
  height: 78vh;
}
.chat {
  height: 32px;
  width: 32px;
  margin-right: 16px;
}
.flxend {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.type {
  margin-top: 32px;
  width: 80%;
  margin-left: auto;
  background-color: #becdeb;
  border-radius: 16px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.type > input {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #00276d;
  width: 70%;
}
.mic {
  height: 24px;
  width: 24px;
  margin-right: 24px;
  color: #00276d;
  font-weight: 800;
}
