.mainCard {
  background-color: #ffffff;
  padding: 100px 40px 30px 40px;
  text-align: center;
}
.payButton {
  background-color: #0d47a1;
  color: #ffffff;
  margin-top: 50px;
  width: 20%;
  height: 50px;
  border: none;
  border-radius: 8px;
}
