.col12 {
  width: 100%;
}
.my50 {
  margin: 30px 0;
}

.outerContainer {
  padding: 56px;
  border-radius: 4px;
  background-color: white;
  /* height: 78vh; */
}
.TableOuter {
  background-color: white;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;

  padding: 24px;
}
.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}
.tabless {
  max-height: 450px !important;
  overflow: hidden !important;
  margin-bottom: 50px !important;
}
.pendingSelect {
  padding: 10px;
  border-radius: 8px;
  color: #7b7878 !important;
  border: 2px solid #dbdbdb !important;
  background-color: white !important;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.completeSelect {
  padding: 10px;
  border-radius: 8px;
  color: #006833 !important;
  border: 2px solid #006833 !important;
  background-color: white !important;
  color: white;
}

.inProcessSelect {
  padding: 10px;
  border-radius: 8px;
  color: #ffb119 !important;
  border: 2px solid #ffb119 !important;
  background-color: white !important;
}
.sticky {
  position: sticky !important;
  top: 10px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.my32 {
  margin: 32px 0;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.ArrowBack {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-right: 24px;
}
.Abc {
  display: flex;
}
.borderTop {
  border-top: 2px dotted #00276d;
}
.w20 {
  width: 20%;
  text-align: center;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #ffedd6;
}
.CustomSelectOrder {
  cursor: pointer;
}
.colorGreen {
  color: #00b69b;
}

.line {
  border: 1px dashed gray;
}
.name {
  font-weight: 400;
  font-size: 24px;
  line-height: 14.8px;
  letter-spacing: -0.26px;
  color: black;
}
.ID {
  color: #0e54a3;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
  line-height: 14.8px;
  letter-spacing: -0.26px;
}
.InfoCard {
  margin-top: 12px;
  border: 1px solid #0e54a3;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 32px;
}
.Subheading {
  font-weight: 600;
  font-size: 14px;
  line-height: 14.8px;
  letter-spacing: -0.26px;
  color: black;
}
.Details {
  color: #b01212;
  font-weight: 400;
  font-size: 10px;
  line-height: 14.8px;
  letter-spacing: -0.26px;
}
.infoWrapper {
  display: flex;
  align-items: start;
  margin-top: 16px;
  gap: 20px;
}
.w15 {
  width: calc(15% - 24px);
}
.Icon {
  width: 20px;
  height: 20px;
}
.tag {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.8px;
  letter-spacing: -0.26px;

  color: #7d7d7d;
}
.value {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.8px;
  letter-spacing: -0.26px;
  color: black;
  margin-top: 8px;
}
.AllTests {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
}
