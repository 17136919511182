.navIMG {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b");
  height: 350px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}

.mianheading22 {
  text-align: center;
  margin-top: 40px;
  color: #ff7631;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.main {
  border-radius: 16px;
  box-shadow: 2px 4px 4px 2px #0000000f;
  background-color: #fff;
}
.location {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.err {
  color: #ff7631;
}

.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.showMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  margin-bottom: 20px;
}

.buttonIcon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgconatiner {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: center;
}

.imgconatiner img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.iconStyle {
  font-size: 32px;
  color: #007b1b;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease;
  pointer-events: none; /* Prevent interactions when hidden */
}

.open {
  opacity: 1; /* Visible when open */
  pointer-events: auto; /* Allow interactions when visible */
}

/* Styles for the modal content */
.modalContent {
  width: 342px;
  height: 227px;
  border-radius: 16px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure position is relative for any absolute positioning inside */
}

.modalContent p {
  text-align: center;
  font-size: 15px;
}

.thankYouText {
  color: green;
}
.closeIcon {
  font-size: 18px;
  color: #00276d;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}