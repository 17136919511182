.modal {
  position: fixed; /* Fixing the modal overlay to the viewport */
  top: 0;
  left: 0;
  /* top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)", */
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}

.verifybtn {
  background-color: #0e54a3;
  color: #fff;
  font-weight: 300;
  /* width: 180px;
  height: 48px; */

  max-height: 35px;
  padding: 1.8% 9.5%;
  border-radius: 24px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.backbtn {
  background-color: #f6f6f6;
  color: #7d7d7d;
  font-weight: 300;
  /* width: 180px;
  height: 48px; */
  max-height: 35px;
  padding: 1.8% 9.5%;
  border-radius: 24px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formcontainer {
  box-shadow: 0px 24.35px 75.27px 0px #00000025,
    0px 45.54px 140.79px 0px #0000001e, 0px 109px 337px 0px #00000015;
  padding: 20px;
  border-radius: 8px;
  width: 50.25%;
  height: fit-content;
  /* height: auto; */
  justify-content: center;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 20px;
  z-index: 10;
  overflow-y: auto;
}
.sendContent {
  box-shadow: 0px 24.35px 75.27px 0px #00000025,
    0px 45.54px 140.79px 0px #0000001e, 0px 109px 337px 0px #00000015;
  padding: 24px;
  width: 44.79%;
  height: 35.42%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  height: fit-content;
  background-color: #fff;
  z-index: 10;
}

.welcomback {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-decoration-skip-ink: none;
  margin-bottom: 40px;
  text-align: center;
}
.logo {
  width: 20%;
  height: 20%;
  object-fit: contain;
  margin: 0 auto;
  margin-bottom: 24px;
}

.modalContent {
  position: relative;
  background: white;
  width: 70%;
  max-width: 800px;
  height: fit-content;
  padding: 30px;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.modalContent::-webkit-scrollbar {
  display: none;
}
.stepIndicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.stepNumber {
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.activeStep .stepNumber {
  background-color: #0056b3;
  color: white;
}
.completedStep .stepNumber {
  background-color: #28a745;
  color: white;
}
.stepConnector {
  width: 100px;
  height: 4px;
  background-color: #e0e0e0;
  position: absolute;
  top: 18px;
  right: -50px;
  z-index: -1;
}
.completedStep + .step .stepConnector {
  background-color: #28a745;
}

.mainHeadingSignUpPage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.secondMainHeadingSignUp {
  color: #ff7631;
  margin-left: 10px;
}
.sendContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeButton {
  display: flex;
  align-self: flex-end;
}

.sendingImage {
  width: 72px;
  height: 72px;
  margin-top: 60px;
}

.title {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
  margin-top: 50px;
}

.subtitle {
  font-size: 16px;
  color: #7d7d7d;
  font-weight: 500;
  margin-top: 24px;
}

.email {
  font-size: 16px;
  color: #0e54a3;
  font-weight: 500;
}

.separator {
  border: 1px solid;
  width: 580px;
  border-color: #7d7d7d;
  margin-top: 70px;
}

.footerText {
  margin-top: 24px;
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
  display: flex;
  align-items: center;
  gap: 8px;
}

.footerLink {
  font-size: 12px;
  color: #0e54a3;
  font-weight: 600;
  cursor: pointer;
}
.inputGroupBasic {
  overflow: hidden;
  /* position: relative; */
  height: 48px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
  align-items: center;
  border-radius: 8px;
  background-color: #fff !important;
  /* box-shadow: 4px 4px 12px 0px #00000040; */
  justify-content: space-between;
  display: flex;
  padding: 0px 10px;
  cursor: pointer;
}

.options {
  width: 97%;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  transition: border-color 0.3s ease;
  border-radius: 4px;
  overflow-y: auto;
  margin-top: -20px;
  max-height: 150px;
  position: absolute;
  background: white;
  padding: 8px;
}
.dropdownContainer {
  top: 100%;
  background: white;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: auto; /* Adjust width as per requirement */
  display: none;
}
.dropdownContainer.open {
  display: block;
}
.dropdownWrapper {
  position: relative;
}
/* ...................... */
@media (max-width: 768px) {
  .formcontainer {
    width: 70vw;
    border: 2px solid;
  }
}
