.imageContainer {
  /* width: 58.07px;
  height: 56px; */
  width: auto;
  height: auto;
  max-width: 58px;
  max-height: 56px;
  border-radius: 50%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-shadow: 4.67px 4.67px 14px -16px rgba(0, 0, 0, 0.25);
  object-fit: cover;
  margin-top: 12px;
  margin: 0 auto;
}
.tab {
  /* width: 14vw; */
  height: 44px;
  border-radius: 24px;
  background-color: rgba(14, 84, 163, 1);
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.tab:hover {
  background-color: #f1f1f1;
  color: inherit;
}
.srcStyle {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  transition: filter 0.3s ease;
}
.activeImg {
  filter: grayscale(100%) brightness(1000%);
}

.inactiveImg {
  filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%)
    contrast(90%);
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}
.activeTab {
  background-color: rgba(14, 84, 163, 1);
  color: white;
  box-sizing: border-box;
  padding: 0 10px;
}
.activeTab:hover {
  background-color: rgba(14, 84, 163, 1);
  color: white;
}
.endApp {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.inactiveTab {
  background-color: #fff;
  color: black;
  box-sizing: border-box;
  padding: 0 10px;
}
.inactiveText {
  color: black;
}
.activeText {
  color: #fff;
}
.logoutContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 26px;
  cursor: pointer;
  gap: 12px;
}

.logoutIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #b01212 !important;
}
.logoutText {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #b01212;
}
@media (max-width: 768px) {
  .text {
    display: none !important;
  }
  .activeImg {
    margin: 0 auto;
  }
  .tab {
    width: 44px;
    height: 44px;
    margin: 0 auto;
  }

  .inactiveImg {
    margin: 0 auto;
  }
  .logoutContainer {
  }
  .logouticon {
    margin: 0 auto;
  }
  .logoutText {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .imageContainer {
    /* width: 58.07px;
    height: 56px; */
    width: auto;
    height: auto;
    max-width: 40px;
    max-height: 40px;
  }
}
