.imge3 {
  height: 56px;
  width: 56px;
  display: flex;
  margin: auto;
  cursor: pointer;
}
.mt8 {
  margin-top: 8px;
}
.borderBottom {
  border-bottom: 2px dashed black;
  padding-top: 4px;
}
.width {
  width: 20%;
}
.mt32 {
  margin-top: 32px;
}
.mt16 {
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.end {
  margin-left: auto;
}
.play {
  height: 16px;
  width: 16px;
  color: #00276d;
}
.width {
  width: 20%;
}
.close {
  margin-left: 16px;
  height: 20px;
  width: 20px;
  color: #00276d;
}
.filename {
  height: 20px;
  width: 260px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
