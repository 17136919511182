.tableData {
  overflow: auto;
  height: 66vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
  font-family: "Poppins", sans-serif;
}
.wapper > tr {
  color: #393a44 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px,
    rgba(136, 134, 134, 0.23) 4px 3px 10px;
  transform: scale(1);
  color: #0d47a1 !important;
  background-color: white !important;
}
.w20 {
  width: 14.2857% !important;
  font-family: "Poppins", sans-serif !important;
}
.headerclass {
  width: 14.2857% !important;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  padding: 15px;
  font-size: 14px;
}
.headerOuter {
  display: flex;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  background-color: white;
  color: #393a44;
  border-radius: 14px 14px 0 0;
  position: sticky !important;
  top: 115px;
  font-family: "Poppins", sans-serif !important;
}
.payment {
  margin-top: 24px;
}
.statusComp {
  color: #393a44;
  background-color: #00b69b;
  border-radius: 8px;
  padding: 8px;
  width: 95px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.statusProcessingText {
  color: #393a44;
  background-color: #fdcb2e;
  border-radius: 8px;
  padding: 8px;
  width: 95px;
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
}
.statusPendingText {
  color: #393a44;
  background-color: #fd5650;
  border-radius: 8px;
  padding: 8px;
  width: 95px;
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
}

.colorGray {
  color: #909198;
}
.mainOuter {
  width: 100%;
  padding: 24px 40px 0px 0;
  box-sizing: border-box;
}
.DetailCard {
  background-color: white;
  border-radius: 16px;
  width: 32.2%;
  color: #393a44;
  margin-right: 24px;
  height: 260px;
}

.mt24 {
  margin-top: 24px;
}
.headerCard {
  padding: 10px 32px;
  color: #393a44;
  border-bottom: 1px solid #979797;
}
.headerBody {
  padding: 24px 32px;
  color: #393a44;
}
.mt8 {
  margin-top: 8px;
}
.Btnwidth {
  width: 204px;
}
.mt4 {
  margin-top: 4px;
}
.flx {
  display: flex;
  align-items: start;
}
.mt16 {
  margin-top: 16px;
}
.w5 {
  width: 1% !important;
}
.headerclassCheck {
  padding: 15px;
  width: 2%;
}
