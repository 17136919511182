.customcontainer {
  width: 206.4px;
  height: 140px;
  background-color: #fff;
  margin-top: 16px;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 18px;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: space-between;
}
.mainContainer {
  display: flex;
  flex-direction: column;
}
.imgbg {
  background-color: #d8e2ff;
  width: 36px;
  height: 36px;
  border-radius: 72px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.plan {
  width: 24px;
  height: 21px;
  object-fit: cover;
}
.contentTab {
  display: flex;
  gap: 5px;
  align-items: center;
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
.subtext {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #454545;
}
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #734a00;
  cursor: pointer;
}
.mainHotelTable {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 4px 4px 12px 0px #00000040;
  margin-top: 16px;
  padding: 20px;
  /* height: auto; */
  height: 350px;
}
.rowBase {
  display: flex;
  align-items: center;
}
.hotelTable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.loading {
  display: flex;
  margin-left: 12px;
}
.dropdowncontainer {
  font-family: "Arial", sans-serif;
  padding: 4px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.dropdowncontainer:hover {
  background-color: #f0f0f0;
}
.dropdowncontainerMain {
  font-family: "Arial", sans-serif;
  width: 300px;
  cursor: pointer;
  position: relative;
}
.emptyContent {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 50%;
  margin-top: 16px;
}
.dropdownlabel {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: -10px;
  left: 0px;
  background-color: white;
  padding: 0 4px;
  color: #000;
}

.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.dropdownselected {
  font-size: 14px;
  flex: 1;
  font-weight: 400;
  color: #131313;
}

.dropdownarrow {
  margin-left: 8px;
}

.dropdownmenu {
  position: absolute;
  top: 100%;
  left: 0;
  /* width: 100%; */
  width: 280px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 4px;
  z-index: 1000;
  box-shadow: 4px 4px 12px 0px #00000040;
}

.dropdownitem {
  cursor: pointer;
}

.dropdownitem:hover {
  background-color: #f0f0f0;
}
