.card {
  width: "100%";
  border-radius: 8px;
  /* background-color: #13a89e; */
  background-color: #f5f5f5;
  margin-bottom: 8px;
  padding: 10px;
  color: #00276d;
  box-sizing: border-box;
  cursor: pointer;
}
.check {
  width: 16px;
  height: 16px;
  margin-right: 35px;
  color: #0b7328;
  cursor: pointer;
}
.textAlignCenter {
  text-align: center;
}
.btnwidth {
  width: 120px;
  margin-top: 24px;
}
.done {
  color: green;
  height: 82px;
  width: 82px;
  display: flex;
  margin: 0 auto 16px auto;
}
.textcenter {
  text-align: center;
}
.cross {
  width: 16px;
  height: 16px;
  margin-right: 35px;
  margin-left: 70px;
  color: #fb2047;
  cursor: pointer;
}

.seletion {
  color: #00276d;
  font-weight: 600;
  font-size: 16px;
  font-weight: 800;
  border: none;
  outline: none;
  margin-bottom: 32px;
}
.flxend {
  display: flex;
  justify-content: end;
  margin-left: auto;
  align-items: center;
}
.end {
  margin-left: auto;
}
.empty3 {
  width: 256px;
  height: 250px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.emptyOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.icon {
  height: 16px;
  width: 16px;
  color: #746ca2;
  margin-right: 10px;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #746ca2;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #a2a0ac;
}

/* Detail */
.DetailCard {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fcfcfc;
  margin-top: 16px;
  color: #7d7d7d;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.filecard {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e3ebed;
  margin-top: 16px;
  color: #00276d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #7d7d7d;
}
.download {
  height: 24px;
  width: 24px;
}
.filecard2 {
  width: 30%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e3ebed;
  margin-top: 16px;
  color: #00276d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #7d7d7d;
}
.headerclass {
  width: 20%; /* Adjust based on your layout needs */
  text-align: start;
  padding: 15px 10px;
}
.headerOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #746ca2;
  color: white;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 600;
  position: sticky;
  top: 0;
}
