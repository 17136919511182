.col12 {
  width: 100%;
}
.my50 {
  margin: 30px 0;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}

.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: fit-content;
}
.tabless {
  max-height: 450px !important;
  overflow: hidden !important;
  margin-bottom: 50px !important;
}
.pendingSelect {
  padding: 10px;
  border-radius: 8px;
  color: #7b7878 !important;
  border: 2px solid #dbdbdb !important;
  background-color: white !important;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.completeSelect {
  padding: 10px;
  border-radius: 8px;
  color: #006833 !important;
  border: 2px solid #006833 !important;
  background-color: white !important;
  color: white;
}
.inProcessSelect {
  padding: 10px;
  border-radius: 8px;
  color: #ffb119 !important;
  border: 2px solid #ffb119 !important;
  background-color: white !important;
}
.sticky {
  position: sticky !important;
  top: 10px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.my32 {
  margin: 32px 0;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.ArrowBack {
  height: 24px;
  width: 24px;
  color: #00276d;
  margin-right: 24px;
}
.Abc {
  display: flex;
}
.borderTop {
  border-top: 2px dotted #00276d;
}

.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #ffedd6;
}
.CustomSelectOrder {
  cursor: pointer;
}
.colorGreen {
  color: #00b69b;
}

.line {
  border: 1px dashed gray;
}
.tableData {
  overflow: auto;
  /* height: 100vh; */
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
}
.wapper > tr {
  color: #000000 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  cursor: pointer;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px,
    rgba(136, 134, 134, 0.23) 4px 3px 10px;
  transform: scale(1);
  color: #000000 !important;
  background-color: white !important;
  cursor: pointer;
}
.CustomSelectOrder {
  cursor: pointer;
}
.w20 {
  width: 10.66%;
  text-align: center;
}
.headerclass {
  width: 16.66% !important;
  text-align: center;
  padding: 15px;
}
.headerOuter {
  display: flex;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  background-color: #ff6600;
  color: white;
  border-radius: 4px 4px 0px 0px !important;
  font-size: 16px;
  font-weight: 600;
  position: sticky !important;
  top: 115px;
}
.center {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 100px;
}
