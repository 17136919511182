.decrementButton,
.incrementButton {
  width: 40px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #1a73e8;
  border: none;
  outline: none;

  background-color: transparent;
}
.quantity {
  width: 76px;
  height: 18px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e5da3;
  font-weight: 600;
}
.viewQuantitybox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  border-radius: 8px;
  padding: 4px 2px;
  background-color: #dcdcdc;
  /* border: 1px solid #1a73e8; */
}
.delete {
  cursor: pointer;
  background-color: #dcdcdc;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #fff;
  /* width: 45%; */
  box-shadow: 0px 4px 4px 0px #00000014;
  padding: 16px;
  /* width: 29.5%; */
}

.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  margin: 20px 0;
}

.showMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emptyCart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background-color: #fff;
  text-align: center;
}
