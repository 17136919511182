.password-input-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
}
.password-input-container > input {
  border: none !important;
  background-color: transparent;
}
.password-elements {
  height: 32px;
  width: 95%;
  outline: none !important;
  padding: 0px;
  color: #001f57 !important;
}
.password-elements ::placeholder {
  color: #020e35 !important;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
}
.toggle-password {
  height: 16px;
  width: 16px;
}
.eyeIcon {
  height: 20px;
  width: 20px;
  margin-right: 4px;
  color: #00276d;
}
