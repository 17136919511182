.containerSignUpLogin {
  position: relative;
  height: 100vh;
  width: calc(100vw - 40px); /* Adjusted width to account for padding */
  background-image: url("../../../../assets/images/BackgroundLoginSignUp.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding: 0 20px; /* Added left and right padding */
  width: 100%;
}
.horizontalLine {
  width: 100%;
  height: 1px;
  background-color: #eceeeb;
  position: absolute;
  top: 95px;
}

.head {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  align-self: center;
}

.logo {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 70px;
  height: auto;
  z-index: 10;
}

.header {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 30px;
}

.imageButton {
  width: 70px;
  height: auto;
  cursor: pointer;
  z-index: 10;
}

.registerHeading {
  margin-left: 15px;
  color: #0056b3;
  font-size: 24px;
  font-weight: 600;
  z-index: 10;
}

.roundButtonContainer {
  position: absolute;
  top: 170px;
  left: 90px;
  display: flex;
  flex-direction: column;
  background-color: #d9d9d970;
  width: 33px;
  height: 210px;
  border-radius: 25px;
  align-items: center;
}

.basicInfoStepForm {
  margin-left: 0;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.colorBlue {
  color: #00276d;
}

.colorOrange {
  color: #ff7631;
}

@media (max-width: 768px) {
  .basicInfoStepForm {
    margin-left: 0;
  }

  .roundButtonContainer {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 40px;
    padding: 5px;
    gap: 10px;
    margin: 0 10px 0 0;
    border-radius: 15px;
  }

  .roundButton {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .roundButton:first-child {
    margin-top: -10px;
  }

  .roundButton:not(:first-child) {
    margin-top: 15px;
  }
}

.socialInfoStepForm {
  margin-left: 190px;
}
.bankDetailsStepForm {
  margin-left: 190px;
}
.passwordStepForm {
  margin-left: 190px;
}
.roundButton {
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 25px;
  user-select: none;
}

.roundButton:first-child {
  margin-top: 15px;
}

.buttonLabels {
  position: absolute;
  top: 180px;
  left: 140px;
  display: flex;
  flex-direction: column;
}

.label {
  color: black;
  margin: 5px 0;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
}

.stepContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  margin-top: 180px;
  margin-bottom: 80px;
}

.continueButton {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 90px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10;
}

.continueButton:hover {
  background-color: #004494;
}

.activeButton {
  background-color: #0056b3;
  color: white;
}

.completedButton {
  background-color: green;
  color: white;
}

@media (max-width: 768px) {
  .continueButton {
    padding: 10px 60px;
    font-size: 14px;
  }

  .buttonLabels {
    top: 140px;
    left: 80px;
  }

  .label {
    font-size: 9px;
  }

  .roundButtonContainer {
    left: 50px;
    top: 120px;
  }

  .stepContent {
    margin-bottom: 100px;
  }
}
.stepContentPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 40px;
  max-height: 70%;
}
@media (max-width: 768px) {
  .continueButton {
    padding: 10px 60px;
    font-size: 14px;
  }

  .buttonLabels {
    top: 140px;
    left: 80px;
    display: none;
  }

  .label {
    font-size: 9px;
  }

  .roundButtonContainer {
    left: 50px;
    top: 120px;
  }

  .stepContent {
    margin-bottom: 100px;
  }
}

@media (min-width: 769px) and (max-width: 1358px) {
  .roundButtonContainer {
    flex-direction: row;
    height: auto;
    margin-left: 70px;
    top: 107px;
    max-width: 400px;
    width: 34%;
    min-width: 400px;
  }

  .buttonLabels {
    flex-direction: row;
    margin-left: 10px;
    top: 150px;
    align-items: center;
  }

  .roundButton {
    margin-bottom: 0;
    margin-right: 50px;
    margin-left: 20px;
    width: 29px;
    height: 29px;
  }

  .label {
    margin: 0 17px;
    font-size: 12px;
  }

  .roundButton:first-child {
    margin-top: 1px;
  }
}
