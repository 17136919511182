.navIMGs {
  position: relative;
  width: 100%;
  height: 350px;
  padding: 0;
  background: linear-gradient(90deg, #0e54a3 0%, #417ec4 100%);
}

.leftImage {
  max-width: 346.5px;
  max-height: 160px;
  width: auto;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 35%;
  right: 6%;
  z-index: 10;
}
.flxcenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;
  position: absolute;
  top: 30%;
  align-self: center;
  z-index: 1000;
  width: 100%;
}

.headingpathOrange {
  color: #ff7631;
  font-size: 16px;
  font-weight: 600;
}

.headingpath {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}

.fs48 {
  font-size: 48px;
}

.two_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0 20px;
  text-align: center;
  white-space: normal;
}

.responsiveContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
  flex-wrap: wrap;
  max-width: 100%;
  word-break: break-word;
  color: white;
  margin-top: 40px;
}

.navIMG {
  width: 100%;
  /* Makes the image fill the width */
  height: 100%;
  /* Makes the image fill the height */
  object-fit: cover;
  /* Ensures the image covers the container area */
  /* position: absolute;
  top: 0;
  left: 0;
  z-index: -1; */
  background-position: center;
}

@media (min-width: 320px) and (max-width: 480px) {
  .headingpathOrange {
    color: #ff7631;
    font-size: 8px;
    font-weight: 600;
  }
  .leftImage {
    width: 20%;
    height: 15%;
  }
  .navIMGs {
    position: relative;
    width: 100%;
    height: 230px;
  }
  .headingpath {
    color: white;
    font-size: 8px;
    font-weight: 600;
  }
  .fs48 {
    font-size: 20px;
  }

  .navIMG {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .headingpathOrange {
    color: #ff7631;
    font-size: 12px;
    font-weight: 600;
  }
  .leftImage {
    width: 25%;
    height: 25%;
  }
  .headingpath {
    color: white;
    font-size: 12px;
    font-weight: 600;
  }

  .fs48 {
    font-size: 34px;
  }
  .navIMGs {
    position: relative;
    width: 100%;
    height: 280px;
  }
  .navIMG {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
  }


}
@media (min-width: 769px) and (max-width: 1200px) {
  .fs48 {
    font-size: 48px;
  }
  .leftImage {
    width: 25%;
    height: 25%;
  }
}
