.imgRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.twoImagesRow .imgContainer {
  width: 49%;
}

.threeImagesRow .imgContainer {
  width: 32%;
}

.imgContainer {
  position: relative;
  height: 235px;
  width: 544px !important;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
}

.displayimg {
  object-fit: fill;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 8px;
}

.dotsContainer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s;
}

.activeDot {
  background-color: rgba(0, 0, 0, 0.8);
}

.viewMoreContainer {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}

.viewMoreText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  cursor: pointer;

  /* font-weight: bold; */
}

.viewMoreImg {
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.viewMoreImg:hover {
  opacity: 0.8;
}
.iconRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 9px;
  width: 100%;
}

.iconCard {
  display: flex;

  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.iconContainer {
  width: 32px;
  height: 32px;
  padding: 5px 2px 4px 2px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.9);
}

.iconContainer svg {
  width: 24px;
  height: 24px;
  color: #00276d;
}

.iconName {
  margin-top: 8px;
  margin: 15px;
  font-size: 14px;
  text-align: center;
  color: #00276d;
  white-space: wrap;
  text-overflow: ellipsis;
}
.placesContainer {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.placeItem {
  display: flex;
  justify-content: space-between;
}

.placeName {
  color: #00276d;
  font-size: 14px;
  line-height: 0.1;
}

.placeValue {
  align-self: flex-end;
  color: #888;
  color: #00276d;
}
