.imge3 {
  height: 24px;
  width: 24px;
  margin-bottom: 12px;
  cursor: pointer;
}
.imageTable {
  height: 24px;
  width: 24px;
}

.border {
  border-bottom: 1px solid #6f6f72;
  margin-top: 8px;
}

.end {
  display: flex;
  justify-content: end;
  margin-left: auto;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.text {
  color: #666666;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  width: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: "flex";
  align-items: end;
  margin: "0 30px  0 10px";
}
/* StyledImagePicker.module.css */

.wrapper {
  padding: 10px;
  border-radius: 8px;
  max-width: 200px;
  margin: 0 auto;
}

.disabled {
  cursor: not-allowed;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.placeholderWrapper {
  width: 80%;
  overflow: hidden;
}

.placeholderText {
  font-size: 11px;
  font-style: italic;
  color: #00276d;
  text-align: start;
  max-height: 19px;
  overflow: hidden;
}

.buttonWrapper {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.buttonImage {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.imagePreview {
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
  border-radius: 4px;
}
.underline {
  width: 100%;
  border-bottom: 1px solid #686868;
}
.wrapperProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainerProfile {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 50%;
  overflow: hidden;
}

.imagePreviewProfile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholderTextProfile {
  font-size: 14px;
  color: #888;
}

.underlineProfile {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 8px;
}
