.container {
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .buttonRow {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .backButton {
    background: #cccccc;
    color: #ffffff;
    width: 120px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    height: 40px;
    border: none;
    font-weight: 600;
    box-shadow: 4px 4px 12px 0px #00000040;
  }
  
  .nextButton {
    background: #0e54a3;
    color: #ffffff;
    width: 120px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    height: 40px;
    border: none;
    font-weight: 600;
    box-shadow: 4px 4px 12px 0px #00000040;
  }
  .progressBarContainer {
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;
  }
  
  .progressBar {
    height: 100%;
    background-color: #FF9500; 
    transition: width 0.3s ease-in-out;
  }
  