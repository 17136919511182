.customTooltip {
  border-radius: 8px;
  box-shadow: 2px 2px 4px gray;
  background-color: #00276d;
  color: white;
  padding: 20px !important;
}

.flx {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
}
.value {
  font-size: 14px;
  color: white;
  font-weight: 600;
}
.date {
  border-bottom: 2px solid #e3e3e3;
  text-align: center;
  padding: 5px;
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 600;
}
.iconss2 {
  color: #0b7328;
  margin: 0 10px 0 30px;
  height: 25px;
  width: 25px;
}
.iconss {
  color: #00276d;
  margin: 0 10px 0 0px;
  height: 25px;
  width: 25px;
}
.main {
  margin-bottom: 16px;
  padding: 0 40px;
}
.Appointments {
  font-size: 20px;
  font-weight: 600;
  color: #00276d;
}
.Patients {
  color: rgba(0, 39, 109, 0.4);
  font-size: 20px;
  font-weight: 700;
}
.Current {
  font-size: 14px;
  font-weight: 600;
  color: #00276d;
}
.Previous {
  font-size: 14px;
  font-weight: 600;
  color: #00276d;
}
.flx {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .Patients {
    color: rgba(0, 39, 109, 0.4);
    font-size: 12px;
    font-weight: 700;
  }
  .flx {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }
  .main {
    margin-bottom: 16px;
    padding: 0 20px;
  }
  .Appointments {
    font-size: 12px;
    font-weight: 600;
    color: #00276d;
  }
  .Current {
    font-size: 10px;
    font-weight: 600;
    color: #00276d;
  }
  .Previous {
    font-size: 10px;
    font-weight: 600;
    color: #00276d;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .Current {
    font-size: 12px;
    font-weight: 600;
    color: #00276d;
  }
  .Previous {
    font-size: 12px;
    font-weight: 600;
    color: #00276d;
  }
  .Patients {
    color: rgba(0, 39, 109, 0.4);
    font-size: 14px;
    font-weight: 700;
    margin: 0 24px;
  }
  .main {
    margin-bottom: 16px;
    padding: 0 20px;
  }
  .Appointments {
    font-size: 14px;
    font-weight: 600;
    color: #00276d;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .Current {
    font-size: 12px;
    font-weight: 600;
    color: #00276d;
  }
  .Previous {
    font-size: 12px;
    font-weight: 600;
    color: #00276d;
  }
  .Patients {
    color: rgba(0, 39, 109, 0.4);
    font-size: 14px;
    font-weight: 700;
    margin: 0 24px;
  }
  .main {
    margin-bottom: 16px;
    padding: 0 20px;
  }
  .Appointments {
    font-size: 14px;
    font-weight: 600;
    color: #00276d;
  }
  .flx {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
}
