.ccard {
  background-color: white;
  margin-top: 32px;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  margin-right: 32px;
  color: #00276d;
  width: 30%;
  box-sizing: border-box;
  /*  */
}

.ShortImage {
  display: flex;
}
.ShortImageone {
  width: 30%;
}
.bttm {
  height: 25px;
  width: 25px;
  color: white;
}
.reset {
  height: 40px;
  width: 40px;
  margin-left: 16px;
  background-color: #0b7328;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
/* .delete {
  height: 40px;
  width: 40px;
  margin-left: 16px;
  color: #00276d !important;
  cursor: pointer;
  background-color: #ff7631;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
} */
.Delete {
  cursor: pointer;
  height: 26px;
  width: 26px;
  color: #00276d;
  padding: 10px;
}
.Delete:hover {
  background-color: #f9a035;
  border-radius: 8px;
}
.Donation1 {
  height: 100px;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
}
.Donation2 {
  height: 50px;
  width: 47%;
  border-radius: 8px;
  margin-top: 16px;
  box-sizing: border-box;
}
.Donation3 {
  height: 50px;
  width: 47%;
  border-radius: 8px;
  margin-top: 16px;
  box-sizing: border-box;
}
.DDonation1 {
  cursor: pointer;
  height: 150px;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}
.DDonation2 {
  cursor: pointer;
  object-fit: fill;

  height: 100px;
  width: 48%;
  border-radius: 8px;
  margin-top: 16px;
}
.DDonation3 {
  cursor: pointer;
  object-fit: fill;

  height: 100px;
  width: 48%;
  border-radius: 8px;
  margin-top: 16px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt32 {
  margin-top: 32px;
}
.mr50 {
  margin-right: 50px;
}
.btnbottom {
  display: flex;
  margin: 56px auto;
  width: 210px;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: start;
}
.w30 {
  width: 33%;
}
.ViewDetails {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.ViewDetails > p:hover {
  border-bottom: 1px solid #00276d;
  cursor: pointer;
}
.flxWrap {
  display: flex;
  flex-wrap: wrap;
  scroll-behavior: auto;
}
.back {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}
.quotions {
  height: 115px;
  overflow: hidden;
}
.modelcontainer {
  width: 400px;
  height: fit-content;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 16px;
}

@media (max-width: 600px) {
  .modelcontainer {
    padding: 12px;
    width: 350px;
  }
}
@media (max-width: 600px) {
  .AddCard,
  .OrphansCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.modelHeader {
  background-color: #edf4fc;
  color: #00276d;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}
.mt32 {
  margin-top: 32px;
}
.close {
  margin-left: auto;
  cursor: pointer;
}
.ImgPicker {
  display: grid;
  place-items: center;
  margin: auto;
  border: 2px dashed #00276d;
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.loadingOverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  color: black;
  font-size: 16px;
  z-index: 1;
}
.description > textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  height: 80px;
  font-size: 16px;
  border: 2px solid #00276d;
  border-radius: 16px;
}
/* Category */
.flxWrap {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.flxWrap::-webkit-scrollbar {
  display: none;
}

.OrphansCard {
  width: 29%;
  box-sizing: border-box;
  border-radius: 16px;
  height: 140px;
  margin-right: 16px;
  margin-top: 32px;

  padding: 5px;
  box-sizing: border-box;
}
.OrphansCard.checked {
  border: 2px solid #099bed;
}
.HealthCard {
  width: 29%;
  box-sizing: border-box;
  border-radius: 16px;
  height: 140px;
  margin-right: 16px;
  margin-top: 32px;
  background-image: url("../../../assets/images/HealthDonation.png");
  background-size: cover;
  padding: 5px;
}
.NeendCard {
  width: 29%;
  box-sizing: border-box;
  border-radius: 16px;
  height: 140px;
  margin-right: 16px;
  margin-top: 32px;
  background-image: url("../../../assets/images/Needed.png");
  background-size: cover;
  padding: 5px;
}
.alignend {
  color: white;
  font-weight: 700;
  margin-left: 16px;
  text-align: right;
  text-shadow: 2px 2px 0 #ff7631, -2px -2px 0 #ff7631, 2px -2px 0 #ff7631,
    -2px 2px 0 #ff7631, 0 0 5px rgba(0, 0, 0, 0.3);
}
.checkBox {
  display: flex;
  justify-content: end;
  color: white;
}
.AddCard {
  border: 2px dashed #00276d;
  width: 29%;
  box-sizing: border-box;
  border-radius: 16px;
  height: 140px;
  margin-right: 16px;
  margin-top: 32px;
  /* padding: 10px; */
  color: #00276d;
  text-align: center;
  cursor: pointer;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #13a89e;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}

.AddIcon {
  height: 40px;
  width: 40px;
  display: flex;
  margin: 16px auto;
}
.AddCriteria {
  height: 40px;
}
.imgouter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.empty {
  width: 256px;
  height: 188.73px;
}
.end {
  margin-left: auto;
  cursor: pointer;
}
.mb32 {
  margin-bottom: 32px;
}
.endd > select {
  background-color: #00276d;
  padding: 10px 16px;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-right: 16px;
}
.colorWhite {
  color: white;
  margin-right: 16px;
}
.imgPick {
  width: 45%;
  height: 100px;
  border-radius: 8px;
  margin-top: 16px;
}
.alginend {
  display: flex;
  justify-content: end;
}
.empty3 {
  width: 256px;
  height: 210px;
  display: flex;
  justify-content: center;
  margin: auto;
}
