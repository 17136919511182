.customSelect {
  color: #393a44;
  font-size: 22px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 5px;
  width: 200px;
}
.customSelect:focus {
  border: none;
  outline: none;
}
.customOption {
  color: #393a44;
  font-size: 16px;
  font-weight: 500;
}
.mainOuter {
  width: 100%;
  padding: 24px 40px 0px 0;
  box-sizing: border-box;
}
.Navouter {
  position: sticky;
  z-index: 1000;
  top: 0%;
}
.tableData {
  overflow: auto;
  height: 60vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
  font-family: "Poppins", sans-serif;
}
.wapper > tr {
  color: #393a44 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px,
    rgba(136, 134, 134, 0.23) 4px 3px 10px;
  transform: scale(1);
  color: #0d47a1 !important;
  background-color: white !important;
}
.w20 {
  width: 16.66% !important;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}
.headerclass {
  width: 16.66% !important;
  font-family: "Poppins", sans-serif !important;
  padding: 15px;
  font-size: 14px;
  text-align: center;
}
.w20F {
  width: 14.28% !important;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}
.headerclassF {
  width: 14.28% !important;
  font-family: "Poppins", sans-serif !important;
  padding: 15px;
  font-size: 14px;
  text-align: center;
}
.headerOuter {
  display: flex;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  background-color: white;
  color: #393a44;
  border-radius: 14px 14px 0 0;
  position: sticky !important;
  top: 115px;
  font-family: "Poppins", sans-serif !important;
}
.payment {
  margin-top: 24px;
}

.statusComp {
  color: #393a44;
  background-color: #00b69b;
  border-radius: 8px;
  padding: 8px;
  width: 95px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.statusProcessingText {
  color: #393a44;
  background-color: #fdcb2e;
  border-radius: 8px;
  padding: 8px;
  width: 95px;
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
}
.statusPendingText {
  color: #393a44;
  background-color: #fd5650;
  border-radius: 8px;
  padding: 8px;
  width: 95px;
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
}
.mt24 {
  margin-top: 24px;
}
.selectedOption {
  color: #0d47a1;
  font-weight: 600;
}
.colorGray {
  color: #909198;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerBody > ul {
  margin-left: 16px;
}
.headerBody > ul > li {
  margin-top: 15px;
}
.plane {
  height: 13px;
  width: 23px;
  display: flex;
  margin: auto;
}
.lines {
  border-top: 2px dotted #0d47a1;
  width: 150px;
}
.DetailCard {
  background-color: white;
  border-radius: 16px;
  width: 497px;
  color: #393a44;
  height: 260px;
}
.DetailCardVendor {
  background-color: white;
  border-radius: 16px;
  width: 485px;
  color: #393a44;
  height: 260px;
}
.MessageCard {
  background-color: white;
  border-radius: 16px;
  width: 100%;
  color: #393a44;
}
.DetailCard2 {
  background-color: white;
  border-radius: 16px;
  width: 100%;
  color: #0d47a1;
  height: auto;
}
.DetailCard3 {
  background-color: white;
  border-radius: 16px;
  width: 66%;
  color: #0d47a1;
  height: 260px;
}
.colorBlue {
  color: #0d47a1;
}
.mt16 {
  margin-top: 16px;
}
.headerCard {
  padding: 10px 32px;
  color: #393a44;
  border-bottom: 1px solid #979797;
}
.headerBody {
  padding: 24px 32px;
  color: #393a44;
}
.messageBody {
  padding: 24px 32px;
  color: #393a44;
}
.mt8 {
  margin-top: 8px;
}
.flx {
  display: flex;
  align-items: start;
}
.colorBlue {
  color: #0d47a1;
  cursor: pointer;
}
.ARROW {
  margin-left: 16px;
  font-size: 20px;
}
.flY {
  width: 50px;
  height: 40px;
}
.textCenter {
  text-align: center;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #393a44;
}
.filter {
  margin-top: 24px;
  width: max-content;
}
.w5 {
  width: 1% !important;
}
.headerclassCheck {
  padding: 15px;
  width: 2%;
}
.imgs {
  border-radius: 16px;
  width: 32%;
  height: 160px;
  margin-right: 24px;
}
.btn {
  background-color: #00b69b;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  height: 42px;
  padding: 5px 15px;
  border: 1px solid #00b69b;
  cursor: pointer;
}
