.displayimg {
  object-fit: cover;
  width: 300px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.list {
  list-style: none;
  padding: 0;
}
.containerFormRentACar {
  width: 500px;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.rightcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.item {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: bold;
  color: #0e54a3;
  width: 100%;
  text-align: start;
  margin: 0 10px;
}

.value {
  font-weight: normal;
  color: gray;
  width: 100%;
  text-align: end;
  white-space: nowrap;
}
.textmainbox {
  width: 100%;
  display: flex;
  text-align: center;
  white-space: nowrap;
}
.bookingbuttoncontainer {
  display: flex;
  gap: 1.5rem;
  margin: 10px 0;
}

.bookingbutton {
  background-color: #fff;
  border: 1px solid #0e54a3;
  border-radius: 16px;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #0e54a3;
  cursor: pointer;
}

.bookingbutton.active {
  background-color: #0e54a3;
  color: #fff;
  border-color: #0056b3;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.carform {
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  box-shadow: 0px 4px 4px 0px #00000014;
  background: #fff;
  padding: 16px;
}

.checkBoxx {
  margin-right: 16px !important;
  color: #0e54a3;
}
.checkboxcontainer {
  display: flex;
  color: #0e54a3;
  margin-top: 10px;
}
.samelocationmain {
  display: flex;
  justify-content: space-between;
  color: #0e54a3;
  font-weight: bold;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0e54a3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0e54a3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.marginTopRentACar {
  margin-bottom: 30px;
}
