.tableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
  /* margin: 0 16px; */
}
.tableContainer::-webkit-scrollbar {
  display: none;
}
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #7d7d7d;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left !important;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  /* background-color: #e0e0e0; */
}
thead {
  position: sticky;
  top: 0;
  background: white; /* ya koi bhi background jo header ko visible rakhe */
  z-index: 10; /* Ensure it's above other content */
}
/* thead tr:hover {
  background-color: #e0e0e0;
  color: #000;
} */
thead tr:hover {
  background-color: transparent !important;
}
tbody td {
  text-align: left !important;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  color: #454545;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  /* line-height: 14px; */
  /* letter-spacing: -0.25px; */
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  white-space: normal;
  overflow: visible;
  overflow-wrap: anywhere;
  word-break: break-word;
}

tbody tr:hover {
  background-color: #f1f1f1;
  color: #000;
}

th,
td {
  width: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.actions {
  display: flex;
  gap: 8px;
}

@media (max-width: 768px) {
  table {
    width: 100%;
  }

  thead th {
    font-size: 14px;
  }

  tbody td {
    font-size: 13px;
  }
}
