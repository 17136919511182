.filterImage {
  cursor: pointer;
  height: 16px;
  width: 16px;
}
.filterText {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.ModalContent {
  width: 800px;
  background-color: white;
  padding: 24px;
  position: absolute;
  top: 50%;
  border-radius: 24px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: none !important;
}
.mdlwrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #7d7d7d;
  padding-bottom: 16px;
}
.headingModel {
  color: #131313;
  font-weight: 600;
  font-size: 14px;
  line-height: 42px;
  letter-spacing: -0.75px;
}
.w50 {
  width: calc(50% - 9px);
}
.Apply {
  height: 48px;
  background-color: #0e54a3;
  border: 1px solid #0e54a3;
  width: 160px;
  cursor: pointer;
  color: white;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  text-align: center;
}
.Reset {
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  text-align: center;
  width: 160px;
  height: 48px;
  background-color: white;
  border: 1px solid #0e54a3;
  color: #0e54a3;
  cursor: pointer;
}
.flxbtwen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.modelHeading {
  font-weight: 700;
  font-size: 16px;
  line-height: 42px;
  letter-spacing: -0.75px;
  color: black;
}
.flxendfilter {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 18px;
}
.filterWrapper {
  box-shadow: 4px 4px 12px 0px #00000040;
  border: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  height: 40px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 20px;
}
.filterWrapper > .SearchInput {
  border: none;
  background-color: transparent;
  outline: none;
  height: 40px;
  padding: 0%;
}

.flxEnd {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 18px;
  margin-bottom: 24px;
}
.SearchIcon {
  font-size: 24px;
  color: #7d7d7d;
}

@media (max-width: 480px) {
  .ModalContent {
    width: 100%;
  }
}
