.HospitalSearch > input {
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 16px;
  font-weight: 500px;
  color: #00276d;
  margin-bottom: 16px;
}
.HospitalSearch > input :focus {
  border: none;
}
.HospitalSearch > input::placeholder {
  color: #a2a3a4;
}
.HospitalSearch {
  display: flex;
  border-bottom: 2px solid #00276d;
}
.hospitalcard {
  padding: 8px;
  border-bottom: 1px solid #e0e9f4;
}
.avatar {
  height: 48px;
  width: 48px;
  margin-right: 24px;
}
.Checkbox {
  border-radius: 25%;
  color: green;
}
.end {
  margin-left: auto;
}
.mt16 {
  margin-top: 16px;
}
.hospitalNameCard {
  padding: 5px;
  background-color: green;
  color: white;
  border-radius: 8px;
  display: flex;
  margin-right: 16px;
  margin-top: 8px;
}
.flxWrap {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.closeouter {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #00276d;
  position: relative;
  bottom: 15px;
  box-shadow: 1px 1px 2px 1px gray;
  left: 8px;
}
.closeIcon {
  color: #00276d;
}
.error {
  color: red;
}
.close {
  justify-content: end;
  margin-left: auto;
}

.close {
  justify-content: end;
  margin-left: auto;
}
