.outer {
  padding: 32px 160px 0px 160px;
}
.pending {
  width: 100%;
  height: 85vh;
}
.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}
.flexText {
  display: flex;
  align-items: center;
  gap: 6px;
}
.mianheading22 {
  text-align: center;
  margin-top: 40px;
  color: #ff7631;
}
.container {
  max-width: 1200px;
  margin: 0 auto 56px auto;
}
.ConsultancyBox {
  width: 100%;
  border-radius: 32px;
  background-color: #edf3f6;
  padding: 40px 40px;
  text-align: center;
  box-sizing: border-box;
}
.inputs {
  width: 100%;
  border-radius: 30px;
  padding: 10px 25px;
  box-sizing: border-box;
  background-color: white;
  border: none;
  outline: none;
}
.select {
  width: 100%;
  border-radius: 30px;
  padding: 10px 25px;
  box-sizing: border-box;
  background-color: white;
  border: none;
  outline: none;
}

.iconnns {
  color: #ff7631;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.textcenter {
  text-align: center;
}
.labels {
  text-align: start;
  color: #2d3663bf;
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
}
.colorGray {
  color: #2d3663bf;
}
.textaera {
  width: 100%;
  border-radius: 30px;
  padding: 20px 25px;
  box-sizing: border-box;
  background-color: white;
  border: none;
  outline: none;
  height: 230px;
  resize: none;
}
.btnSubmit {
  background-color: #ff7631;
  color: #0e54a3;
  width: 290px;
  height: 60px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  margin-top: 32px;
}
.mianheading {
  text-align: center;
  margin-top: 40px;
  color: white;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.colorBlue {
  color: #0e54a3;
}
.textcolor {
  color: #62688b;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.colorOrange {
  color: #ff7631;
}
.flx {
  display: flex;
  align-items: center;
  gap: 100px;
  justify-content: space-between;
  margin: 32px 0px;
}
.flexText {
  display: flex;
  align-items: center;
  gap: 6px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .ConsultancyBox {
    width: 100%;
    border-radius: 32px;
    background-color: #edf3f6;
    padding: 32px;
    text-align: center;
    box-sizing: border-box;
  }
  .container {
    padding: 20px !important;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .container {
    padding: 30px 60px 30px 60px !important;
  }
  .ConsultancyBox {
    width: 100%;
    border-radius: 32px;
    background-color: #edf3f6;
    padding: 40px;
    text-align: center;
    box-sizing: border-box;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .container {
    padding: 50px 80px 50px 80px !important;
  }
  .ConsultancyBox {
    width: 100%;
    border-radius: 32px;
    background-color: #edf3f6;
    padding: 40px;
    text-align: center;
    box-sizing: border-box;
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
  .container {
    padding: 50px 100px 50px 100px !important;
  }
  .ConsultancyBox {
    width: 100%;
    border-radius: 32px;
    background-color: #edf3f6;
    padding: 40px 80px;
    text-align: center;
    box-sizing: border-box;
  }
}
@media (min-width: 769px) and (max-width: 2800px) {
}
