.container {
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.logosSlide {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  animation: scroll 32s linear infinite;
  animation-play-state: running;
}

.container:hover .logosSlide {
  animation-play-state: paused;
}

.logoImage {
  height: 60px;
  width: auto;
  margin: 0 48px;
  object-fit: contain;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
