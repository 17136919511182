.tableData {
  overflow: auto;
  height: 65vh;
}
.tableData::-webkit-scrollbar {
  display: none;
}
.tableData::-webkit-scrollbar-track {
  display: none;
}

.tableData::-webkit-scrollbar-thumb {
  display: none;
}

.tableData::-webkit-scrollbar-thumb:hover {
  display: none;
}
.tableRow {
  width: 100%;
}
.tableRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  color: #000000 !important;
  background-color: white !important;
  font-family: "Poppins", sans-serif;
}
.wapper > tr {
  color: #393a44 !important;
  border: 1px solid #d8e1ec;
  font-size: 14px;
  font-weight: 500;
  background-color: white !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.wapper > tr:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px,
    rgba(136, 134, 134, 0.23) 4px 3px 10px;
  transform: scale(1);
  color: #0d47a1 !important;
  background-color: white !important;
}
.w20 {
  width: 25% !important;
  font-family: "Poppins", sans-serif !important;
}
.headerclass {
  width: 25% !important;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  padding: 15px;
  font-size: 14px;
}
.headerOuter {
  display: flex;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  background-color: white;
  color: #393a44;
  border-radius: 14px 14px 0 0;
  position: sticky !important;
  top: 115px;
  font-family: "Poppins", sans-serif !important;
}
.mainOuter {
  width: 100%;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;
}
.block {
  width: 76px;
  height: 36px;
  outline: none;
  background-color: #e22c54;
  color: white;
  border-radius: 8px;
  border: 2px solid #e22c54;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.unblock {
  width: 100px;
  height: 36px;
  outline: none;
  background-color: #10ab7d;
  color: white;
  border-radius: 8px;
  border: 2px solid #10ab7d;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customerCard {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #909198;
  background-color: white;
}
.DetailCard {
  background-color: white;
  border-radius: 16px;
  width: 100%;
  margin-right: 24px;
  color: #393a44;
  border: 1px solid #b9bac2;
}
.mt24 {
  margin-top: 24px;
}
.mt16 {
  margin-top: 16px;
}
.headerCard {
  padding: 10px 32px;
  color: #393a44;
  border-bottom: 1px solid #949494;
}
.headerBody {
  padding: 24px 32px;
  color: #393a44;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
