.tabContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
  color: #2b6cb0;
  font-size: 16px;
  font-weight: 500;
  margin: 5px;
  border: none;
  background-color: #f0f0f0;
}

.activeTab {
  background-color: #007bff;
  color: white;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  width: 256px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgba(14, 84, 163, 1) !important;
  background-color: #ffffff;
  position: relative;
  margin-top: 24px;
}

.searchInput {
  border: none;
  outline: none;
  background-color: transparent;
  color: #0e54a3 !important;
}

.searchIcon {
  color: #0e54a3;
  font-size: 30px;
  margin-left: 16px;
}

.rightAlign {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  color: #0e54a3;
  font-size: 16px;
  font-weight: 600;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 101%;
  padding: 16px;
  box-sizing: border-box;
}

.cardWrapper {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: transform 0.2s ease-in-out;
}

.cardWrapper:hover {
  transform: scale(1.02);
}

.cardBody {
  cursor: pointer;
}

.cardDetails,
.rightAlign {
  margin-top: 8px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cardContainer {
    grid-template-columns: 1fr;
  }
}

.cardDetails {
  display: flex;
  justify-content: space-between;
  color: #909198;
}

.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.showMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .cardWrapper {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .cardWrapper {
    flex: 1 100%;
  }
}
.viewCardContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 25%;
}

.viewCardPrice {
  font-size: 24px;
  color: #1a73e8;
  text-align: right;
}

.viewPricePerPack {
  font-size: 14px;
  color: #888;
}

.viewCardQuantity {
  margin: 20px 0;
}

.viewCardQuantityTitle {
  font-size: 18px;
  color: #0e54a3;
  margin: 0;
}
.viewCardDosage {
  color: #0e54a3;
  font-size: 16px;
  text-align: right;
  font-weight: 600;
}

/* Main container styles */
.viewQuantitybox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  border-radius: 8px;
  padding: 2px;
  border: 1px solid #0e54a3;
}

.decrementButton,
.incrementButton {
  width: 40px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #0e54a3;
  border: none;
  outline: none;

  background-color: transparent;
}

.quantity {
  width: 76px;
  height: 18px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e5da3;
  font-weight: 600;
}

/* For hidden inner element, if necessary */
.hiddenInnerElement {
  position: absolute; /* Positioning for hidden element */
  width: 124px; /* Width as per requirement */
  height: 18px; /* Height as per requirement */
}

.viewQuantityControls span {
  font-size: 18px;
  color: #1a73e8;
}

.viewCardButtons {
  display: flex;
  justify-content: center;

  gap: 20px;
}

.viewCancelButton {
  border: 1px solid #0e54a3; /* Initial border color */
  border-radius: 10px; /* Removed duplicate radius */
  padding: 10px 20px;
  font-size: 14px;
  background-color: white;
  color: #0e54a3;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth hover effect */
}

.viewCancelButton:hover {
  background-color: #ff4d4f; /* Red background on hover */
  color: white; /* White text on hover */
  border-color: white; /* Only update the border color */
}

.viewSelectButton {
  border: 1px solid #0e54a3;
  border-radius: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  background-color: white;
  color: #0e54a3;
  cursor: pointer;
}

.viewSelectButton:hover {
  background-color: #0e54a3;
  color: white;
  border: 1px solid white;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other elements */
}

.modalContent {
  background: white;
  width: 390px;
  height: 311px;
  height: fit-content;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.1);
}

/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
  .modalContent {
    width: 50%; /* Adjust width for tablets */
    padding: 15px; /* Reduce padding for smaller screens */
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1); /* Smaller shadow */
  }
}

/* Media query for mobile phones */
@media (max-width: 480px) {
  .modalContent {
    width: 90%; /* Adjust width for mobile phones */
    padding: 10px; /* Reduce padding further */
    border-radius: 4px; /* Smaller border radius for mobile */
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1); /* Smaller shadow */
  }
}
.addnotification {
  width: 40.17%;
  display: flex;
  position: relative;
  top: -13px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #dcdcdc;
}
.uniquePagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.uniquePageButton {
  background-color: #0e54a3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.uniquePageButton:hover {
  background-color: #1f78c1;
}

.activeUniquePage {
  background-color: #1f78c1;
  font-weight: bold;
}
.viewCardTitle {
  font-size: 18px;
  font-weight: 500;
  color: #0e54a3;
}
