.back {
  height: 24px;
  width: 24px;
  margin-right: 16px;
  color: #00276d;
  cursor: pointer;
}
.mr56 {
  margin-right: 56px;
}
.travelcards {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  width: 300px;
  height: auto;
  box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.08);
  margin-top: 24px;
  box-sizing: border-box;
  border: 2px solid white;
  margin-right: 24px;
}
.travelcards:hover {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  width: 300px;
  height: auto;
  box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.08);
  margin-top: 24px;
  box-sizing: border-box;
  margin-right: 24px;
  border: 2px solid #00276d;
  cursor: pointer;
}
.textconatiner {
  height: 200px;
  overflow: hidden;
}
.textarea {
  margin-top: 16px;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.familyTravel {
  width: 254px;
  height: 145px;
  margin-top: 24px;
}
.SingleTravel {
  width: 254px;
  height: 145px;
  margin-top: 24px;
}
