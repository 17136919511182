.flx {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flxCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flxBetween {
  display: flex;
  justify-content: space-between;
}
.flxAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flxEvenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.flxCenter {
  display: flex;
  justify-content: center;
}
.flxStart {
  display: flex;
  justify-content: start;
}
.flxEnd {
  display: flex;
  justify-content: end;
}
.flxWrap {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.mt14 {
  margin-top: 10px;
  font-size: 15px;
}
.refresh {
  font-size: 22px;
  margin-left: 32px;
  cursor: pointer;
  color: #393a44;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
  color: red;
}

.underLine {
  text-decoration: underline;
  cursor: pointer;
}

/* /........... Colors .........../ */

.colorWhite {
  color: #ffffff;
}

.colorBlue {
  color: #00276d;
}

.colorOrange {
  color: #ff7631;
}
.colorlightBlue {
  color: #099bed;
}
.colorGreen {
  color: #0b7328;
}
.colorBlack {
  color: black;
}
.colorGray {
  color: #909198;
}

/* .......... Backgrounds .......... */
.btnOrange {
  background-color: #ff7631;
  border: 1px solid #ff7631;
}
.btngreen {
  background-color: #0b7328;
  border: 1px solid #0b7328;
}
.bgBlue {
  background-color: #0056b3;
  /* #00276d; */
}
.bgLightBlue {
  background-color: #1a3d7c;
}
.bgWhite {
  background-color: #fff;
}

.bgOrange {
  background-color: #ff7631;
}

.bgGreen {
  background-color: #0b7328;
}

/* .......... Columns ........... */
.col1 {
  width: 8.3333%;
}
.col2 {
  width: 16.6667%;
}
.col3 {
  width: 25%;
}
.col4 {
  width: 33.3333%;
}
.col5 {
  width: 41.6667%;
}
.col6 {
  width: 50%;
}
.col7 {
  width: 58.3333%;
}
.col8 {
  width: 66.6667%;
}
.col9 {
  width: 75%;
}
.col10 {
  width: 83.3333%;
}
.col11 {
  width: 91.6667%;
}
.col12 {
  width: 100%;
}

/* ........... Margins ..........*/

.m0 {
  margin: 0%;
}
.mb4 {
  margin-bottom: 4px;
}
.ml3 {
  margin-left: 10px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb24 {
  margin-bottom: 24px;
}

.mb20 {
  margin-bottom: 20px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb28 {
  margin-bottom: 28px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb64 {
  margin-bottom: 64px;
}
.mt100 {
  margin-top: 100px;
}
.mt24 {
  margin-top: 24px;
}
.mt10 {
  margin-top: 10px;
}
.mt88 {
  margin-top: 88px;
}
.mr8 {
  margin-right: 8px;
}
.mr16 {
  margin-right: 16px;
}
.mr24 {
  margin-right: 24px;
}
.mr32 {
  margin-right: 32px;
}
.mr40 {
  margin-right: 40px;
}
.mr48 {
  margin-right: 48px;
}
.mr112 {
  margin-left: 112px;
}
.mr87 {
  margin-right: 87px;
}
.mt40 {
  padding-top: 40px;
}
.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt50 {
  margin-top: 50px;
}
.mt24 {
  margin-top: 24px;
}
.mt56 {
  margin-top: 56px;
}
.mt67 {
  margin: 67px 0 28px 30px;
}
.mt32 {
  margin-top: 32px !important;
}

.mt76 {
  margin-top: 76px;
}
.mt72 {
  margin-top: 72px;
}

.mt28 {
  margin-top: 28px;
}
.m0 {
  margin: 0%;
}
.mr15 {
  margin-right: 15px;
}
.mr10 {
  margin-left: 25px;
}
.mr112 {
  margin-left: 112px;
}
.marginouter {
  padding: 40px 112px;
}

/* ........... Font-Size ..............*/

.wordspace13 {
  word-spacing: 13px;
}
.mt8 {
  font-size: 8px;
  font-family: "Poppins", sans-serif;
}
.fs10 {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
}

.fs12 {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.fs14 {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.fs15 {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.fs16 {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.fs13 {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}
.fs18 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.fs20 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.fs22 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}

.fs24 {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.fs28 {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
}

.fs32 {
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}
.fs27 {
  font-size: 27.5px;
  font-family: "Poppins", sans-serif;
}
.fs26 {
  font-size: 26.5px;
  font-family: "Poppins", sans-serif;
}

.fs36 {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
}
.fs40 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
}
.fs30 {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
}
.fs48 {
  font-size: 48px;
  font-family: "Poppins", sans-serif;
}

.fs54 {
  font-size: 54px;
  font-family: "Poppins", sans-serif;
}
.fs85 {
  font-size: 85px;
  font-family: "Poppins", sans-serif;
}
.fs56 {
  font-size: 56px;
  font-family: "Poppins", sans-serif;
}
.textJustify {
  text-align: justify;
  text-justify: inter-word;
}
.textCenter {
  text-align: center;
}
/* ........... Font-Family ............*/
@font-face {
  font-family: "Argent";
  src: url(../../fonts/Demo_Fonts/Fontspring-DEMO-argentcf-light.otf);
}
.Argent {
  font-family: "Argent";
}

@font-face {
  font-family: "Edwardian";
  src: url(../../fonts/edwardian-script-itc/EdwardianScriptITC.ttf);
  src: url(../../fonts/edwardian-script-itc/ITCEDSCR.TTF);
}
.Edwardian {
  font-family: "Edwardian";
}
.pr36 {
  padding-right: 36px;
}
.light {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300; /* Normal weight, equivalent to Regular */
}
* {
  font-family: "Poppins", sans-serif;
}
.regular {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400; /* Normal weight, equivalent to Regular */
}

.medium {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500; /* Normal weight, equivalent to Regular */
}

.semiBold {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600; /* Normal weight, equivalent to Regular */
}

.bold {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700; /* Normal weight, equivalent to Regular */
}

.lightItalic {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 300; /* Normal weight, equivalent to Regular */
}

.regularItalic {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 400; /* Normal weight, equivalent to Regular */
}

.mediumItalic {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 500; /* Normal weight, equivalent to Regular */
}

.semiBoldItalic {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 500; /* Normal weight, equivalent to Regular */
}

.boldItalic {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 500; /* Normal weight, equivalent to Regular */
}

.error {
  color: #fb2047;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400; /* Normal weight, equivalent to Regular */
}

.codeInputContainer {
  padding: 0;
  margin-top: 32px;
  text-align: center;
}
.codeInput {
  height: 40px;
  width: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  margin-right: 16px;
  text-align: center;
  color: #00276d !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.Verify {
  background-color: #f0f2f6;
  border: 1px solid #f0f2f6;
  padding: 10px 16px;
  border-radius: 8px;
  color: #999ca0;
  font-size: 16px;
  font-weight: 600;
  margin-left: 24px;
}

.VerifyActive {
  background-color: #00276d;
  border: 1px solid #00276d;
  padding: 10px 16px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-left: 24px;
}
.Verified {
  background-color: #288109;
  border: 1px solid #288109;
  padding: 10px 16px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-left: 24px;
}
/* ......... Media Quries ...........*/
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  min-height: 78vh;
  max-height: auto;
}
.outerContainer2 {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: auto;
}

.BtnWidth {
  width: 210px;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto 56px auto;
}

.MianOuter {
  padding: 100px 112px 0px 112px;
  box-sizing: border-box;
}
.loginVector {
  height: 70vh;
  width: 100%;
  margin-top: 50px;
  margin-left: 0px;
}
.LoginVector {
  height: 70vh;
  width: 100%;
  margin-top: 50px;
  margin-left: 0px;
}
.lockVector {
  width: 70%;
  height: 500px;
  margin-right: 100px;
  margin-top: 50px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .mb16 {
    margin-bottom: 0px;
  }
  .lockVector {
    height: 300px;
    width: 100%;
    margin-right: 0px;
  }
  .loginVector {
    height: 300px;
    width: 100%;
    margin-top: 50px;
    margin-left: 0px;
  }
  .LoginVector {
    height: 300px;
    width: 100%;
    margin-top: 50px;
    margin-left: 0px;
  }
  .MianOuter {
    padding: 24px;
    box-sizing: border-box;
  }
  .container {
    padding: 20px;
    margin: 0 auto;
  }
  .colsm1 {
    width: 8.3333%;
  }
  .colsm2 {
    width: 16.6667%;
  }
  .colsm3 {
    width: 25%;
  }
  .colsm4 {
    width: 33.3333%;
  }
  .colsm5 {
    width: 41.6667%;
  }
  .colsm6 {
    width: 50%;
  }
  .colsm7 {
    width: 58.3333%;
  }
  .colsm8 {
    width: 66.6667%;
  }
  .colsm9 {
    width: 75%;
  }
  .colsm10 {
    width: 83.3333%;
  }
  .colsm11 {
    width: 91.6667%;
  }
  .colsm12 {
    width: 100%;
  }
  .mr32 {
    margin-right: 0px;
  }
  .mb28 {
    margin-bottom: 0px;
  }
  .mtsm28 {
    margin-top: 16px;
  }
  .marginouter {
    padding: 20px 20px;
  }
  .mr87 {
    margin-right: 20px;
  }
  .mr112 {
    margin-left: 19px;
  }
  .flxWrap {
    display: flex;
    flex-wrap: wrap;
  }
}

.buttonWidth {
  width: 300px;
}

@media (min-width: 481px) and (max-width: 768px) {
  .lockVector {
    width: 300px;
    height: 330px;
    margin-right: 0px;
  }
  .loginVector {
    height: 300px;
    width: 100%;
    margin-top: 50px;
    margin-left: 50px;
  }
  .LoginVector {
    height: 300px;
    width: 100%;
    margin-top: 50px;
    margin-left: 50px;
  }
  .MianOuter {
    padding: 40px;
    box-sizing: border-box;
  }

  .loader {
    height: 400px;
    overflow: auto;
  }
  .container {
    padding: 20px 40px;
    margin: 0 auto;
  }
  .flxWrap {
    display: flex;
    flex-wrap: wrap;
  }
  .colmd1 {
    width: 8.3333%;
  }
  .col2 {
    width: 16.6667%;
  }
  .colmd3 {
    width: 25%;
  }
  .colmd4 {
    width: 33.3333%;
  }
  .colmd5 {
    width: 41.6667%;
  }
  .colmd6 {
    width: 50%;
  }
  .colmd7 {
    width: 58.3333%;
  }
  .colmd8 {
    width: 66.6667%;
  }
  .colmd9 {
    width: 75%;
  }
  .colmd10 {
    width: 83.3333%;
  }
  .colmd11 {
    width: 91.6667%;
  }
  .colmd12 {
    width: 100%;
  }
  .marginouter {
    padding: 30px 90px;
  }
  .mr87 {
    margin-right: 30px;
  }
  .mr112 {
    margin-left: 100px;
  }
}

@media (min-width: 769px) and (max-width: 1040px) {
  .mr87 {
    margin-right: 20px;
  }
  .container {
    padding: 20px 80px;
    margin: 0 auto;
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
  .mr87 {
    margin-right: 87px;
  }
}
