.maincontainer {
  padding: 24px 24px 24px 0px;
}
.textstyle {
  font-family: "Poppins", sans-serif; /* Font: Poppins */
  font-weight: 700; /* Weight: 700 */
  font-size: 24px; /* Size: 24px */
  line-height: 14px; /* Line height: 14px */
  letter-spacing: -0.25px; /* Letter spacing: -0.25px */
  vertical-align: top; /* Assuming 'Vertical trim' means aligning top */
}
.container {
  display: flex;
  align-items: center;
  gap: 32px;
  margin: 4px;
}
.dropdownWrapper {
  align-items: center;
  display: flex;
  position: relative;
  /* justify-content: center; */
  gap: 8px;
}
.dropdownContainer {
  position: absolute;
  top: 100%;
  z-index: 10;
  background: white;
  padding: 8px;
  width: 150px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* width: 1vw; */
}
.backButton {
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e4;
  text-align: center;
  display: flex;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.title {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-transform: capitalize;
}

.card {
  background-color: white;
  border-radius: 16px;
  /* margin: 10px 0; */
  /* width: 96%; */
  height: 143px;
  padding: 24px;
  margin-top: 32px;
}

.cardTitle {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-transform: capitalize;
  color: #000000;
}
.admin {
  border: 1px solid #7d7d7d;
  background-color: #7d7d7d !important;
  cursor: pointer;
  color: white !important;
  border-radius: 24px;
  height: 48px;
  font-size: 16px;
  width: 245px;
}
.blue {
  border: 1px solid #0e54a3;
  background-color: #0e54a3 !important;
  cursor: pointer;
  color: white !important;
  border-radius: 24px;
  height: 48px;
  font-size: 16px;
  width: 245px;
}
/* modalStyles.css */
.modalContent {
  padding: 16px;
  text-align: center;
}

.modalText {
  margin-bottom: 16px;
  line-height: 1.5;
  font-size: 16px;
}

.modalTextConfirm {
  margin-bottom: 16px;
  line-height: 1.5;
  font-size: 16px;
}

.modalButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.buttonCancel {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  width: 120px;
  height: 40px;
}

.buttonProceed {
  background-color: green;
  color: white;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 120px;
  height: 40px;
  cursor: pointer;
}
.heading {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: "from-font";
  text-decoration-skip-ink: none;
  color: #131313;
}
