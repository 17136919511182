.outer {
  height: 91vh;
}
.tabless {
  height: 350px;
  overflow: hidden;
  margin-bottom: 40px;
}
.flxbtween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.my32 {
  margin: 32px 0;
}
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #c9d8e2;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #13a89e;
}

.DoctorSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.DoctorSearch > input {
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 16px;
  font-weight: 500px;
  color: #00276d;
}
.DoctorSearch > input :focus {
  border: none;
}
.DoctorSearch > input::placeholder {
  color: #a2a3a4;
}
.DoctorSearch {
  border-bottom: 2px solid #00276d;
}
.close {
  color: #00276d;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.loader {
  height: 400px;
  overflow: auto;
}
.loader::-webkit-scrollbar {
  display: none;
}

/* Track */
.loader::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.loader::-webkit-scrollbar-thumb {
  display: none;
}

/* Handle on hover */
.loader::-webkit-scrollbar-thumb:hover {
  display: none;
}
.doctorcard {
  padding: 8px;
  border-bottom: 1px solid #e0e9f4;
}
.avatar {
  height: 48px;
  width: 48px;
  margin-right: 24px;
}
.end {
  margin-left: auto;
}
.mt16 {
  margin-top: 16px;
}
.closefinal {
  cursor: pointer;
  color: #00276d;
  font-size: 24px;
  margin-bottom: 16px;
}
.mt8 {
  margin-top: 8px;
}
.textcenter {
  text-align: center;
}
.mt24 {
  margin-top: 24px;
}
.mt32 {
  margin-top: 32px;
}
