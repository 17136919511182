.mainContainer {
  margin-top: 16px;
}
.customcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.text {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
  margin-top: 4px;
}
.button {
  width: 109px;
  height: 48px;
  border-radius: 24px;
  background-color: #0e54a3;
  box-shadow: 4px 4px 12px 0px #00000040;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  cursor: pointer;
}
.card {
  width: 100%;
  height: 208px;
  background-color: #fff;
  box-shadow: 4px 2px 12px 0px #00000040;
  border-radius: 16px;
  margin-top: 18px;
  overflow: hidden;
  display: flex;
  /* align-items: center; */
}
.imageContainer {
  width: 196px;
  object-fit: cover;
}
.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
}
.divcardContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.textHeader {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}
.locationText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #13a89e;
}
.rowcontent {
  display: flex;
  align-items: center;
  gap: 8px;
}
.textrow {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7d7d7d;
}
.footer {
  display: flex;
  flex-direction: column;
}
.footerText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}
.footerSubText {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7d7d7d;
}
.rowdisplay {
  display: flex;
  align-items: center;
  gap: 12px;
}
.bar {
  border-left: 1px solid;
  border-color: #7d7d7d;
  height: 24px;
}
.centerBar {
  border-left: 1px solid;
  border-color: #7d7d7d;
  height: 176px;
  align-self: center;
}
.end {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 469px;
  justify-content: space-between;
  
}
.feactureFont {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
.feacture {
  width: 150px;
  height: 34px;
  border-radius: 24px;
  box-shadow: 4px 4px 12px 0px #00000040;
  border: 0.87px solid;
  border-color: #0e54a3;
  align-items: center;
  justify-content: center;
  color: #0e54a3;
  font-size: 14px;
  display: flex;
  font-weight: 600;
}
.rowDetail {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.format {
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.detail{
    width: 98px;
    height: 34px;
    border-radius: 24px;
    background-color:#0E54A3 ;
    color: #fff;
    box-shadow: 4px 4px 12px 0px #00000040;
    align-items: center;
    justify-content: center;
    display: flex;
    align-self: flex-end;

}
.rowWay{
    gap: 8px;
    display: flex;
    flex-direction: column;
}
.spaceBetween{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 18px;
}
.modalContent {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.modalActions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 32px;
}

.acceptButton {
  flex: 1;
  padding: 10px;
  background-color: #0E54A3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 4px 4px 12px 0px #00000040;

}



.cancelButton {
  flex: 1;
  padding: 10px;
  background-color:#7D7D7D;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 4px 4px 12px 0px #00000040;
  border-radius: 24px;
}

.cancelButton:hover {
  background-color: #e53935;
}
.rowSearch{
  display: flex;
  align-items: center;
  gap: 24px;
  
}
.SearchInput {
  width: calc(100% - 30px); 
  padding-left: 30px; 
  box-sizing: border-box;
  z-index: 0;
}

.searchcontainer > input {
  border-radius: 8px;
  height: 38px;
  width: 233px;
  padding: 10px 10px 10px 10px;
  border: none;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f9f9fb;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px; */
}
.searchcontainer {
  border: 0.6px solid #d5d5d5;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.searchcontainer > input:focus {
  border: none;
  outline: none;
}
.searchcontainer > input::placeholder {
  color: #909198;
  font-size: 12px;
}
.searchcontainer {
  position: relative;
  width: auto;
  margin-left: 32px;
  border-radius: 8px;
}
.searchcontainer222 {
  position: relative;
  width: auto;
  margin-left: 0px;
  border-radius: 8px;
}
.searchfilter{
  background-color: #fff;
  width: 298px;
  height: 48px;
  border: 1px solid;
  border-radius: 24px;
  border-color: #F1F1F1;
  box-shadow: 4px 4px 12px 0px #00000040;

}
.SearchIcons {
  color: #d5d5d5;
  font-size: 20px;
  margin-right: 8px;
  cursor: pointer;
}
.searchcontainer > input:focus + .SearchIcons {
  color: #393a44;
}
.border {
  border-top: 0.6px solid #979797;
}
