.btnn {
  padding: 10px 15px;
  border-radius: 8px;
  margin-left: auto;
  font-weight: 500;
  color: #00276d;
  border: 3px solid #00276d;
}
.btnwidth {
  width: 210px;
}
.empty3 {
  width: 256px;
  height: 189px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.my32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.colorBlue {
  color: #00276d;
}
.mt16 {
  margin-top: 16px;
}
.outer {
  height: 91vh;
}
.tabless {
  height: 530px;
  overflow: hidden;
  margin-bottom: 40px;
}
.flxbtween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.my32 {
  margin: 32px 0;
}
.close {
  outline: none;
  font-size: 22px;
  background-color: transparent;
  color: gray;
  border: none;
}

.cancel {
  background-color: #00276d;
  border-radius: 8px;
  width: 110px;
  padding: 5px 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #00276d;
}
.mt32 {
  margin-top: 32px;
}
.btnDelete {
  background-color: red;
  border-radius: 8px;
  width: 110px;
  padding: 5px 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid red;
}

.back {
  margin-right: 24px;
  height: 24px;
  width: 24px;
  color: #00276d;
}

.buttoons {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttoons:hover {
  background-color: #f9a035;
  border-radius: 8px;
}
.reset {
  height: 25px;
  width: 25px;
  padding: 10px;
}
.mt8 {
  margin-top: 8px;
}

.end {
  margin-left: auto !important;
  display: flex;
  justify-content: end;
}
/* .center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
} */
.outerContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  height: 78vh;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #ffedd6;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.addIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.imgs {
  height: 36px;
  width: 36px;
}
.justifycenter {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}
.center {
  height: 20px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.Picss {
  margin: 0px 5px;
  width: 65px;
  height: 65px;
  object-fit: contain;
}
/* .................................... */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  display: flex;
  width: 342px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
}
.dltbtn {
  padding: 10px;
  color: #f5f5f5;
  background-color: red;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
}
.cancelbtn {
  padding: 10px;
  color: #f5f5f5;
  margin: 5px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #808080;
  cursor: pointer;
}
.closeicon {
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: rgb(163, 160, 160);
}
