.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 56px;
  max-width: 1200px;
  margin: 0 auto;
}
@media (min-width: 320px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 768px) {
}
@media (min-width: 769px) and (max-width: 1024) {
}
@media (min-width: 1025) and (max-width: 1440) {
}
