.label {
  display: block;
  font-size: 14px;
  color: #1e3a8a;
  margin-bottom: 4px;
}

.input {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.input:focus {
  border: 1px solid #d1d5db;
  outline: none;
}

.placeholder {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 8px;
  color: #1e3a8a;

  background-color: #f3f4f6;
  width: 100%;
  box-sizing: border-box;
}
.mainprogressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 500px;
}

.circleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.progressCircle1,
.progressCircle2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  background-color: #0e54a3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  z-index: 0;
}

.circleText {
  margin-top: 10px;
  color: #0e54a3;
}

.line {
  position: absolute;
  top: 30%;
  left: 25px;
  right: 25px;
  height: 3px;
  background-color: #b2bed3;
  z-index: -1;
}
.showMoreContainer {
  width: 100%;
  display: flex;
  justify-content: end;

  /* justify-content: center; */
  margin: 40px 10px 10px 0;
}

.showMoreButton {
  display: flex;

  align-items: center;
  padding: 10px 20px;
  background-color: #0e54a3;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.showMoreButton .icon {
  margin-left: 10px;
  font-size: 20px;
  color: #0e54a3;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label {
  display: block;
  font-size: 14px;
  color: #1e3a8a;
  margin-bottom: 4px;
}

.input {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.input:focus {
  border: 1px solid #d1d5db;
  outline: none;
}

.placeholder {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 8px;
  color: #1e3a8a;

  background-color: #f3f4f6;
  width: 100%;
  box-sizing: border-box;
}
.label {
    display: block;
    font-size: 14px;
    color: #1e3a8a;
    margin-bottom: 4px;
  }
  
  .input {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .input:focus {
    border: 1px solid #d1d5db; 
    outline: none;
  }
  
  .placeholder {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 8px;
    color: #1e3a8a;
  
    background-color: #f3f4f6;
    width: 100%;
    box-sizing: border-box;
  }
  