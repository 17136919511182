.CardConatiner {
  width: 100%;
  border-radius: 16px;
  background-color: white;
  height: 216px;
  margin-bottom: 24px;
  box-shadow: 4px 2px 12px 0px #00000040;
}
.ImgContainer {
  width: 20%;
  height: 216px;
}
.ImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px 0 0 16px;
}
.mainFlx {
  display: flex;
  align-items: start;
}
.cardBody {
  padding: 20px;
  display: flex;
  width: 80%;
  gap: 40px;
  align-items: start;
}
.FeatureWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 8px;
  align-items: center;
  justify-content: start; /* Align items to the start */
  margin-top: 12px;
  width: 60%;
}
.subheading {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #13a89e;
}
.FeatureCard {
  background-color: white;
  border-radius: 20px;
  height: 34px;
  border: 1px solid #0e54a3;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0e54a3;
}
.FeatureCard > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  text-align: center;
}
.heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}
.Edit {
  background-color: #0e54a3;
  color: white;
  height: 34px;
  width: 98px;
  box-shadow: 4px 4px 12px 0px #00000040;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  border: 2px solid #0e54a3;
}

.icon {
  height: 16px;
  width: 16px;
}
.flx {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}
.icontext {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}
.mt16 {
  margin-top: 16px;
}
.w30 {
  width: 25%;
  display: flex;
  justify-content: space-between;
  height: 176px;
  flex-direction: column;
}
.no {
  color: #131313;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.value {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #7d7d7d;
}

.Highlighted {
  background-color: #0e54a3 !important;
  color: white;
}
