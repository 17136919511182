.paretns {
  display: flex;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f9f9f9;
  height: auto;
}

.cursor {
  cursor: pointer;
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
}

.resendCode {
  color: #f0f0f0; /* Default color */
  transition: color 0.3s; /* Smooth transition */
}

.resendCode:hover {
  color: #00276d; /* Change to desired hover color */
  cursor: pointer; /* Change cursor to pointer */
}
.loginFormContainer {
  /* width: 55vh; */
  box-shadow: 0px 24.35px 75.27px 0px #00000025,
  0px 45.54px 140.79px 0px #0000001e, 0px 109px 337px 0px #00000015;
padding: 20px;
border-radius: 8px;
width: 64.25%;
/* height: 76.44%; */
max-height: 668px;
gap: 8px;
display: flex;
flex-direction: column;
/* justify-content: center; */
/* align-items: center; */
/* margin: 0 auto; */
background-color: #fff;
/* text-align: center; */
}
.imgBackgrond {
  background: linear-gradient(
    to bottom,
    #2303d1 0%,
    #2390d1 25%,
    #2659d2 50%,
    #2049d7 75%,
    #204e82 100%
  ) !important;
  height: 100vh !important;

  display: flex;
  justify-content: center;
  align-items: center;
}
.BackIcon {
  height: 14px;
  width: 14px;
  color: #00276d;
  cursor: pointer;
  margin-right: 8px;
}
.logo {
  width: 20%;
  height: 20%;
  object-fit: contain;
  margin-bottom: 24px;
}

.logoImage {
  width: 41vh;
  height: 41vh;
  height: auto;
  display: block;
  margin: 0 auto;
}

.mt24 {
  margin-top: 1.5rem;
}

.mt16 {
  margin-top: 1rem;
}

.mt50 {
  margin-top: 3rem;
}

.mt40 {
  margin-top: 2.5rem;
}

.mt100 {
  margin-top: 6.25rem;
}

.textCenter {
  text-align: center;
}

.cursor {
  cursor: pointer;
}

.flxBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flxCenter {
  display: flex;
  justify-content: center;
}

.checkBoxx {
  height: 1.25rem;
  width: 1.25rem;
  color: #a2968e;
}

@media (min-width: 1200px) {
  .loginFormContainer {
    width: 25rem;
  }

  .logoImage {
    max-width: 90%;
  }
}

@media (max-width: 1024px) {
  .loginFormContainer {
    width: 25rem;
  }
  .imgBackgrond {
    height: 90vh;
  }
  .logoImage {
    width: 25rem;
  }
}

@media (max-width: 768px) {
  .loginFormContainer {
    display: flex;
    justify-self: center;
  }
}
