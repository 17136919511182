.modal {
    position: fixed; /* Fixing the modal overlay to the viewport */
    top: 0;
    left: 0;
    /* top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)", */
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modalContent {
    position: relative;
    background: white;
    /* width: fit-content; */
    width: 860px;
    max-width: 70vw;
    height: fit-content;
    max-height: 90vh;
    padding: 10px;
    border-radius: 10px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.1);
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;
    padding: 24px 36px;
  }


.innerContent{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 32px ; */
    box-sizing: border-box;
}
.heading{
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    /* line-height: 14px; */
    letter-spacing: -0.25px;
    text-align: left;
    text-underline-position: "from-font";
    text-decoration-skip-ink: none;
    color:black;
  }
  .drname,
  .pmdc
  {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.25px;
    text-underline-position: "from-font";
    text-decoration-skip-ink: none;
    color: #131313;
    white-space: wrap;
  }
  .pmdc {
    color: #13a89e;
    font-size: 12px;
  }

  .featureconatiner {
    width: 71px;
    height: 14px;
    padding: 4px;
    border-radius: 24px;
    opacity: 0px;
    background-color: #eaf4ff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .feature {
    color: #0e54a3;
    font-size: 8px;
    text-align: center;
    font-weight: 600;
  }
.heder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
}
.qualification {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    /* line-height: 14px; */
    letter-spacing: -0.25px;
    text-decoration-skip-ink: none;
    color: #7d7d7d;
    white-space: wrap;
  }
  .bottomseprator {
    /* align-self: stretch; */
    border: 0.5px solid #7D7D7D !important;
  }
  .label {
    font-family: "Poppins";
    line-height: 18px ;
    letter-spacing: -0.25px;


    font-size: 14px !important;
    font-weight: 600;
    color: #7D7D7D !important;
  
  }
  .noteContainer{
    background-color: #F1F1F1;
    border-radius: 24px;
    /* width: 100%; */
    max-height: 20%;
    padding: 14px 24px;
    display: flex;
  align-items: start;
  min-height: 100px;
  }