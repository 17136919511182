.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.SwitchButtonConatiner {
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.BackIcon {
  margin-right: 8px;
  font-size: 16px;
}

.BackToHome {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 768px) {
  .SwitchButtonConatiner {
    padding: 0 5%;
  }

  .BackIcon {
    font-size: 12px;
  }

  .BackToHome p {
    font-size: 12px;
  }
}
