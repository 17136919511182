.box {
  width: 700px;
  border-radius: 16px;
  background-color: #f6f7fa;
  padding: 30px;
  margin-right: 16px;
}
.mt40 {
  margin-top: 40px;
}
.flx {
  display: flex;
  flex-direction: row;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #ee7e37;
}
.empty3 {
  width: 256px;
  height: 189px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.flxp {
  display: flex;
  flex-direction: row;
}

.card {
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  background-color: #d7e5ff;
  padding: 5px 30px;
  box-sizing: border-box;
  color: #00276d;
  margin-bottom: 24px;
  box-shadow: 2px 2px 4px #00276d;
}
.card2 {
  margin-top: 24px;
  width: 400px;
  border-radius: 16px;
  background-color: #f5fcff;
  padding: 20px;
  margin-left: 16px;
}
.check {
  color: #0b7328 !important;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cross {
  color: #fb2047 !important;
  height: 37px;
  width: 37px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
}
.edit {
  background-color: #ff7631;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.file {
  height: 24px;
  width: 24px;
  margin-right: 24px;
}
.download {
  height: 24px;
  width: 24px;
  margin-left: auto;
}
.user {
  width: 300px;
  border-right: 2px dashed #00276d;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.textcenter {
  text-align: center;
}
.middel {
  text-align: center;
  border-right: 2px dashed #00276d;
  width: 50%;
}
.sendbtn {
  background-color: transparent;
  border-radius: 8px;
  border: 2px solid #00276d;
  color: #00276d;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  margin: 24px auto 0 auto;
}
.center {
  display: flex;
  justify-content: center;
}
.user2 {
  padding: 20px;
  width: 320px;
}
.table {
  width: 750px;
  margin-top: 40px;
}
.icon {
  height: 24px;
  width: 24px;
  margin-right: 16px;
  color: #00276d;
}
.end {
  margin-left: auto;
}
.close {
  height: 24px;
  width: 24px;
  color: #00276d;
}
.profile {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 16px;
}
.mb16 {
  margin-bottom: 16px;
}
