.bordr {
  border-bottom: 1px solid #c7c7c7;
}

.empty3 {
  width: 256px;
  height: 189px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 450px;
}

.tableWrapper {
  width: 100%;
}

.headerOuter {
  background-color: #099bed;
  color: white;
  border-radius: 4px 4px 0px 0px;
  position: sticky;
  top: 0px;
  z-index: 0;
}

.header {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
}

.headerItem {
  flex: 1;
  text-align: center;
}

.tableData {
  overflow: auto;
  height: 58vh;
}

.tableData::-webkit-scrollbar {
  display: none;
}

.dataRow {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.dataItem {
  flex: 1;
  padding: 10px;
  text-align: center;
}

.dataRow:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 4px, rgba(0, 0, 0, 0.23) 2px 2px 4px;
  background-color: white;
}

.boxm {
  display: flex;
  justify-content: center;
  align-items: center;
}
