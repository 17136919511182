.mainSelection {
  display: flex;
  justify-content: center;
}
.Selections {
  width: 150px;
  color: #00276d;
  font-size: 16px;
  font-weight: 600;
  outline: none !important;
  border-bottom: 2px solid #00276d;
}
.Selections ::after {
  outline: none !important;
  border: none !important;
}

.clinic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
  color: #00276d;
}
.Inhouse {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
  color: #00276d;
}

.mr16 {
  margin-right: 16px;
}
.ml16 {
  margin-left: 16px;
}
.ml32 {
  margin-left: 32px;
}
.cursor {
  cursor: pointer;
  color: rgba(217, 217, 217, 1);
}
.DDonation1 {
  width: 90%;
  margin-top: 32px;
}
/* new */
.healthCard {
  height: 400px;
  width: 400px;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid #f5f5f5;
  background-color: #f5f5f5;
  margin-top: 24px;
  margin-right: 24px;
}
.healthCard:hover {
  height: 400px;
  width: 400px;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 16px;
  background-color: #f5f5f5;
  margin-top: 24px;
  margin-right: 24px;
  border: 2px solid #00276d;
  cursor: pointer;
}
.textcontainer {
  height: 160px !important;
  overflow: hidden;
}
.textdata {
  margin-top: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.healthimgconatiner {
  height: 149px;
  width: 238px;
  margin: 16px auto 0 auto;
  background-color: #f5f5f5;
}
.healthImg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  object-fit: contain;
}
.empty {
  width: 256px;
  height: 189px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 230px;
  margin-top: 32px;
}
.algincenter {
  margin-top: 100px;
}
.back {
  color: #00276d;
  margin-right: 24px;
  height: 24px;
  width: 24px;
}
.close {
  height: 25px;
  width: 25px;
  margin-left: auto;
  cursor: pointer;
  color: #00276d;
}

.header {
  height: 30px;
  padding: 10px 20px;
  background-color: #fef9ef;
  color: #00276d;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 32px;
}
.PlanSelection {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  color: #00276d;
  font-weight: 700;
  background-color: #f5f5f5;
  border-radius: 8px;
}
.heathCard {
  width: 50%;
  height: 150px;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin-top: 24px;
  margin-right: 12px;
  padding: 20px;
  color: #00276d;
}
.TravelCard {
  width: 50%;
  height: 150px;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  margin-top: 24px;
  margin-left: 12px;
  padding: 20px;
  color: #00276d;
}
.healthImgmodel {
  height: 70px;
  width: 100%;
  margin-top: 16px;
}
.RefreshIcon {
  height: 24px;
  width: 24px;
  color: #746ca2;
}
.outerRefresh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: transparent;
  cursor: pointer;
}
.outerRefresh:hover {
  background-color: #a2a0ac;
}
