.socialInfoContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  backdrop-filter: blur(50px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 900px;
  margin: 0 auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.continueButton {
  padding: 1% 9%;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  /* width: 30%; */
  z-index: 10;
}

.continueButton:hover {
  background-color: #004494;
}

.rowSocial {
  display: flex;
  gap: 16px;
  margin-bottom: 10px;
}

.inputGroupSocial {
  display: flex;
  flex-direction: column;
  width: 405px;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

input::placeholder {
  color: #999;
}

.showIcon {
  position: absolute;
  right: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #6f6f6f;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .socialInfoContainer {
    margin-right: 20px;
    padding: 5px;
  }

  .rowSocial {
    flex-direction: column;
    gap: 10px;
  }

  .inputGroupSocial {
    width: 100%; /* Full width on smaller screens */
    margin-left: 0;
  }
}
