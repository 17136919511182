.bkground {
  box-sizing: border-box;
}
.requestCard {
  background-color: #ffe2e5;
  border-radius: 16px;
  height: 183px;
  padding: 20px;
  margin-right: 24px;
}
.requestCardiCon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fa5a7d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TotalCustomerCard {
  background-color: #fff4de;
  border-radius: 16px;
  height: 183px;
  padding: 20px;
  margin-right: 24px;
}

.TotalCustomerCardiCon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #ff947a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TotalRevenuCard {
  background-color: #dcfce7;
  border-radius: 16px;
  height: 183px;
  padding: 20px;
  margin-right: 24px;
}
.TotalRevenuiCon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #3cd856;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RevenueCard {
  background-color: white;
  height: 351px;
  padding: 20px;
  border-radius: 16px;
}
.CustomerGraph {
  background-color: white;
  border-radius: 16px;
  height: 388px;
  padding: 20px;
  margin-right: 24px;
  margin-top: 32px;
  box-sizing: border-box;
}
.VisitorGraph {
  height: 218px;
  background-color: white;
  border-radius: 16px;
  margin-top: 32px;
  box-sizing: border-box;
  padding: 20px;
}
.iconss {
  height: 24px;
  width: 24px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
  margin-top: 16px;
}
.primaryCOlor {
  color: #4079ed;
}
.mt5 {
  margin-top: 5px;
}
