.text {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #131313;
}
.mt24 {
  margin-top: 24px;
}
.outerclose {
  height: 20px;
  width: 20px;
  border-radius: 8px;
  position: absolute;
  top: 1%;
  right: 1%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.Property {
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.25px;
  color: #131313;
}
.iconclose {
  font-size: 16px;
  cursor: pointer;
  color: #0e54a3;
}
.mainHotelTable {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 4px 4px 12px 0px #00000040;
  margin-top: 16px;
  padding: 20px;
  height: 350px;
}
.rowBase {
  display: flex;
  align-items: center;
}
.hotelTable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.loading {
  display: flex;
  margin-left: 12px;
}
.lableText {
  font-size: 14px;
  font-weight: 400;
}
.SaveBtn {
  width: 180px;
  height: 48px;
  border-radius: 24px;
  font-weight: 300;
  font-size: 18px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  color: white;
  box-shadow: 4px 4px 12px 0px #00000040;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0e54a3;
  border: 1px solid #0e54a3;
}
.BtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BackBtn {
  width: 180px;
  height: 48px;
  border-radius: 24px;
  font-weight: 300;
  font-size: 18px;
  line-height: 12.22px;
  letter-spacing: -0.22px;
  color: #7d7d7d;
  box-shadow: 4px 4px 12px 0px #00000040;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  cursor: pointer;
}
.errorText {
  color: red;
  font-size: 12px;
  text-align: start;
  font-weight: 400;
}
.flxBtwn {
  display: flex;
  align-items: center;
  margin: 18px 0 0 0;
  gap: 18px;
  justify-content: space-between;
}
.flx {
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 18px 0;
  justify-content: space-between;
}
label {
  font-weight: 400;
  font-size: 12px;
  line-height: 9.75px;
  letter-spacing: -0.17px;
  color: #7d7d7d;
}
.modalInner {
  padding: 24px;
  width: 100%;
  height: auto;
  border: 0.5px solid #7d7d7d;
  border-radius: 12px;
}
.textHeadingModel {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.25px;
}
.edit {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  border: 0.5px solid #0e54a3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icons {
  color: #0e54a3;
  font-size: 16px;
}

.imagePicker {
  border: 1px dashed #0e54a3;
  padding: 24px 56px;
  background-color: #f9fafc;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}
.Pickertext {
  font-weight: 600;
  font-size: 16px;
  line-height: 9.75px;
  letter-spacing: -0.17px;
  color: #7d7d7d;
}
.pickIMAGE {
  height: 56px;
  width: 56px;
  cursor: pointer;
}
.containerimge {
  display: flex;
  margin: 12px 0;
  align-items: center;
  justify-content: space-between;
}
.pickerDisplay {
  width: calc(20% - 6px);
  height: 200px;
  object-fit: cover;
}
.selectedImagesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 20px;
  margin-top: 18px;
}
.imageWrapper {
  width: calc(20% - 16px);
  height: 180px;
  position: relative;
}
.selectedImages {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.modalouter {
  width: 1000px;
}
.textHeadingModel {
  font-weight: 700;
  font-size: 16px;
  line-height: 9.75px;
  letter-spacing: -0.17px;
  margin-bottom: 38px;
  text-align: center;
}
.outerclose {
  height: 20px;
  width: 20px;
  border-radius: 8px;
  position: absolute;
  top: 1%;
  right: 1%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.iconclose {
  font-size: 16px;
  cursor: pointer;
  color: #0e54a3;
}
